import React from 'react';
import { CalendarIcon, CopyIcon, ImageIcon, TrashIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { EyeClosedIcon, EyeOpenIcon } from '../../../components/icons';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { DropdownTrigger } from '../../projects/components/styled-project-components';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { Shot, UpdateShotsInput } from '../../../app/entities/shot';
import { useSelection } from '../../../core/contexts/selection.context';
import { bulkUpdateStore } from '../../../core/stores/bulk-update';

import type { Project } from '../../../app/entities/project';
import { openModal } from '../../../core/modal/open-modal';

type ShotListMenuProps = {
  stripId: string;
  shot: Shot;
  stripboard: Project['stripboard'];
  isHidden: boolean;
};

export const ShotListMenu: React.FC<ShotListMenuProps> = ({
  stripId,
  shot,
  stripboard,
  isHidden,
}) => {
  const { elements: selectedStripsIds } = useSelection();

  const isBulkUpdate = selectedStripsIds && selectedStripsIds.length > 1;

  const unassignShootingDay = () => {
    // @todo: This should be addressed in refactoring to
    // un-assign shooting days, using handleUpdate and
    // sending undefined when BE updates
    stripboard.deleteStrip(stripId);
  };

  const handleDelete = () => {
    shot.delete();
  };

  const handleHideShot = async () => {
    stripboard.editStrip(stripId, {
      data: {
        isHidden: !isHidden,
      },
    });
  };

  const handleShotsBulkUpdate = (data: UpdateShotsInput) => () => {
    const selectedShotsIds = stripboard.strips
      .filter((el) => selectedStripsIds.includes(el._id) && el.type === 'shot')
      .map((el) => el.data.shotId);

    bulkUpdateStore.handleShotsBulkUpdate(data, selectedShotsIds, shot!);
  };

  const handleUpdate = async (shootingDayId: string) => {
    await shot.update({ shootingDayId });
  };

  const otherShootingDays: React.ComponentProps<typeof DropdownMenu>['items'] =
    shot.project?.shootingDays
      .filter((step) => step && step._id !== shot.shootingDayId)
      .sort((a, b) => a.order - b.order)
      .map((step) => ({
        title: step.displayName as string,
        onSelect: isBulkUpdate
          ? handleShotsBulkUpdate({ shootingDayId: step._id })
          : () => handleUpdate(step._id),
      })) || [];

  const menuItems: React.ComponentProps<typeof DropdownMenu>['items'] = [
    {
      title: isHidden ? 'Unhide' : 'Hide',
      icon: isHidden ? <EyeClosedIcon /> : <EyeOpenIcon />,
      onSelect: isBulkUpdate ? handleShotsBulkUpdate({ isHidden: !isHidden }) : handleHideShot,
    },
    {
      title: 'Replace image',
      icon: <ImageIcon />,
      onSelect: shot.changeImage,
    },
    {
      title: 'Re-assign shooting day',
      icon: <CalendarIcon />,
      subItems: [
        {
          title: 'No shooting day',
          onSelect: isBulkUpdate
            ? handleShotsBulkUpdate({ shootingDayId: null })
            : unassignShootingDay,
        },
        ...(otherShootingDays?.length ? otherShootingDays : []),
      ],
    },
    {
      title: 'Duplicate shot',
      icon: <CopyIcon />,
      onSelect: () => Shot.duplicateShots([shot._id]),
    },
    {
      title: 'Delete shot',
      icon: <TrashIcon />,
      type: 'danger' as const,
      onSelect: () =>
        openModal(DeleteDialog, {
          title: 'Delete shot?',
          text: `The shot will be deleted completely from the project.
          For removing a shot from a specific day only, use "Re-assign shooting day → No shooting day".`,
          onSubmit: handleDelete,
        }),
    },
  ];

  return (
    <Container>
      <DropdownMenu items={menuItems} trigger={<DropdownTrigger>⋮</DropdownTrigger>} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
