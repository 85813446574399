import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { PlusIcon } from '@radix-ui/react-icons';
import { Tooltip } from '@producer-io/ui-kit';

import { SaveTagDto, Tag, TagColor, TagTarget } from '../../../app/entities/tag';
import { MultiSelectDropdown } from '../../../components/select-dropdown';

import { Point, TagLabel } from './styled-tags-components';
import { useCurrentSpace } from 'hooks/use-current-space';

type TagSelectorProps = {
  /**
   * The target type of the tag (e.g. asset, task, ...)
   */
  targetType: TagTarget;

  /**
   * An array of tag ids
   */
  selectedTags: string[];

  /**
   * Return a list of all selected elements
   */
  onChange: (values: string[]) => void;

  trigger?: (label?: string) => React.ReactNode;
};

const colorOptions: React.ComponentProps<typeof MultiSelectDropdown>['options'] = Object.entries(
  TagColor,
).map(([key, color]) => ({
  label: key,
  value: color,
  icon: <Point style={{ marginRight: '0.8rem' }} color={color} />,
}));

export const TagSelector: React.FC<TagSelectorProps> = observer(
  ({ selectedTags, targetType, trigger, onChange }) => {
    const createValueRef = React.useRef<string | null>(null);

    const [options, setOptions] = useState<
      React.ComponentProps<typeof MultiSelectDropdown>['options']
    >([]);

    const [currentMenu, setCurrentMenu] = useState<'tags' | 'colors'>('tags');

    const space = useCurrentSpace();

    const tagsOptions: React.ComponentProps<typeof MultiSelectDropdown>['options'] = useMemo(() => {
      return (space?.tags || [])
        .filter((el) => el.target === targetType)
        .sort((a, b) => (a.text > b.text ? 1 : -1))
        .map((el) => ({
          label: el.text,
          value: el._id,
          onSelect: (e) => {
            e.preventDefault();
          },
          icon: <Point style={{ marginRight: '0.8rem' }} color={el.color} />,
        }));
    }, [space?.tags, targetType]);

    useEffect(() => {
      if (currentMenu === 'colors') {
        setOptions(colorOptions);
      } else {
        setOptions(tagsOptions);
      }
    }, [currentMenu, tagsOptions]);

    const handleChangeTags = (tagId: string, isChecked: boolean) => {
      const tagsIds = selectedTags || [];

      isChecked ? tagsIds.push(tagId) : tagsIds.splice(tagsIds.indexOf(tagId), 1);

      onChange(tagsIds);

      const tag = Tag.getOne(tagId);
      if (tag) isChecked ? tag.usage++ : tag.usage > 0 ? tag.usage-- : 0;
    };

    const handleCreateTag = async (input: SaveTagDto) => {
      const tag = await Tag.create(input);

      if (tag) {
        handleChangeTags(tag._id, true);
      }
    };

    const handleChange = async (value: string, isChecked: boolean) => {
      if (currentMenu === 'tags') {
        handleChangeTags(value, isChecked);
      } else {
        await handleCreateTag({ color: value, text: createValueRef.current!, target: targetType });
        createValueRef.current = null;
      }
    };

    const handleShowColorOptions = (value: string, event?: Event) => {
      event?.preventDefault();
      createValueRef.current = value;
      setCurrentMenu('colors');
    };

    const handleOpenChange = (isOpen?: boolean) => {
      if (!isOpen) {
        setCurrentMenu('tags');
        createValueRef.current = null;
      }
    };

    return (
      <MultiSelectDropdown
        title="Select tags"
        align="end"
        trigger={(label) => {
          return (
            trigger?.(label) || (
              <div onClick={(e) => e.stopPropagation()}>
                <Tooltip text="Add tag">
                  <AddTagTrigger color="var(--color-grayscale-trout)">
                    <PlusIcon />
                  </AddTagTrigger>
                </Tooltip>
              </div>
            )
          );
        }}
        options={options}
        selectedOptions={selectedTags}
        onValueChange={handleChange}
        onOpenChange={handleOpenChange}
        {...(currentMenu === 'tags' && { onCreate: handleShowColorOptions })}
      />
    );
  },
);

const AddTagTrigger = styled(TagLabel)`
  background-color: transparent;
  border-style: dashed;
  border-color: var(--color-texts-middle-contrast);
  color: var(--color-grayscale-trout);

  & > svg {
    color: var(--color-texts-middle-contrast);
  }

  &:hover {
    background-color: var(--color-surfaces-bg-elevation-3);
  }
`;
