import React, { useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import styled from 'styled-components';

import { ReactSelect } from '../../../components/select';
import axios from 'axios';

interface Props {
  onChange: (value: string) => void;
  value?: string;
}

interface Option {
  label: string;
  value: string;
}

export const CountrySelect: React.FC<Props> = ({ onChange, value }) => {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    axios
      .get(`${window.location.origin}/countries.json`)
      .then(({ data }) => {
        setOptions(
          data.map((country: any) => ({
            label: country.name,
            value: country.code,
          })),
        );
      })
      .catch(captureException);
  }, []);

  const handleChange = (option: any) => {
    if (!options.find((o) => o.value === option.value)) {
      return;
    }
    onChange(option.value);
  };

  const selectedOption = options.find((option) => option.value === value);

  return (
    <Container>
      <ReactSelect
        value={selectedOption}
        options={options}
        onChange={handleChange}
        placeholder={'Choose a country...'}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 100%;

  & input {
    border-color: var(--color-grey-7);
  }
  & input:focus {
    border-color: var(--color-secondary);
  }
`;
