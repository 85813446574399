import { v4 as uuid } from 'uuid';
import { makeObservable, observable } from 'mobx';

class TaskGroupingStore {
  groupingKey = '';
  groupBy = 'status';

  constructor() {
    makeObservable(this, {
      groupingKey: observable,
      groupBy: observable,
    });
  }

  private _setGroupingKey() {
    this.groupingKey = uuid();
  }

  triggerRender() {
    this._setGroupingKey();
  }

  setGroupBy(groupBy: any) {
    this.groupBy = groupBy;
  }
}

export const taskGroupingStore = new TaskGroupingStore();
