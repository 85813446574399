import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import * as yup from 'yup';

import { Space } from '../../../app/entities/space';

import { AvatarCreatableSelect } from '../../../components/select/components/avatar-creatable-select/avatar-creatable-select';
import { ElevatedButton } from '../../../components/buttons';
import { breakPoint } from '../../../app/theme';
import { MemberRoleSelector } from './member-role-select';

import type { Member } from '../../../app/entities/member';
import type { AvatarOption } from '../../../components/select/components/avatar-select/avatar-select-option';
import type { SelectRefType } from '../../../components/select';
import type { RoleTypes } from './role-dropdown';

const parseOption = (member: Member) => {
  const label = member.fullName ? member.fullName : member.email;

  return {
    value: member.email,
    label,
    avatarSrc: member.avatar || '',
    subtitle: member.email,
    suffix: member.role,
  };
};

interface ContactSelectProps {
  space: Space;
  collaborators: Member[];
  actionDisabled?: boolean;
  onSubmit: (email: string, role?: RoleTypes) => void;
  onChange?: (email?: string) => void;
  onRoleChange?: (role?: RoleTypes) => void;
}

const schema = yup.object().shape({
  email: yup.string().email('Please enter a valid email').required('Email is required'),
});

export const MemberSelect: React.FC<ContactSelectProps> = observer(
  ({ space, collaborators, actionDisabled, onSubmit, onChange, onRoleChange }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [role, setRole] = useState<RoleTypes | undefined>('guest');

    const selectRef = useRef<SelectRefType<AvatarOption>>(null);

    useEffect(() => {
      selectRef.current?.focus();
    });

    const collaboratorsEmails = collaborators.map((collaborator) => collaborator.email);

    const options: React.ComponentProps<typeof AvatarCreatableSelect>['options'] = space.members
      .filter((member) => !collaboratorsEmails.includes(member.email))
      .map(parseOption);

    const validate = async (email: string) => {
      try {
        await schema.validate({ email });
        const isCurrentCollaborator = collaboratorsEmails.includes(email);

        if (!isCurrentCollaborator) {
          return true;
        } else {
          setErrorMessage('User already in project');
          return false;
        }
      } catch (error: any) {
        setErrorMessage(error.errors[0]);
        return false;
      }
    };

    const handleSubmit = async () => {
      if (!isLoading && email) {
        const isValid = await validate(email);

        if (!isValid) {
          return;
        }

        setIsLoading(true);

        try {
          await onSubmit(email, role);
          setEmail('');
          onChange?.('');
          setIsLoading(false);
        } catch (error) {
          const response = (error as any).response;

          if (response?.data?.errors?.[0].message) {
            setErrorMessage(response.data?.errors?.[0].message);
          } else if (response?.status === 409) {
            setErrorMessage('User already in project');
          }
          setIsLoading(false);
          throw error;
        }
      }
    };

    return (
      <Container>
        <Label>Invite a creator or guest</Label>

        <InviteMember>
          <MemberRoleSelector
            ref={selectRef}
            space={space}
            options={options}
            value={email}
            onChange={(value) => {
              onChange?.(value);
              setEmail(value);
            }}
            onRoleChange={(role) => {
              setRole(role);
              onRoleChange?.(role);
            }}
            role={role}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />

          <ElevatedButton
            key="button-invite"
            id="invite-project-collaborators-button"
            text="Invite"
            isLoading={isLoading}
            onClick={handleSubmit}
            disabled={actionDisabled || !email}
          />
        </InviteMember>
      </Container>
    );
  },
);

MemberSelect.displayName = 'MemberSelect';

const InviteMember = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  align-items: center;

  & > button {
    min-width: 4rem;
    display: none;

    @media screen and (min-width: ${breakPoint.medium}px) {
      display: flex;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const Label = styled.p`
  color: var(--color-grayscale-white, #fafafa);
  font-size: 1.4rem;
  font-weight: 400;
`;
