import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Share2Icon } from '@radix-ui/react-icons';

import { Header } from './project.page';
import { useCurrentAsset } from '../../hooks/use-current-asset';
import { uiStore } from '../../core/stores/ui-store';
import { AssetViewerScreen } from '../../features/assets/screens/asset-viewer.screen';
import { FolderView } from '../../features/assets/components/folder-view';
import { Breadcrumb } from '../../components/breadcrumb/breadcrumb';
import { ElevatedButton } from '../../components/buttons';
import { useCurrentProject } from '../../hooks/use-current-project';
import { ShareModal } from '../../features/share/components/share-modal';
import { openModal } from '../../core/modal/open-modal';

export const ProjectDocumentRoute = observer(() => {
  const project = useCurrentProject()!;
  const asset = useCurrentAsset();

  useEffect(() => {
    uiStore.currentLocation = 'project.documents';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  const breadCrumbs = [
    { label: 'Documents', href: '../' },
    ...(asset?.getBreadCrumbs(project.documentsFolderId) || []),
  ];

  const openShareModal = () => {
    openModal(ShareModal, {
      title: `Share ${asset!.name}`,
      elementIds: [asset!._id],
      spaceId: project.spaceId,
      projectId: project._id,
    });
  };

  return (
    <>
      <Header>
        <Breadcrumb breadcrumbs={breadCrumbs} />

        {asset && asset.type !== 'folder' && (
          <ElevatedButton onClick={openShareModal} text="Share" icon={<Share2Icon />} />
        )}
      </Header>

      {asset ? (
        asset.type === 'folder' ? (
          <FolderView folderId={asset._id} />
        ) : (
          <AssetViewerScreen asset={asset} />
        )
      ) : null}
    </>
  );
});
