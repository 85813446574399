import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { DateSelector } from '../../../components/date-selector/date-selector';
import { differenceInCalendarDays } from 'date-fns';

import { TaskStatus, type Task } from '../../../app/entities/task';
import { DateSelectorWrapper } from './save-task.modal';

type DateSelectorProps = {
  date?: string;
  status: Task['status'];
  onUpdate: (date: string) => void;
  isViewOnly?: boolean;
};

export const TaskDateSelector: React.FC<DateSelectorProps> = observer(
  ({ date, onUpdate, isViewOnly, status }) => {
    const [dateIconType, setDateIconType] = useState<'default' | 'danger' | 'warning'>('default');

    useEffect(() => {
      if (!date || [TaskStatus.Done, TaskStatus.Canceled].includes(status)) {
        return setDateIconType('default');
      }

      const today = new Date();
      const dueDate = new Date(date);

      const dateDifference = differenceInCalendarDays(dueDate, today);

      if (dateDifference <= 0) {
        setDateIconType('danger');
      } else if (dateDifference < 4) {
        setDateIconType('warning');
      } else {
        setDateIconType('default');
      }
    }, [date, status]);

    return (
      <Container
        data-set={!!date}
        data-type={dateIconType}
        data-disabled={isViewOnly}
        data-variant="minimal"
      >
        <DateSelector
          placeholder="Set due date"
          field="dueDate"
          date={date}
          onUpdate={onUpdate}
          disabled={isViewOnly}
        />
      </Container>
    );
  },
);

const Container = styled(DateSelectorWrapper)`
  &[data-variant='minimal'] {
    height: 2.4rem;
    min-height: 2.4rem;
    padding: 0.6rem 0.8rem;

    & div {
      padding: 0;
    }
  }

  &[data-set='true'] {
    border-color: transparent;
    background: var(--color-surfaces-bg-elevation-2);

    &[data-type='danger'] {
      svg {
        color: var(--color-texts-error);
      }
    }

    &[data-type='warning'] {
      svg {
        color: var(--color-secondary-saffron);
      }
    }
  }
`;
