import React from 'react';
import { observer } from 'mobx-react-lite';
import { Cross2Icon } from '@radix-ui/react-icons';

import { Task, TaskStatus } from '../../../../app/entities/task';

import { ProjectPriority, ProjectPriorityByValue } from '../../../../app/entities/project';
import { FilterTrigger, FilterTitle, LowContrastText } from '../styled-tasks-components';
import { useTranslation } from 'react-i18next';
import { Space } from '../../../../app/entities/space';

export type FiltersProps = {
  projectIds?: string[];
  status?: TaskStatus[];
  assigneeIds?: string[];
  tagIds?: string[];
  priority?: ProjectPriority[];
};

type FilterTriggerProps = {
  space: Space;
  selectedProp: keyof FiltersProps;
};

export const SpaceFilterChip: React.FC<FilterTriggerProps> = observer(({ space, selectedProp }) => {
  const { t } = useTranslation('common');

  const spaceTasksFilter = space.tasksFilter;
  const selectedFilter = spaceTasksFilter[selectedProp];
  const selectedFilterCount = [...new Set(selectedFilter.map((item) => item || ''))].length;

  const filterTitleKey = spaceTasksFilter.tokensMapper[selectedProp];

  const getFirstFilterElement = (selectedProp: keyof typeof spaceTasksFilter.tokensMapper) => {
    const { projectIds, status, assigneeIds, priority, tagIds } = spaceTasksFilter;

    if (selectedFilterCount > 1) {
      return t(`${filterTitleKey}WithCount`, {
        count: selectedFilterCount,
      });
    }

    if (selectedProp === 'projectIds') {
      const spaceProjects = space?.projects;

      return spaceProjects.find((project) => project._id === projectIds?.[0])?.name || 'No project';
    }

    if (selectedProp === 'status') {
      return Task.StatusMapper[status[0] as TaskStatus].title;
    }

    if (selectedProp === 'assigneeIds') {
      const spaceMembers = space.members;

      return (
        spaceMembers.find((member) => member._id === assigneeIds?.[0])?.fullName || 'No assignee'
      );
    }

    if (selectedProp === 'tagIds') {
      const spaceTags = space.tags;

      return spaceTags.find((tag) => tag._id === tagIds?.[0])?.text || 'No tag';
    }

    if (selectedProp === 'priority') {
      return priority[0] ? ProjectPriorityByValue[priority[0]] : 'No priority';
    }
  };

  return (
    <FilterTrigger>
      <FilterTitle>
        {t(filterTitleKey, { count: selectedFilterCount })}
        <LowContrastText>
          &nbsp;
          {t('isAny', {
            count: selectedFilterCount,
          })}
        </LowContrastText>
        &nbsp;
        {getFirstFilterElement(selectedProp)}
      </FilterTitle>

      <Cross2Icon
        onPointerDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={() => {
          spaceTasksFilter.emptyFilter(selectedProp);
        }}
      />
    </FilterTrigger>
  );
});
