import React from 'react';
import { ChatBubbleIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

interface CommentCountProps {
  count?: number;
}

export const CommentCount: React.FC<CommentCountProps> = ({ count = 0 }) => (
  <Container>
    <ChatBubbleIcon />
    <span>{count}</span>
  </Container>
);

const Container = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  color: var(--color-grayscale-white);

  & svg {
    color: var(--color-grayscale-manatee);
  }
`;
