import React, { useMemo } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { CSS } from '@dnd-kit/utilities';

import folderImage from '../../../assets/images/folder.png';
import { Asset } from '../../../app/entities/asset';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { getModifier } from '../../../core/services/event.service';
import { useResponsive } from '../../../hooks/useResponsive';
import { useSelection } from '../../../core/contexts/selection.context';
import { RenameDialog } from '../../../components/dialogs/rename.dialog';
import { catchError } from '../../../core/catch-error';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { openModal } from 'core/modal/open-modal';

type FolderItemProps = {
  folder: Asset;
  assetsRouteTo: (asset: string, assetId: string, folderName?: string) => void;
};

export const FolderItem: React.FC<FolderItemProps> = observer(({ folder, assetsRouteTo }) => {
  const { isMobile } = useResponsive();

  const {
    setNodeRef: draggableRef,
    attributes,
    listeners,
    transform,
  } = useDraggable({
    id: folder._id,
    data: {
      type: 'folder',
    },
  });

  const styles = {
    transform: CSS.Transform.toString(transform),
  };

  const { setNodeRef, isOver } = useDroppable({
    id: folder._id,
    data: {
      type: 'folder',
    },
  });

  const { select, elements, selectionModeOn } = useSelection();

  const handleRenameFolder = async (name: string) => {
    try {
      await folder.update({ name });
    } catch (e) {
      catchError(e);
    }
  };

  const menuItems = [
    // {
    //   onSelect: () => {},
    //   title: 'Share',
    //   icon: <ShareFile />,
    // },
    {
      onSelect: () =>
        openModal(RenameDialog, {
          defaultName: folder.name,
          onSubmit: handleRenameFolder,
          title: 'Rename folder',
          label: 'Folder name',
        }),
      title: 'Rename',
      icon: <Pencil1Icon />,
    },
    {
      onSelect: () =>
        openModal(DeleteDialog, {
          onSubmit: () => folder.delete(),
          text: `Are you sure you want to delete this ${folder.name} ?`,
          title: 'Delete folder',
        }),
      title: 'Delete',
      icon: <TrashIcon />,
      type: 'danger' as 'danger',
    },
  ];

  const handleOpenFolder = () => assetsRouteTo('folder', folder._id);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!isMobile || selectionModeOn) {
      const modifier = getModifier(e.nativeEvent);
      select(folder._id, modifier);
      return;
    }

    handleOpenFolder();
  };

  const isSelected = useMemo(() => elements.includes(folder._id), [elements, folder._id]);

  return (
    <Container
      onClick={handleClick}
      onDoubleClick={handleOpenFolder}
      key={`${folder?._id}key`}
      data-selected={isSelected}
      ref={(e) => {
        setNodeRef(e);
        draggableRef(e);
      }}
      style={{ ...styles, ...(isOver ? { opacity: 0.5 } : {}) }}
      {...attributes}
      {...listeners}
    >
      <FolderLink>
        <FolderImage>
          <img src={folderImage} alt="Folder" />
        </FolderImage>

        <FolderName>{folder.name}</FolderName>
      </FolderLink>

      <ModalContainer>
        <DropdownMenu items={menuItems} />
      </ModalContainer>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  background: #28292d;
  border-radius: 0.6rem;
  padding: 1.6rem;
  user-select: none;

  &[data-selected='true'] {
    box-shadow: inset 0 0 0 1.5px var(--color-secondary);
  }

  &:hover {
    cursor: pointer;
  }
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 1.6rem;
  transform: translateY(-50%);
  cursor: pointer;
`;

const FolderName = styled.div`
  color: white;
  font-weight: 400;
  font-size: 1.6rem;
  margin-right: auto;
`;

const FolderImage = styled.div`
  margin-right: 1.2rem;

  img {
    width: 2.4rem;
  }
`;

export const FolderLink = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;
