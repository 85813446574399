import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadIcon, Share2Icon, TrashIcon } from '@radix-ui/react-icons';

import { SelectionContext } from '../../../core/contexts/selection.context';
import { download } from '../../../lib/utils/download';
import { SplitButton } from 'components/split-button/split-button';
import { ShareModal } from '../../share/components/share-modal';
import { Upload } from '../../../app/entities/upload';
import { Asset } from '../../../app/entities/asset';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { openModal } from 'core/modal/open-modal';

type DownloadShareButtonProps = {
  spaceId: string;
  projectId: string;
};

export const DownloadShareButton: React.FC<DownloadShareButtonProps> = ({ spaceId, projectId }) => {
  const { t } = useTranslation('common');
  const { elements } = useContext(SelectionContext);

  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const uploads = Upload.getAll();

  const [areLinksSelected, idsToHandle] = useMemo(() => {
    const assets = elements.map((id) => Asset.getOne(id)).filter((el) => !!el) as Asset[];
    const areLinksSelected = assets.every((el) => el.type === 'link');
    const idsToHandle = assets.filter((el) => el.type !== 'link').map((el) => el._id);

    return [areLinksSelected, idsToHandle];
  }, [elements]);

  useEffect(() => {
    const isUploading = uploads.some(
      (upload) => elements.includes(upload.assetId) && upload.status !== 'done',
    );
    setIsUploading(isUploading);
  }, [uploads, elements]);

  const handleDownloadMultipleFiles = async () => {
    if (!idsToHandle.length) return;

    setIsDownloading(true);

    for (const id of idsToHandle) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      download(id);
    }

    setIsDownloading(false);
  };

  const handleDeleteAssets = async () => {
    await Asset.deleteMany(elements);
  };

  const buttonText = isDownloading
    ? 'Downloading...'
    : `${t('itemWithCount', { count: elements.length })} selected`;

  const items = [
    {
      title: 'Share selection',
      icon: <Share2Icon />,
      onSelect: () =>
        openModal(ShareModal, {
          elementIds: elements,
          spaceId,
          projectId,
        }),
    },
    {
      title: 'Download selection',
      icon: <DownloadIcon />,
      onSelect: handleDownloadMultipleFiles,
      disabled: isUploading || areLinksSelected,
      tooltipText: 'Some of the selected files are still being uploaded.',
    },
    {
      title: 'Delete selection',
      icon: <TrashIcon />,
      type: 'danger' as 'danger',
      onSelect: () =>
        openModal(DeleteDialog, {
          title: 'Delete files',
          text: 'Are you sure you want to delete the selected files?',
          onSubmit: handleDeleteAssets,
        }),
    },
  ];

  if (!elements.length) {
    return null;
  }

  return <SplitButton id="download-share-button" text={buttonText} items={items} />;
};
