import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { CreateShotCard } from './create-shot-card';
import { Layout, ShotCard, ShotCardProps } from './shot-card';
import { useSelection } from '../../../core/contexts/selection.context';
import { Storyboard } from '../../../app/entities/storyboard';
import { Shot } from '../../../app/entities/shot';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { useCurrentMember } from '../../../hooks/use-current-member';
import { openModal } from 'core/modal/open-modal';

import type { StoryboardSettings } from '../models/storyboard-settings';

type SortableShotGridProps = {
  storyboard: Storyboard;
  shots: Shot[];
  options: StoryboardSettings;
  zoomLevel?: number;
};

export const SortableShotGrid: React.FC<SortableShotGridProps> = observer(
  ({ storyboard, shots, options, zoomLevel }) => {
    const { elements, selectAll } = useSelection();

    const items = shots.map((shot) => shot._id);
    const [copiedElements, setCopiedElements] = useState<string[]>([]);

    const currentMember = useCurrentMember();

    const storyboardPreferences = currentMember?.preferences?.storyBoardPreferences;

    useEffect(() => {
      const handleKeyDown = async (e: KeyboardEvent) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
          if (elements.length) {
            e.preventDefault();
            setCopiedElements(elements);
          }
        }

        if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
          if (copiedElements.length) {
            e.preventDefault();
            await Shot.duplicateShots(copiedElements);
            setCopiedElements([]);
          }
        }

        if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
          e.preventDefault();
          selectAll(items);
        }

        if (e.key === 'Delete') {
          if (elements.length) {
            e.preventDefault();

            openModal(DeleteDialog, {
              onSubmit: () => Shot.deleteShots(elements),
              text: `Are you sure you want to delete ${elements.length} shots`,
              title: 'Delete multiple shots',
            });
          }
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements, copiedElements]);

    return (
      <SortableContext id="storyboard-grid" items={items}>
        <Container data-zoom={zoomLevel}>
          {items.map((id, index) => (
            <SortableShot
              key={id}
              shotId={id}
              projectId={storyboard.projectId}
              storyboardId={storyboard._id}
              position={index + 1}
              layout={Layout.Grid}
              options={options}
              isSelected={elements.includes(id)}
              readOnly={!currentMember || currentMember.role === 'guest'}
              storyboardPreferences={storyboardPreferences}
            />
          ))}

          <CreateShotCard storyboard={storyboard} zoom={storyboardPreferences?.zoom || 1} />
        </Container>
      </SortableContext>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  padding: 1.6rem;
  margin: 0;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  &[data-zoom='4'],
  &[data-zoom='3'],
  &[data-zoom='2'],
  &[data-zoom='1'] {
    display: grid;
    grid-gap: 1.6rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    overflow-x: hidden;
  }

  @media screen and (min-width: 768px) {
    display: grid;
    grid-gap: 1.6rem;
    grid-template-columns: repeat(auto-fill, calc((100% - (2 * 0.8rem)) / 2));
    overflow-x: hidden;
  }

  @media screen and (min-width: 1224px) {
    &[data-zoom='6'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 1.6rem)) / 3));
    }
    &[data-zoom='5'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 2.4rem)) / 4));
    }

    // Starting from here, the cards should hide their details
    &[data-zoom='4'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 3.2rem)) / 5));
    }
    &[data-zoom='3'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 4rem)) / 6));
    }
    &[data-zoom='2'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 4.8rem)) / 7));

      #photo-uploader {
        > div {
          gap: 1.2rem;
          > div {
            margin: 0;
          }
        }

        span {
          display: none;
        }
      }
    }

    &[data-zoom='1'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 5.6rem)) / 8));
      #photo-uploader {
        > div {
          gap: 1.2rem;
          > div {
            margin: 0;
          }
        }

        span {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: 2000px) {
    &[data-zoom='6'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 2.4rem)) / 4));
    }
    &[data-zoom='5'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 3.2rem)) / 5));
    }
    &[data-zoom='4'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 4rem)) / 6));
    }

    // Starting from here, the cards should hide their details
    &[data-zoom='3'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 4.8rem)) / 7));
    }
    &[data-zoom='2'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 5.6rem)) / 8));
    }
    &[data-zoom='1'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 6.4rem)) / 9));
    }
  }
`;

export const FullShotDragOverlay = observer(
  ({ shotId, items, ...props }: Omit<ShotCardProps, 'index'> & { items: string[] }) => {
    return (
      <ShotCard
        shotId={shotId}
        style={{
          cursor: 'grabbing',
          borderRadius: '0.6rem',
          border: '1.5px solid var(--color-secondary)',
        }}
        {...props}
      />
    );
  },
);

const SortableShot = observer(
  ({
    shotId,
    storyboardId,
    isSelected,
    readOnly,
    ...props
  }: ShotCardProps & {
    isSelected?: boolean;
    readOnly?: boolean;
  }) => {
    const { select, elements } = useSelection();

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: shotId,
      animateLayoutChanges: always,
      data: {
        elements: elements.length ? elements : [shotId],
        position: props.position,
      },
      transition: {
        duration: 150,
        easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
      },
      disabled: readOnly,
    });

    const isDraggingCard = isDragging && isSelected;

    const style = {
      transition,
      transform: CSS.Translate.toString(transform),
      borderRadius: '0.6rem',
      border: '1.5px solid transparent',
      ...(isSelected &&
        !isDraggingCard && {
          border: '1.5px solid var(--color-secondary)',
        }),
      disabled: readOnly,
    };

    return (
      <ShotCard
        ref={setNodeRef}
        shotId={shotId}
        storyboardId={storyboardId}
        active={isDraggingCard}
        style={style}
        onClick={select}
        {...props}
        {...attributes}
        {...listeners}
      />
    );
  },
);

const always = () => {
  return true;
};
