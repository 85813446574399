import React from 'react';

export const FlagIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.40003 14.0833L1.0667 2.88333H13.8667L10.6667 6.08333L13.8667 9.28333H4.11403"
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
