import React from 'react';
import styled from 'styled-components';
import { FilePlusIcon, FileTextIcon, Link2Icon, PlusIcon } from '@radix-ui/react-icons';

import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { breakPoint } from '../../../app/theme';
import { selectFiles } from 'core/services/file.service';

type FilterButtonProps = {
  onSelectFile: (files: File[]) => Promise<void>;
  onSelectLink: (event: Event) => void;
  onSelectNote: (event: Event) => void;
  onCreateFolder: (event: Event) => void;
};

export const AddAssetButton: React.FC<FilterButtonProps> = ({
  onSelectFile,
  onSelectLink,
  onSelectNote,
  onCreateFolder,
}) => {
  const items: React.ComponentProps<typeof DropdownMenu>['items'] = [
    {
      title: 'Add file',
      icon: <PlusIcon />,
      onSelect: async () => {
        const files = await selectFiles({ multiple: true });

        if (files) {
          onSelectFile(files);
        }
      },
    },
    { title: 'Add link', icon: <Link2Icon />, onSelect: onSelectLink },
    { title: 'Add note', icon: <FileTextIcon />, onSelect: onSelectNote },
    { title: 'Create folder', icon: <FilePlusIcon />, onSelect: onCreateFolder },
  ];

  return (
    <DropdownMenu
      items={items}
      size="free-size"
      side={'top'}
      trigger={
        <Button>
          <PlusIcon />
        </Button>
      }
    />
  );
};

const Button = styled.button`
  position: fixed;
  bottom: 2.4rem;
  right: 2.4rem;
  width: 4.2rem;
  height: 4.2rem;
  border-radius: var(--border-radius-medium);
  background-color: var(--color-secondary);
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  border: none;
  outline: none;
  color: white;
  z-index: 1000;

  &:active {
    background-color: var(--color-shades-science);
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }
`;
