import React, { useState } from 'react';
import styled from 'styled-components';

import { BaseDialog, DialogFooter, DialogHeader } from '../dialog';
import { ElevatedButton, TextButton } from '../buttons';

export type DeleteDialogProps = {
  onCancel: () => void;
  onSubmit: () => void;
  text: string;
  title: string;
  actionText?: string;
};

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  onCancel,
  onSubmit,
  text,
  title,
  actionText = 'Yes, delete',
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await onSubmit();
      setIsLoading(false);
      onCancel();
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title={title} />

      <Container>{text}</Container>

      <DialogFooter
        actions={[
          <TextButton key="cancel" text="Cancel" onClick={onCancel} />,
          <ElevatedButton
            key="delete"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleSubmit}
            text={actionText}
            variant="danger"
            autoFocus
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Container = styled.p`
  padding: 1.6rem;
  max-width: 50rem;
  font-size: 1.4rem;
  color: var(--color-grayscale-light-slate);
  white-space: pre-line;
`;
