import styled from 'styled-components';

export const TAGS_CONTAINER_GAP = 0.8;
export const ADD_TAG_WIDTH = 4.8;

export const TAG_BORDER_WIDTH = 0.1;
export const TAG_INNER_HORIZONTAL_PADDING = 0.8;
export const TAG_INNER_GAP = 0.4;
export const TAG_COLORED_POINT_SIZE = 0.8;
export const TAG_REMOVE_BUTTON_WIDTH = 1.5;
2 * (TAG_BORDER_WIDTH + TAG_INNER_HORIZONTAL_PADDING + TAG_INNER_GAP) +
  TAG_COLORED_POINT_SIZE +
  TAG_REMOVE_BUTTON_WIDTH +
  0.1; // 1px extra offset

export const Strip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.8rem 1.6rem;

  &:not(:last-child) {
    border-bottom: 0.1rem solid var(--color-surfaces-bg-elevation-2);
  }

  &[data-header='true'] {
    background: var(--color-surfaces-bg-elevation-1);
    border: none;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div:last-child {
    margin-inline-end: 0.4rem;
    margin-inline-start: 0.8rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;

  &[data-full-width='true'] {
    flex: 1;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;

  & > div {
    margin-inline-start: -0.8rem;
    width: 100%;

    & > input {
      width: 100%;
      padding: 0.2rem 0.8rem;
      height: 2.4rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: var(--color-texts-high-contrast);

      &:disabled {
        color: var(--color-texts-high-contrast);
      }
    }
  }
`;

export const TagLabel = styled.div<TagProps>`
  display: flex;
  white-space: nowrap;
  padding: 0.4rem ${TAG_INNER_HORIZONTAL_PADDING}rem;
  border: ${TAG_BORDER_WIDTH}rem solid;
  border-color: ${({ color }) => color || 'var(--color-primary-sandal)'};
  border-radius: 10rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${TAG_INNER_GAP}rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--color-grayscale-white);
  min-width: auto;
  background-color: var(--app-background);

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: ${({ color }) => `${color}20` || `rgba(250, 250, 250, 0.2)`};
  }

  &:last-child {
    min-width: ${ADD_TAG_WIDTH}rem;

    &:hover,
    &:focus {
      cursor: pointer;

      svg {
        color: var(--color-grayscale-white);
      }
    }
  }
`;

type TagProps = {
  color: string;
};

export const Point = styled.div<TagProps>`
  background-color: ${({ color }) => color || 'var(--color-primary-sandal)'};
  height: ${TAG_COLORED_POINT_SIZE}rem;
  width: ${TAG_COLORED_POINT_SIZE}rem;
  min-width: ${TAG_COLORED_POINT_SIZE}rem;
  border-radius: 50%;
`;
