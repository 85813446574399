import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { HasServerResponse } from '../../../components/PanelContainer';
import { TextInput } from '../../../components/text-input/TextInput';
import { Alert } from '../../../components/alert/Alert';
import { BaseDialog, DialogFooter } from '../../../components/dialog';
import { TextButton, ElevatedButton } from '../../../components/buttons';
import { DialogHeader } from '../../../components/dialog';
import { Project } from '../../../app/entities/project';
import { toastPool } from '../../toasts/models/toast-pool';
import { Toast } from '../../toasts/models/toast';
import { catchError } from '../../../core/catch-error';

interface ModalProps {
  onCancel: () => void;
  onSuccess: () => void;
  projectId: string;
}

export const DeleteProjectModal: React.FC<ModalProps> = ({ onCancel, onSuccess, projectId }) => {
  const { t } = useTranslation('deleteProject');
  const project = Project.getOne(projectId);
  const [serverError, setServerError] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const { watch, control, handleSubmit } = useForm({
    defaultValues: {
      name: '',
    },
  });

  const handleOnSubmit = async () => {
    if (isLoading || !project) {
      return;
    }

    setIsLoading(true);

    project
      .delete()
      .then(() => {
        setIsLoading(false);
        onSuccess();
        toastPool.insert(new Toast('Project deleted successfully'));
      })
      .catch((error: any) => {
        setServerError(error?.data?.errors?.[0]?.message);
        catchError(error);
        setIsLoading(false);
      });
  };

  const [nameValue] = watch(['name']);

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title={t('delete_project')} onClose={onCancel} />
      <Container>
        {serverError && (
          <ServerErrorMessage>
            <Alert text={serverError} />
          </ServerErrorMessage>
        )}

        <Description>{t('delete_project_description', { name: project?.name })}</Description>

        <form id="delete-project-form" onSubmit={handleSubmit(handleOnSubmit)}>
          <InputContainer>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value, name, ref }, fieldState }) => (
                <TextInput
                  ref={ref}
                  value={value}
                  name={name}
                  onChange={onChange}
                  label={t('project_name_label', { name: project?.name })}
                  placeholder={project?.name}
                  errorMessage={fieldState.error?.message}
                  required
                />
              )}
            />
          </InputContainer>

          {serverError && serverError.response && (
            <HasServerResponse>
              <Alert text={serverError.response.data.message} />
            </HasServerResponse>
          )}
        </form>
      </Container>
      <DialogFooter
        actions={[
          <TextButton key="cancel" text="Cancel" onClick={onCancel} />,
          <ElevatedButton
            type="submit"
            variant="danger"
            text={'Delete this project'}
            key={'delete-project'}
            form="delete-project-form"
            disabled={nameValue !== project?.name}
            isLoading={isLoading}
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 1.6rem;
`;

const Description = styled.p`
  line-height: 2.2rem;
  max-width: 50rem;
  margin-bottom: 3.2rem;
`;

const ServerErrorMessage = styled(HasServerResponse)`
  display: flex;
  justify-content: start;
  margin-top: -3rem;
  margin-bottom: 2rem;
  max-width: 100%;

  & > div {
    max-width: 32rem;
  }
`;

const InputContainer = styled.div`
  &:not(:first-child) {
    margin-top: 1.6rem;
  }
`;
