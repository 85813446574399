import React, { useState } from 'react';
import styled from 'styled-components';

import { ElevatedButton, TextButton } from '../../../components/buttons';
import { BaseDialog, DialogFooter, DialogHeader } from '../../../components/dialog';
import { ArrowDown } from '../icons/ArrowDown';
import type { TypeTime } from '../models/types';

interface TimeSelectorProps {
  time: {
    hour: number;
    minute: number;
    period?: 'am' | 'pm';
  };
  onTimeChange: (time: TypeTime) => Promise<void>;
  onCancel: () => void;
}

const DEFAULT_MINUTE_INCREMENT = 15;

export const TimeSelector: React.FC<TimeSelectorProps> = ({ time, onTimeChange, onCancel }) => {
  const [hours, setHours] = useState<number>(time ? time.hour : 0);
  const [minutes, setMinutes] = useState<string>(
    time ? time.minute.toString().padStart(2, '0') : '00',
  );
  const [period, setPeriod] = useState<'am' | 'pm'>(time && time.period ? time.period : 'am');

  const handleClear = () => {
    setHours(0);
    setMinutes('00');
    setPeriod('am');
  };

  const handleSubmitTime = async () => {
    await onTimeChange({ hour: hours, minute: parseInt(minutes, 10), period: period });
    onCancel();
  };

  const handleTimeChange = (type: 'hours' | 'minutes') => (e: any) => {
    const value = e.target.value;
    const cleaned = value.replace(/\D/g, '');
    const numericValue = parseInt(cleaned, 10);

    if (isNaN(numericValue)) {
      return;
    }

    if (type === 'hours') {
      setHours(numericValue > 12 ? 12 : numericValue);
    } else {
      // Avoid leading zeros unless it's '00'
      let newMinutes = cleaned === '' ? '00' : cleaned;

      // If value exceeds 2 digits, keep only the last 2
      if (newMinutes.length > 2) {
        newMinutes = newMinutes.slice(-2);
      }

      setMinutes((numericValue > 59 ? 59 : numericValue).toString().padStart(2, '0'));
    }
  };

  const incrementTime = (type: 'hours' | 'minutes') => () => {
    if (type === 'hours') {
      const newHours = hours + 1;

      /**
       * If going from 11 to 12 or going from 12 to 11, change period
       */
      if (newHours === 12 && hours === 11) {
        changePeriod();
      }

      setHours(newHours > 12 ? 1 : newHours);
    } else {
      const newMinutes = parseInt(minutes, 10) + DEFAULT_MINUTE_INCREMENT;

      if (newMinutes > 59) {
        setMinutes((-(60 - newMinutes)).toString().padStart(2, '0'));

        const newHours = hours + 1;

        /**
         * If going from 11 to 12 or going from 12 to 11, change period
         */
        if (newHours === 12 && hours === 11) {
          changePeriod();
        }

        /**
         * Replace 13 by 1
         */
        setHours(newHours === 13 ? 1 : newHours);
      } else if (newMinutes < 0) {
        setMinutes('00');
      } else {
        setMinutes(newMinutes.toString().padStart(2, '0'));
      }
    }
  };

  const decrementTime = (type: 'hours' | 'minutes') => () => {
    if (type === 'hours') {
      const newHours = hours - 1;

      /**
       * If going from 12 to 11 or going from 12 to 11, change period
       */
      if (newHours === 11 && hours === 12) {
        changePeriod();
      }

      setHours((prev) => (prev > 1 ? prev - 1 : 12));
    } else {
      const newMinutes = parseInt(minutes, 10) - DEFAULT_MINUTE_INCREMENT;

      if (newMinutes < 0 && hours > 1) {
        setMinutes((60 + newMinutes).toString().padStart(2, '0'));

        const newHours = hours - 1;

        /**
         * If going from 12 to 11 or going from 12 to 11, change period
         */
        if (newHours === 11 && hours === 12) {
          changePeriod();
        }

        setHours(hours - 1);
      } else if (newMinutes < 0) {
        setHours(12);
        setMinutes((60 + newMinutes).toString().padStart(2, '0'));
      } else {
        setMinutes(newMinutes.toString().padStart(2, '0'));
      }
    }
  };

  const changePeriod = () => {
    setPeriod(period === 'am' ? 'pm' : 'am');
  };

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title="" />

      <TimeSelectorContainer>
        <TimeInputContainer>
          <UpArrowContainer onClick={incrementTime('hours')}>
            <ArrowDown />
          </UpArrowContainer>

          <TimeInput type="text" maxLength={2} value={hours} onChange={handleTimeChange('hours')} />

          <DownArrowContainer onClick={decrementTime('hours')}>
            <ArrowDown />
          </DownArrowContainer>
        </TimeInputContainer>

        <TimeSeparator>:</TimeSeparator>

        <TimeInputContainer>
          <UpArrowContainer onClick={incrementTime('minutes')}>
            <ArrowDown />
          </UpArrowContainer>

          <TimeInput type="text" value={minutes} onChange={handleTimeChange('minutes')} />

          <DownArrowContainer onClick={decrementTime('minutes')}>
            <ArrowDown />
          </DownArrowContainer>
        </TimeInputContainer>

        <TimeInputContainer>
          <UpArrowContainer onClick={changePeriod}>
            <ArrowDown />
          </UpArrowContainer>

          <TimeInput type="text" maxLength={2} value={period} readOnly />

          <DownArrowContainer onClick={changePeriod}>
            <ArrowDown />
          </DownArrowContainer>
        </TimeInputContainer>
      </TimeSelectorContainer>

      <DialogFooter
        actions={[
          <TextButton key="time-selector-clear-button" text="Clear" onClick={handleClear} />,
          <ElevatedButton
            key="time-selector-submit-button"
            text="Submit"
            onClick={handleSubmitTime}
          />,
        ]}
      />
    </BaseDialog>
  );
};

const TimeSelectorContainer = styled.div`
  display: flex;
  background-color: var(--color-gray-8);
  align-items: center;
  justify-content: center;
`;

const TimeInputContainer = styled.div`
  width: 4.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1rem;

  &:last-of-type {
    margin-left: 3.3rem;
  }
`;

const TimeInput = styled.input`
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1.5px solid var(--color-grey-5);
  font-size: 3.2rem;
  line-height: 3.873rem;
  text-align: center;
  font-weight: 700;
  color: #f2f3f5;
  background-color: transparent;
  text-transform: uppercase;
`;

const DownArrowContainer = styled.div`
  margin-top: 1.7rem;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(--color-secondary);

    & > svg {
      stroke: white;
    }
  }
  & > svg {
    stroke: #367bff;
  }
`;

const UpArrowContainer = styled.div`
  margin-bottom: 1.7rem;
  transform: rotate(180deg);
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(--color-secondary);

    & > svg {
      stroke: white;
    }
  }
  & > svg {
    stroke: #367bff;
  }
`;

const TimeSeparator = styled.span`
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 3.873rem;
  text-align: center;
  color: var(--color-grey-4);
  margin-left: 0.4rem;
`;
