import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Pencil1Icon, PlusIcon, TrashIcon } from '@radix-ui/react-icons';

import { MembersList } from '../../../routes/project/components/shootingday/members-list';
import { generateDepartmentsObjectsArray } from '../../../lib/utils/generate-departments-objects-array';
import { AssignContactsModal } from '../../contacts/components/assign-contacts.modal';
import { Team } from '../../../app/entities/team';
import { RenameDialog } from '../../../components/dialogs/rename.dialog';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { catchError } from '../../../core/catch-error';
import { CollapsibleItem } from '../../../components/collapsible-item/collapsible-item';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { TableContainer } from '../../../components/table/styled-shared-table';
import { DropdownTrigger } from '../../projects/components/styled-project-components';
import { EmptyTablePlaceholder } from '../../../components/table/empty-table-placeholder';
import { ConditionalWrapper } from '../../../components/conditional-wrapper/conditional-wrapper';
import { useResponsive } from '../../../hooks/useResponsive';

import type { TeamContact } from '../../teams/models/team';
import type { Step } from '../../../app/entities/step';
import { openModal } from '../../../core/modal/open-modal';

type TeamItemProps = {
  team: Team;
  step: Step;
  spaceId: string;
  isCrewTeam?: boolean;
};

export const TeamItem: React.FC<TeamItemProps> = observer(({ team, spaceId, step, isCrewTeam }) => {
  const { isDesktop } = useResponsive();

  const departments = useMemo(() => {
    if (!team || !team.departments || !team.contacts) return [];

    return generateDepartmentsObjectsArray({
      departments: team.departments,
      contacts: team.contacts,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team, team.departments, team.contacts]);

  const openAssignContactModal = () => {
    openModal(AssignContactsModal, {
      spaceId,
      addedContacts: team.contacts || [],
      onAdd: handleAssignTeamContact,
      onDelete: handleDeleteCrewMember,
      title: `Add ${team.name} members`,
      subtitle: 'Added members',
    });
  };

  const menuItems = [
    ...(!isCrewTeam
      ? [
          {
            title: 'Rename',
            icon: <Pencil1Icon />,
            onSelect: () =>
              openModal(RenameDialog, {
                defaultName: team.name,
                onSubmit: handleRenameTeam,
                title: 'Rename team',
                label: 'Team name',
              }),
          },
        ]
      : []),
    {
      title: isCrewTeam ? 'Add crew member' : 'Add members',
      icon: <PlusIcon />,
      onSelect: openAssignContactModal,
    },
    ...(!isCrewTeam
      ? [
          {
            title: 'Delete',
            icon: <TrashIcon />,
            type: 'danger' as const,
            onSelect: () =>
              openModal(DeleteDialog, {
                onSubmit: handleDeleteTeam,
                title: 'Delete team',
                text: `Are you sure you want to delete ${team.name} team?`,
              }),
          },
        ]
      : []),
  ];

  if (!team) {
    return <div />;
  }

  const handleDeleteCrewMember = async (contactId: string) => {
    await team.deleteContact(contactId);
  };

  const handleOnUpdate = async (contactId: string, values: Partial<TeamContact>) => {
    await team.updateContact(contactId, values);
  };

  const handleAssignTeamContact = async (contactId: string, role?: string) => {
    await team.addContact({ contactId, role: role || '' });
  };

  const handleMetaUpdate = async (
    contactId: string,
    values: Omit<Exclude<Step['contactsMetaData'], undefined>[number], 'contactId'>,
  ) => {
    // @ts-ignore
    await step.update({ contactsMetaData: { contactId, ...values } });
  };

  const handleToggleHide = async (contactId: string) => {
    await step.toggleHideContact(contactId);
  };

  const handleRenameTeam = async (name: string) => {
    try {
      await team.update({ name });
    } catch (e) {
      catchError(e);
    }
  };

  const handleDeleteTeam = async () => {
    try {
      await team.delete();
    } catch (e) {
      catchError(e);
    }
  };

  return (
    <ConditionalWrapper
      condition={isDesktop}
      wrapper={(children) => (
        <CollapsibleItem
          title={team.name}
          actions={
            <DropdownMenu
              trigger={<DropdownTrigger data-dark="true">⋮</DropdownTrigger>}
              items={menuItems}
            />
          }
          defaultOpen
        >
          <TableContainer>{children}</TableContainer>
        </CollapsibleItem>
      )}
    >
      <MembersList
        title={team.name}
        actions={
          <DropdownMenu
            trigger={<DropdownTrigger data-dark="true">⋮</DropdownTrigger>}
            items={menuItems}
          />
        }
        meta={step.contactsMetaData}
        onUpdate={handleOnUpdate}
        {...(!isCrewTeam && { onDelete: handleDeleteCrewMember })}
        onMetaUpdate={handleMetaUpdate}
        onToggleHide={handleToggleHide}
        departments={departments}
        contacts={team.contacts}
        defaultCallTime={{
          hour: step.info?.generalCallTime?.hour || 0,
          minute: step.info?.generalCallTime?.minute || 0,
        }}
        placeholder={
          !team.contacts?.length && (
            <EmptyTablePlaceholder
              description={
                isCrewTeam
                  ? 'No crew members selected yet. You can also manage this team in the Crew step.'
                  : `${team.name} has no members.`
              }
              action={{
                text: isCrewTeam ? 'Add crew member' : 'Add members',
                onClick: openAssignContactModal,
              }}
            />
          )
        }
      />
    </ConditionalWrapper>
  );
});
