import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import * as yup from 'yup';

import { MemberItemDropdown } from '../../../../features/teams/components/member-item-dropdown';
import { InlineDropDown } from '../../../../components/dropdowns/InlineDropDown';
import { InlineEditableText } from '../../../../components/editable-text/inline-editable-text';
import { InlineTimeSelector } from '../../../../features/schedule/components/InlineTimeSelector';
import {
  Table,
  TableHeader,
  THead,
  Cell,
  Row,
} from '../../../../components/table/styled-shared-table';

import type { Step } from '../../../../app/entities/step';
import type { TeamContact } from '../../../../features/teams/models/team';
import type { MemberListProps } from './members-list';

type MemberTableProps = Omit<MemberListProps, 'meta' | 'contacts'> & {
  contacts?: (TeamContact & Exclude<Step['contactsMetaData'], undefined>[number])[];
  getDepartmentName: (departmentId: string) => string | undefined;
};

export const MembersTable: React.FC<MemberTableProps> = observer(
  ({
    contacts,
    departments,
    onDelete,
    onToggleHide,
    onUpdate,
    onMetaUpdate,
    protectedTeamName,
    defaultCallTime,
    getDepartmentName,
    placeholder,
  }) => {
    if (placeholder) {
      return placeholder;
    }

    const departmentsOptions = departments?.map((department) => ({
      value: department._id,
      label: department.name,
    }));

    const schema = {
      role: yup.string().max(32, 'Role must be less than 32 characters'),
      firstName: yup.string().required().max(32, 'First name must be less than 32 characters'),
      lastName: yup.string().max(32, 'Last name must be less than 32 characters'),
      email: yup.string().email('Please enter a valid email'),
      phone: yup.string(),
    };

    const isEditable = !!onUpdate;

    return (
      <Table>
        {departments && (
          <col
            style={{
              width: '10%',
            }}
          />
        )}

        <col
          style={{
            width: '9%',
          }}
        />

        <col
          style={{
            width: '9%',
          }}
        />

        <col
          style={{
            width: '9%',
          }}
        />

        <col
          style={{
            width: '15%',
          }}
        />

        <col
          style={{
            width: '11%',
          }}
        />

        {onMetaUpdate && (
          <col
            style={{
              width: '9%',
            }}
          />
        )}

        <col
          style={{
            width: '28%',
          }}
        />

        <TableHeader>
          {departments && <TableHeadCell>Department</TableHeadCell>}

          <TableHeadCell>Role</TableHeadCell>

          <TableHeadCell>First Name</TableHeadCell>

          <TableHeadCell>Last Name</TableHeadCell>

          <TableHeadCell>Email</TableHeadCell>

          <TableHeadCell>Phone</TableHeadCell>

          {onMetaUpdate && <TableHeadCell>Call Time</TableHeadCell>}

          <TableHeadCell />
        </TableHeader>

        <tbody>
          {contacts?.map((contact, index) => (
            <TableRow key={`${contact._id}_${index}`} data-hidden={contact.isHidden}>
              {!!departments && (
                <Cell>
                  {departmentsOptions?.length ? (
                    <InlineDropDown
                      value={contact.departmentId || ''}
                      label={getDepartmentName(contact.departmentId || '')}
                      onChange={(departmentId: string) => onUpdate!(contact?._id, { departmentId })}
                      options={departmentsOptions}
                      disabled={!isEditable}
                    />
                  ) : (
                    !isEditable &&
                    protectedTeamName && <ExecutiveLabel>{protectedTeamName}</ExecutiveLabel>
                  )}
                </Cell>
              )}

              <Cell>
                <InlineEditableText
                  value={contact.role}
                  schema={schema.role}
                  disabled={!isEditable}
                  onSubmit={(role) => onUpdate!(contact._id, { role })}
                />
              </Cell>

              <Cell>
                <InlineEditableText
                  value={contact.firstName}
                  schema={schema.firstName}
                  disabled={!isEditable}
                  onSubmit={(firstName) => onUpdate!(contact._id, { firstName })}
                />
              </Cell>

              <Cell>
                <InlineEditableText
                  value={contact.lastName}
                  schema={schema.lastName}
                  disabled={!isEditable}
                  onSubmit={(lastName) => onUpdate!(contact._id, { lastName })}
                />
              </Cell>

              <Cell>
                <InlineEditableText
                  value={contact.email}
                  schema={schema.email}
                  disabled={!isEditable}
                  onSubmit={(email) => onUpdate!(contact._id, { email })}
                />
              </Cell>

              <Cell>
                <InlineEditableText
                  value={typeof contact.phone === 'string' ? contact.phone : contact.phone?.number}
                  schema={schema.phone}
                  disabled={!isEditable}
                  onSubmit={(phone) => {
                    if (phone !== undefined) onUpdate!(contact?._id, { phone });
                  }}
                />
              </Cell>

              {onMetaUpdate &&
                (!contact.isHidden ? (
                  <Cell>
                    {!!contact._id ? (
                      <InlineTimeSelector
                        onChange={(callTime) => onMetaUpdate(contact._id, { callTime })}
                        time={contact.callTime || defaultCallTime!}
                      />
                    ) : (
                      '-'
                    )}
                  </Cell>
                ) : (
                  <Cell />
                ))}

              <Cell style={{ textAlign: 'end' }}>
                {(onDelete || onToggleHide) && (
                  <MemberItemDropdown
                    {...(onDelete && { onDelete: () => onDelete(contact._id) })}
                    {...(onToggleHide && { onToggleHide: () => onToggleHide(contact._id) })}
                    isHidden={contact.isHidden}
                    isCast={!!contact._id}
                  />
                )}
              </Cell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    );
  },
);

const TableHeadCell = styled(THead)`
  border-bottom: 0.1rem solid var(--color-grayscale-tuna);

  &:first-child,
  &:last-child {
    padding-inline-start: 1.6rem;
  }
`;

const ExecutiveLabel = styled.span`
  cursor: default;
  background: transparent;
  color: var(--color-grayscale-ghost);
  font-size: 1.4rem;
  font-weight: 400;
`;

const TableRow = styled(Row)`
  input,
  div {
    color: var(--color-grayscale-white);
  }

  &[data-hidden='true'] {
    ${Cell} {
      *:not(button),
      *:hover:not(button),
      *:focus-visible:not(button) {
        color: var(--color-grayscale-trout);
      }
    }
  }
`;
