import { computed, makeObservable, observable } from 'mobx';
import merge from 'lodash.merge';

import { Step } from './step';
import { Model } from '../../core/engine/model';
import { ManyToOne, OneToMany } from '../../core/engine/decorators';
import { Project } from './project';
import { api } from '../../api';

export class Stage extends Model {
  /** The stage's name **/
  name: string;

  order: number;

  @OneToMany('stage')
  steps: Step[];

  projectId: string;

  startDate?: string;

  dueDate?: string;

  @ManyToOne('stages')
  project: Project | null = null;

  get totalSteps() {
    return this.steps.length;
  }

  get completedSteps() {
    return this.steps.reduce((acc, step) => (step.done ? acc + 1 : acc), 0);
  }

  constructor() {
    super('stages');

    this.steps = [];

    makeObservable<Stage>(this, {
      _id: observable,
      createdAt: observable,
      updatedAt: observable,
      projectId: observable,
      name: observable,
      order: observable,
      steps: observable,
      totalSteps: computed,
      completedSteps: computed,
    });
  }

  toPOJO(): Record<string, any> {
    return {
      _id: this._id,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      projectId: this.projectId,
      name: this.name,
      order: this.order,
      steps: this.steps.map((step) => step.toPOJO()),
      totalSteps: this.totalSteps,
      completedSteps: this.completedSteps,
    };
  }

  async update(values: Partial<Omit<this, '_id'>>) {
    await api.patch(`/projects/${this.projectId}/stages/${this._id}`, values).then(({ data }) => {
      merge(this, values);
      return data;
    });
  }

  static getOne(id: string): Stage | undefined {
    return Model.getOne(id) as Stage;
  }

  static getAll(): Stage[] {
    return Model.getAll().filter((m) => m.$name === 'stages') as Stage[];
  }

  reorderShootingDays() {
    const shootingDaysWithoutStartDate: Step[] = [];
    const shootingDaysWithStartDate: Step[] = [];

    this.steps.forEach((step) => {
      if (!step.startDate) {
        shootingDaysWithoutStartDate.push(step);
      } else {
        shootingDaysWithStartDate.push(step);
      }
    });

    this.steps = [
      ...shootingDaysWithStartDate.sort(
        (a, b) => new Date(a!.startDate!).getTime() - new Date(b!.startDate!).getTime(),
      ),
      ...shootingDaysWithoutStartDate.sort(
        (a, b) => new Date(a!.createdAt).getTime() - new Date(b!.createdAt).getTime(),
      ),
    ];

    for (let i = 0; i < this.steps.length; i++) {
      this.steps[i].order = i + 1;
    }
  }
}
