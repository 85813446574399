import React, { DragEvent, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { CoverImage } from '../../../components/cover-image/cover-image';
import { EditableText } from '../../../components/editable-text/EditableText';
import { Description } from '../../../components/texts/Texts';
import { enumToSelectOptions } from '../../../lib/utils/Convertor';
import { SelectDrawer } from '../../../components/drawer-inputs/select-drawer';
import { InputDrawer } from '../../../components/drawer-inputs/input-drawer';
import { TextAreaDrawer } from '../../../components/drawer-inputs/text-area-drawer';
import { NumberInputDrawer } from '../../../components/drawer-inputs/number-input-drawer';
import { LocationSelect } from './location-select';
import { StoryboardItemHeader } from './StoryboardItemHeader';
import { Bloc, BlockTitle, InputContainer } from '../../../routes/project/components/step-drawer';
import { CharactersSelect } from './characters-select';
import { formatPicture } from '../../../core/services/image.service';
import { CameraAngleEnum } from '../../../assets/enums/cameraAngle.enum';
import { ShotMovementEnum } from '../../../assets/enums/shotMovement.enum';
import { getModifier } from '../../../core/services/event.service';
import { ShotSizeEnum } from '../../../assets/enums/shotSize.enum';
import { Shot, UpdateShotsInput } from '../../../app/entities/shot';
import { Storyboard } from '../../../app/entities/storyboard';
import { TimeInputDrawer } from '../../../components/drawer-inputs/time-input-drawer';
import { LimitReachedModal } from '../../../components/dialogs/limit-reached.modal';
import { useSelection, type Modifier } from '../../../core/contexts/selection.context';
import { bulkUpdateStore } from '../../../core/stores/bulk-update';
import { StoryBoardSettingsKeys } from '../../../app/entities/member';
import { TagsViewer } from '../../../features/tags/components/tags-viewer';

import type { StoryboardSettings } from '../models/storyboard-settings';
import { openModal } from '../../../core/modal/open-modal';

export enum Layout {
  Grid = 'grid',
}

const PROPERTY_VALUES: any = {
  angle: CameraAngleEnum,
  movement: ShotMovementEnum,
  shotSize: ShotSizeEnum,
};

export interface ShotCardProps {
  shotId: string;
  projectId: string;
  storyboardId: string;
  active?: boolean;
  position?: number;
  layout: Layout;
  style?: any;
  onClick?: (shotId: string, modifier?: Modifier) => void;
  options: StoryboardSettings;
  storyboardPreferences?: {
    zoom: number;
    displayedProperties?: Record<StoryBoardSettingsKeys, boolean>;
  };
}

export const ShotCard = observer(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLDivElement, ShotCardProps>(
    (
      {
        shotId,
        projectId,
        storyboardId,
        position,
        active,
        layout,
        style,
        options,
        onClick,
        storyboardPreferences,
        ...props
      },
      ref,
    ) => {
      const { t } = useTranslation('shot');
      const { elements: selectedShotsIds } = useSelection();

      const storyboard = Storyboard.getOne(storyboardId);
      const shot = Shot.getOne(shotId);

      const isBulkUpdate = selectedShotsIds && selectedShotsIds.length > 1;

      const [expand, setExpand] = useState(false);

      const topSectionProperties = options.properties
        ?.filter((property) => !['voiceOver', 'directorsNote', 'tags'].includes(property.type))
        .filter(
          (property) =>
            !!storyboardPreferences?.displayedProperties?.[
              property.id as keyof typeof storyboardPreferences.displayedProperties
            ],
        );

      const bottomSectionProperties = options.properties
        ?.filter((property) => ['voiceOver', 'directorsNote', 'tags'].includes(property.type))
        .filter(
          (property) =>
            !!storyboardPreferences?.displayedProperties?.[
              property.id as keyof typeof storyboardPreferences.displayedProperties
            ],
        );

      const shouldLoadBlock = !!topSectionProperties?.length || !!bottomSectionProperties?.length;

      const handleSingleEdit = (data: UpdateShotsInput) => {
        shot && shot.update(data!);
      };

      const handleEdit = (data: UpdateShotsInput) => {
        const dataCantBeBulkUpdated =
          data.title !== undefined ||
          data.description !== undefined ||
          data.voiceOver !== undefined ||
          data.directorsNote !== undefined;

        if (dataCantBeBulkUpdated || !isBulkUpdate) {
          handleSingleEdit(data);
          return;
        }

        bulkUpdateStore.handleShotsBulkUpdate(data, selectedShotsIds, shot!);
      };

      const handleCreateShot = async (file?: File) => {
        if (!shot?.project?.canAddShot()) {
          return openModal(LimitReachedModal, {
            type: 'shots',
            eventName: 'Shots exceed 10',
          });
        }

        if (!projectId) {
          return;
        }

        let frame;
        if (file) {
          const options = { width: 640, height: 380 };
          frame = await formatPicture(file, options);
        }

        await Shot.createShot(
          {
            projectId,
            position: position || 0,
            file: frame,
          },
          storyboard?._id,
        );
      };

      const handleDragEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setExpand(true);
      };

      const handleDragLeave = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setExpand(false);
      };

      const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
      };

      const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.persist();
        setExpand(false);

        const files = Array.from(e.dataTransfer.files);

        for (let file of files) {
          await handleCreateShot(file);
        }
      };

      const handleClick = async () => {
        handleCreateShot();
      };

      const renderProperty = (
        property: { id: string; name: string; type: string },
        index: number,
      ) => {
        if (!shot) return;

        switch (property.type) {
          case 'select':
            return (
              <InputContainer key={property.id} style={{ zIndex: 100 - index }}>
                <SelectDrawer
                  value={shot[property.id as keyof Shot] as string}
                  onSelect={({ target }: any) => handleEdit({ [property.id]: target.value })}
                  options={enumToSelectOptions(PROPERTY_VALUES[property.id], t)}
                  label={property.name}
                />
              </InputContainer>
            );
          case 'text':
            return (
              <InputContainer key={property.id} style={{ zIndex: 100 - index }}>
                <InputDrawer
                  value={shot[property.id as keyof Shot] as string}
                  label={property.name}
                  onChange={(value) => handleEdit({ [property.id]: value })}
                />
              </InputContainer>
            );
          case 'number':
            return (
              <InputContainer key={property.id} style={{ zIndex: 100 - index }}>
                <NumberInputDrawer
                  canNeg={false}
                  value={shot[property.id as keyof Shot] as number}
                  onChange={(value) => handleEdit({ [property.id]: value })}
                  label={property.name}
                />
              </InputContainer>
            );
          case 'time':
            return (
              <InputContainer key={property.id} style={{ zIndex: 100 - index }}>
                <TimeInputDrawer
                  value={shot[property.id as keyof Shot] as number}
                  onChange={(value) => handleEdit({ [property.id]: value })}
                  label={property.name}
                />
              </InputContainer>
            );
          case 'voiceOver':
            return (
              <Bloc key={property.id}>
                <BlockTitle>Voice over</BlockTitle>

                <TextAreaDrawer
                  value={shot.voiceOver || ''}
                  onChange={(value) => handleSingleEdit({ [property.id]: value })}
                />
              </Bloc>
            );
          case 'directorsNote':
            return (
              <Bloc key={property.id}>
                <BlockTitle>Director&apos;s notes</BlockTitle>
                <TextAreaDrawer
                  value={shot?.directorsNote || ''}
                  onChange={(value) => handleSingleEdit({ [property.id]: value })}
                />
              </Bloc>
            );
          case 'location':
            return (
              <InputContainer key={property.id} style={{ zIndex: 100 - index }}>
                <LocationSelect
                  projectId={projectId}
                  onChange={(locationId) => handleEdit({ locationId })}
                  selectedLocationId={shot.locationId}
                />
              </InputContainer>
            );
          case 'characters':
            return (
              <CharactersSelect
                key={property.id}
                isShotsBulkUpdate={selectedShotsIds && selectedShotsIds.length > 1}
                handleShotsBulkUpdate={({ characterIdToRemove, characterIdToAdd }) =>
                  handleEdit({ characterIdToRemove, characterIdToAdd })
                }
                shot={shot}
              />
            );
          case 'tags':
            return (
              <Bloc key={property.id}>
                <BlockTitle>Tags</BlockTitle>
                <TagsViewer
                  targetId={shot._id}
                  targetType="shot"
                  overflow="wrap"
                  alignment="start"
                />
              </Bloc>
            );
          default:
            return;
        }
      };

      const handleShotClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        const modifier = getModifier(e.nativeEvent);

        if (!shot) return;

        onClick && onClick(shot._id, modifier);
      };

      if (!shot) return null;

      return (
        <Wrapper active={active} data-zoom={storyboardPreferences?.zoom}>
          <PageContainer ref={ref}>
            <InsertOverlay
              onDrop={handleDrop}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              data-expand={expand}
            >
              <InsertTile onClick={handleClick}>+</InsertTile>
            </InsertOverlay>

            <ShotContainer onClick={handleShotClick} style={style} {...props}>
              <Cover>
                {!!shot.order &&
                  storyboardPreferences?.displayedProperties?.shotNumber !== false && (
                    <OrderIndicator>
                      <div>{shot.order}</div>
                    </OrderIndicator>
                  )}

                <CoverImage
                  src={shot.cover?.src || ''}
                  title={shot.title}
                  onEdit={(file) => handleEdit({ file })}
                />

                <StoryboardItemHeader shot={shot} zoomLevel={storyboardPreferences?.zoom || 1} />

                {shot.voiceOver &&
                  storyboard &&
                  storyboardPreferences &&
                  storyboardPreferences.zoom > 4 && (
                    <SubtitleContainer>
                      <Subtitle
                        dangerouslySetInnerHTML={{
                          __html: shot.voiceOver.replaceAll('\n', '<br>'),
                        }}
                      />
                    </SubtitleContainer>
                  )}
              </Cover>

              <Footer>
                <BasicInfoWrapper>
                  <EditableText
                    onSubmit={(title) => handleEdit({ title })}
                    defaultText={'Add a title'}
                  >
                    <Title data-is-default={!shot.title}>
                      {shot.title ? shot.title : 'Add a title'}
                    </Title>
                  </EditableText>

                  <EditableText
                    onSubmit={(description) => handleEdit({ description })}
                    defaultText={'Add a description...'}
                  >
                    <Description data-is-default={!shot.description}>
                      {shot.description ? shot.description : 'Add a description...'}
                    </Description>
                  </EditableText>
                </BasicInfoWrapper>

                {shouldLoadBlock && (
                  <Block>
                    {!!topSectionProperties?.length && (
                      <div>{topSectionProperties.map(renderProperty)}</div>
                    )}

                    {!!bottomSectionProperties?.length && (
                      <div>{bottomSectionProperties.map(renderProperty)}</div>
                    )}
                  </Block>
                )}
              </Footer>
            </ShotContainer>
          </PageContainer>
        </Wrapper>
      );
    },
  ),
);

ShotCard.displayName = 'ShotCard';

const BasicInfoWrapper = styled.div`
  display: block;
`;

const PageContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  background-size: cover;
  border-radius: 3px;
  height: 100%;
  outline: none;
  appearance: none;
  border: none;

  &:focus-visible:not(.active &) {
    box-shadow: 0 0 0 2px #4c9ffe;
  }
`;

const ShotContainer = styled.div`
  position: relative;
  transition: 0.3s all ease;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  background: var(--card-bg-color);
  border-radius: var(--card-border-radius);
  border: var(--storyboard-border-color);
  user-select: none;
  height: 100%;
`;

const InsertTile = styled.div<any>`
  border-radius: 0.8rem;
  font-size: 1.6rem;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  color: transparent;
  cursor: pointer;
  user-select: none;
`;

const BaseHoverInsertTile = css`
  width: 30%;
  right: -10%;

  & > ${InsertTile} {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 40%;
    border: dashed 2px white;
    color: white;
  }

  & + div {
    transform: scale(0.8) translateX(-5%) !important;
  }
`;

const InsertOverlay = styled.div<any>`
  display: none;
  position: absolute;
  right: -1.6rem;
  top: 50%;
  width: 1.6rem;
  transition: all 0.1s ease-in-out;
  height: 80%;
  transform: translateY(-50%);
  justify-content: center;
  align-items: center;
  color: transparent;
  cursor: default;

  &:hover {
    ${BaseHoverInsertTile}
  }

  @media screen and (min-width: 768px) {
    display: flex;
  }

  &[data-expand='true'] {
    ${BaseHoverInsertTile}
    & > * {
      pointer-events: none;
    }
  }
`;

const Title = styled.div`
  font-family: Inter, sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0;
  width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  color: var(--text-color-white);

  &[data-is-default='true'] {
    color: var(--card-subtitle-color);
  }
`;

const SubtitleContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.6rem;
`;

const Subtitle = styled.div`
  z-index: 10;
  padding: 0.8rem;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.6rem;
  text-align: center;
  line-height: 2rem;
`;

const Cover = styled.div`
  position: relative;

  &:hover + #action,
  &:hover > #action,
  &:hover #action {
    visibility: initial;
  }
`;

const Footer = styled.footer`
  position: relative;
  padding: 1.2rem;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const OrderIndicator = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 8px;
  left: 8px;
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;
  background-color: var(--white-default);
  color: #111414;
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  z-index: 500;
  cursor: grab;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Wrapper = styled.div<any>`
  height: 100%;
  position: relative;
  list-style: none;
  width: 100%;
  min-width: 100%;
  opacity: ${({ active }) => (active ? 0.25 : 1)};
  transition: transform 200ms ease 0s;
  scroll-snap-align: center;

  &:hover {
    cursor: grab;
  }

  @media screen and (max-width: 640px) {
    &:nth-child(2n) {
      ${InsertTile} {
        opacity: 0;
      }
    }
  }

  @media screen and (min-width: 641px) and (max-width: 1240px) {
    &:nth-child(3n) {
      ${InsertTile} {
        opacity: 0;
      }
    }

    @media screen and (min-width: 1241px) {
      &:nth-child(4n) {
        ${InsertTile} {
          opacity: 0;
        }
      }
    }
  }

  &[data-zoom='1'],
  &[data-zoom='2'] {
    ${Footer}, ${BasicInfoWrapper}, ${Block} {
      display: none;
    }

    ${ShotContainer} {
      height: unset;
    }
  }

  &[data-zoom='3'],
  &[data-zoom='4'] {
    ${Footer} {
      display: flex;
    }

    ${BasicInfoWrapper} {
      display: block;
    }

    ${Block} {
      display: none;
    }
  }

  &[data-zoom='5'],
  &[data-zoom='6'] {
    ${BasicInfoWrapper} {
      display: block;
    }

    ${Footer}, ${Block} {
      display: flex;
    }
  }
`;
