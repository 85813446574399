import React from 'react';
import { observer } from 'mobx-react-lite';

import { createCharacter } from '../../character/character.slice';
import { MultiSelectDropdown } from '../../../components/select-dropdown';
import { Project } from '../../../app/entities/project';

import type { Shot } from '../../../app/entities/shot';

type ShotCharactersSelectorProps = {
  shot: Shot;
  onAdd: (characterId: string) => void;
  onRemove: (characterId: string) => void;
};

export const ShotCharactersSelector: React.FC<ShotCharactersSelectorProps> = observer(
  ({ shot, onAdd, onRemove }) => {
    const project = Project.getOne(shot.projectId)!;

    const options: React.ComponentProps<typeof MultiSelectDropdown>['options'] =
      project.characters?.map((character) => ({
        label: character.name,
        value: character._id,
        onSelect: (e) => {
          e.preventDefault();
        },
      }));

    const handleCharactersChange = (characterId: string, isChecked: boolean) => {
      if (isChecked) {
        onAdd(characterId);
      } else {
        onRemove(characterId);
      }
    };

    const handleCreate = async (name: string) => {
      return createCharacter({ projectId: project._id, characterInfo: { name } }).then(
        (character) => {
          shot.addCharacter(character._id);
        },
      );
    };

    const selectedOptions = shot.characters.map((character) => character._id);

    return (
      <MultiSelectDropdown
        title="Add Characters"
        options={options}
        selectedOptions={selectedOptions}
        onValueChange={handleCharactersChange}
        onCreate={handleCreate}
      />
    );
  },
);
