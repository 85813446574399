import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { getRenderedTime } from '../../../lib/utils/Convertor';
import { minutesFormatter } from '../../../lib/time-formatters';
import { BaseBlock } from './styled-strips';
import { Step } from '../../../app/entities/step';

import type { DayBreakStripProps } from './day-break-strip';
import { MobileDayStartStrip } from './day-start-strip.mobile';
import { useCurrentStep } from '../../../hooks/use-current-step';

export const MobileDayBreakStrip: React.FC<DayBreakStripProps> = observer(
  ({ isShotList, stripboard, strip }) => {
    const { dod, stepId, endTime, totalEstimatedTime, totalShotsDuration } = strip.data || {};

    const step = useCurrentStep();

    const shootingDay = Step.getOne(stepId)!;
    const totalShootingDays = stripboard.totalShootingDays;

    if (!shootingDay) {
      return null;
    }

    const renderedEndTime = !isShotList && getRenderedTime(endTime);

    const nextShootingDayStrip =
      totalShootingDays > 0 &&
      dod !== undefined &&
      dod < totalShootingDays &&
      strip.position !== undefined &&
      step?.type !== 'shootingDay'
        ? stripboard.getNextShootingDay(strip._id)
        : undefined;

    return (
      <>
        <Container>
          <EndOfDay>
            End of day{' '}
            {totalShootingDays && (
              <>
                {dod} of {totalShootingDays.toString()}
              </>
            )}
          </EndOfDay>

          <TimeContainer>
            <span>Total</span>

            {isShotList ? (
              <span>{minutesFormatter(totalShotsDuration)}</span>
            ) : (
              <span>{minutesFormatter(totalEstimatedTime)}</span>
            )}

            {renderedEndTime && (
              <>
                <span>End</span>

                <span>{getRenderedTime(endTime)}</span>
              </>
            )}
          </TimeContainer>
        </Container>

        {nextShootingDayStrip && (
          <MobileDayStartStrip stripboard={stripboard} strip={nextShootingDayStrip} />
        )}
      </>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background-color: var(--color-shades-hibiscus);
`;

const EndOfDay = styled(BaseBlock)`
  font-weight: 400;
  font-size: 1.2rem;
  padding: 0.8rem;
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  font-weight: 400;
  font-size: 1.2rem;
  color: var(--color-grayscale-ghost);
`;
