import React, { useState } from 'react';
import { ArchiveIcon, DownloadIcon } from '@radix-ui/react-icons';

import { SliderInput } from '../../../components/slider-input/slider-input';
import { StoryboardPropertiesMenu } from './storyboard-properties-menu';
import { ElevatedButton, IconButton } from '../../../components/buttons';
import { ToolBar, Tools } from '../../../components/toolbar/toolbar';
import { Storyboard } from 'app/entities/storyboard';
import { BufferToBlob } from '../../../lib/utils/BufferToBolb';
import { createFileName } from '../../../core/services/file.service';
import styled from 'styled-components';
import { LimitReachedModal } from '../../../components/dialogs/limit-reached.modal';
import { useStoryboard } from '../hooks/useStoryboard';
import { Tooltip } from '@producer-io/ui-kit';
import { useCurrentMember } from '../../../hooks/use-current-member';

interface StoryboardToolbarProps {
  storyboard: Storyboard;
  toggleBoneyard: () => void;
}

export const StoryboardToolbar: React.FC<StoryboardToolbarProps> = ({
  storyboard,
  toggleBoneyard,
}) => {
  const currentMember = useCurrentMember();

  const storyboardPreferences = currentMember?.preferences?.storyBoardPreferences;

  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [showLimitReachedModal, setShowLimitReachedModal] = useState(false);
  const { fetchStoryboardAsPDF } = useStoryboard(storyboard.project!._id);

  const handleDownload = async () => {
    if (generatingPDF || !storyboard) {
      return;
    }

    if (!storyboard.project?.space?.canExportPdf()) {
      return setShowLimitReachedModal(true);
    }

    setGeneratingPDF(true);

    try {
      const file = await fetchStoryboardAsPDF(
        storyboard._id,
        storyboardPreferences?.displayedProperties,
      );

      BufferToBlob(file, createFileName(storyboard.project.name, `${storyboard.name || 'Storyboard'}.pdf`));
    } catch (e) {
      console.log(e);
    }

    setGeneratingPDF(false);
  };

  const handleToggleProperties = (propertyId: string) => {
    if (!storyboard) return;
    currentMember?.toggleSetting(propertyId);
  };

  const handleShowAll = () => {
    if (!storyboard) return;
    currentMember?.showAllSettings();
  };

  const handleHideAll = () => {
    if (!storyboard) return;
    currentMember?.hideAllSettings();
  };

  const handleZoomLevel = (value: number) => {
    if (!storyboard) return;
    currentMember?.changeZoomLevel(value);
  };

  return (
    <>
      <ToolBar>
        <div />
        <Tools>
          <p>Total shots: {storyboard?.shots.length}</p>

          <ZoomInputWrapper key="zoom-input">
            <SliderInput
              min={1}
              max={6}
              step={1}
              value={storyboardPreferences?.zoom || 5}
              onChange={handleZoomLevel}
            />
          </ZoomInputWrapper>

          <StoryboardPropertiesMenu
            key="properties"
            storyboard={storyboard}
            userPreferences={storyboardPreferences?.displayedProperties}
            onToggle={handleToggleProperties}
            onShowAll={handleShowAll}
            isDisabled={(storyboardPreferences?.zoom || 7) <= 4}
            onHideAll={handleHideAll}
          />

          <Tooltip text="Shot archive" position="bottom">
            <IconButton icon={<ArchiveIcon />} onClick={toggleBoneyard} />
          </Tooltip>

          <ElevatedButton
            key="download"
            onClick={handleDownload}
            isLoading={generatingPDF}
            disabled={!storyboard?.shots?.length}
            text="Generate PDF"
            icon={<DownloadIcon />}
          />
        </Tools>
      </ToolBar>

      {showLimitReachedModal && (
        <LimitReachedModal
          title="Upgrade your space to export your storyboard as a PDF"
          eventName="Export storyboard attempt"
          onCancel={() => setShowLimitReachedModal(false)}
        />
      )}
    </>
  );
};

const ZoomInputWrapper = styled.div`
  flex: 1 1 0;
`;
