import React from 'react';
import { CalendarIcon, CopyIcon, ImageIcon, PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { Project } from '../../../app/entities/project';
import { Shot, UpdateShotsInput } from '../../../app/entities/shot';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { DropdownTrigger } from '../../projects/components/styled-project-components';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { useSelection } from '../../../core/contexts/selection.context';
import { bulkUpdateStore } from '../../../core/stores/bulk-update';
import { openModal } from '../../../core/modal/open-modal';

type AddStripMenuProps = {
  stripId?: string;
  projectId: string;
  position: number;
  shot?: Shot;
  isDark?: boolean;
  stripType: 'banner' | 'shot' | 'dayBreak' | 'dayStart';
};

export const AddStripMenu: React.FC<AddStripMenuProps> = ({
  stripId,
  projectId,
  position,
  shot,
  isDark,
  stripType,
}) => {
  const { elements: selectedStripsIds } = useSelection();

  const isBulkUpdate = selectedStripsIds && selectedStripsIds.length > 1;

  const project = Project.getOne(projectId)!;
  const stripboard = project.stripboard;

  const unassignShootingDay = () => {
    if (!stripId) return;
    // @todo: This should be addressed in refactoring to
    // un-assign shooting days, using handleUpdate and
    // sending undefined when BE updates
    stripboard.deleteStrip(stripId);
  };

  const handleDelete = async () => {
    if (!stripId) return;
    shot ? await shot.delete() : await stripboard.deleteStrip(stripId);
  };

  const handleAdd = (
    data: { text: string; estimatedTime: number },
    sequence: 'before' | 'after',
  ) => {
    let stripPosition = position || 1;
    const { text, estimatedTime } = data;

    if (sequence === 'after') {
      if (position === 0) stripPosition = 1;
      else stripPosition += 1;
    }

    stripboard.addBannerStrip({
      position: stripPosition,
      text,
      estimatedTime,
    });
  };

  const handleShotsBulkUpdate = (data: UpdateShotsInput) => () => {
    const selectedShotsIds = stripboard.strips
      .filter((el) => selectedStripsIds.includes(el._id) && el.type === 'shot')
      .map((el) => el.data.shotId);

    bulkUpdateStore.handleShotsBulkUpdate(data, selectedShotsIds, shot!);
  };

  const addStripSubmenu = (
    sequence: 'before' | 'after',
  ): React.ComponentProps<typeof DropdownMenu>['items'] => {
    const handleOnSubmenuClick = (data: { text: string; estimatedTime: number }) =>
      handleAdd(data, sequence);

    return [
      {
        title: 'Setup',
        icon: <PlusIcon />,
        onSelect: () => handleOnSubmenuClick({ estimatedTime: 90, text: 'Setup' }),
      },
      {
        title: 'Coffee',
        icon: <PlusIcon />,
        onSelect: () => handleOnSubmenuClick({ estimatedTime: 5, text: 'Coffee' }),
      },
      {
        title: 'Company move',
        icon: <PlusIcon />,
        onSelect: () => handleOnSubmenuClick({ estimatedTime: 90, text: 'Company move' }),
      },
      {
        title: 'Lunch time',
        icon: <PlusIcon />,
        onSelect: () => handleOnSubmenuClick({ estimatedTime: 60, text: 'Lunch time' }),
      },
      {
        title: 'Thats a wrap',
        icon: <PlusIcon />,
        onSelect: () => handleOnSubmenuClick({ estimatedTime: 10, text: 'Thats a wrap' }),
      },
      {
        title: 'Packup',
        icon: <PlusIcon />,
        onSelect: () => handleOnSubmenuClick({ estimatedTime: 90, text: 'Packup' }),
      },
      {
        title: 'Custom',
        icon: <PlusIcon />,
        onSelect: () => handleOnSubmenuClick({ estimatedTime: 0, text: 'Custom' }),
      },
    ];
  };

  const handleUpdate = async (shootingDayId: string) => {
    shot && (await shot.update({ shootingDayId }));
  };

  const otherShootingDays =
    project.shootingDays
      ?.filter((step) => step && step._id !== shot?.shootingDayId)
      .sort((a, b) => a.order - b.order)
      .map((step) => ({
        title: step?.displayName as string,
        icon: <CalendarIcon />,
        onSelect: isBulkUpdate
          ? handleShotsBulkUpdate({ shootingDayId: step._id })
          : () => handleUpdate(step._id),
      })) || [];

  const menuItems: React.ComponentProps<typeof DropdownMenu>['items'] = [
    ...(stripType !== 'dayStart'
      ? [
          {
            title: 'Add before...',
            subItems: addStripSubmenu('before'),
            icon: <PlusIcon />,
          },
        ]
      : []),
    {
      subItems: addStripSubmenu('after'),
      title: 'Add after...',
      icon: <PlusIcon />,
    },
    ...(shot
      ? [
          {
            title: 'Replace image',
            icon: <ImageIcon />,
            onSelect: shot.changeImage,
          },
          {
            title: 'Re-assign shooting day',
            icon: <CalendarIcon />,
            subItems: [
              {
                title: 'No shooting day',
                icon: <CalendarIcon />,
                onSelect: isBulkUpdate
                  ? handleShotsBulkUpdate({ shootingDayId: null })
                  : unassignShootingDay,
              },
              ...(otherShootingDays?.length ? otherShootingDays : []),
            ],
          },
          {
            title: 'Duplicate shot',
            icon: <CopyIcon />,
            onSelect: () => Shot.duplicateShots([shot._id]),
          },
        ]
      : []),
    ...(stripType !== 'dayStart'
      ? [
          {
            onSelect: () =>
              stripType === 'banner'
                ? handleDelete()
                : openModal(DeleteDialog, {
                    title: 'Delete shot?',
                    text: `The shot will be deleted completely from the project.
          For removing a shot from a specific day only, use "Re-assign shooting day → No shooting day".`,
                    onSubmit: handleDelete,
                  }),
            type: 'danger' as const,
            title: shot ? 'Delete shot' : 'Delete',
            icon: <TrashIcon />,
          },
        ]
      : []),
  ];

  return (
    <Container>
      <DropdownMenu
        items={menuItems}
        trigger={<DropdownTrigger data-dark={isDark}>⋮</DropdownTrigger>}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
