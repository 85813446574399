import { api } from 'api';
export interface PaymentMethod {
  id: string;
  title: string;
  cardNumber: string;
  isDefault: boolean;
}

export interface PreviewAddMemberUpdatesResponse {
  currentPayment: {
    amount: string;
    currency: string;
  };
  nextPayment: {
    amount: string;
    currency: string;
    date: string;
  };
}

export const previewAddMemberUpdates = async (
  spaceId: string,
  numberOfMembers: number = 1,
): Promise<PreviewAddMemberUpdatesResponse | null> => {
  const { data } = await api.post(`/payment/space/${spaceId}/preview-add-member-updates`, {
    numberOfMembers,
  });
  return data;
};

export const getTransactionDetails = async (spaceId: string, transactionId: string) => {
  const { data } = await api.get(`/payment/spaces/${spaceId}/transactions/${transactionId}`);

  return data;
};
