import React, { useEffect } from 'react';
import { useParams, Navigate, Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SettingsPageLayout } from './settings-page-layout';
import { PageLoader } from '../../../app/components/page-loader';
import { useCurrentSpace } from 'hooks/use-current-space';

const menu = [
  {
    key: 'general',
    label: 'General',
    href: `../settings/general`,
  },
  {
    key: 'billings',
    label: 'Billing',
    href: `../../settings/billings`,
  },
  {
    key: 'plans',
    label: 'Plans',
    href: `../settings/plans`,
  },
  {
    key: 'users',
    label: 'Users',
    href: `../settings/users`,
  },
  {
    key: 'tags',
    label: 'Tags',
    href: `../settings/tags`,
  },
  {
    key: 'storage',
    label: 'Storage',
    href: `../settings/storage`,
  },
];

export const SpaceSettingsPage: React.FC = observer(() => {
  const { spaceId } = useParams() as { spaceId: string };
  const space = useCurrentSpace();

  useEffect(() => {
    const isSecurityScreenEnabled = !!space?.flags?.isSSOEnabled;

    if (isSecurityScreenEnabled) {
      menu.push({
        key: 'security',
        label: 'Security',
        href: `../settings/security`,
      });
    }
  }, [space]);

  if (!space || !space.flags) {
    return <PageLoader />;
  }

  if (!space.flags['space.settings']) {
    return <Navigate to={`/space/${spaceId}`} />;
  }

  return (
    <SettingsPageLayout title="Space settings" menu={menu}>
      <Outlet />
    </SettingsPageLayout>
  );
});
