import { makeObservable, observable } from 'mobx';

import { api } from '../../api';
import { Model } from '../../core/engine/model';
import { entityPool } from '../../core/engine/engine';
import { catchError } from '../../core/catch-error';
import { ManyToMany, ManyToOne } from 'core/engine/decorators';
import { Space } from './space';
import { Asset } from './asset';
import { Task } from './task';
import { Shot } from './shot';

export type TagTarget = 'shot' | 'asset' | 'task';

export type SaveTagDto = {
  text?: string;
  color?: string;
  target?: TagTarget;
};

export enum TagColor {
  Blue = '#367BFF',
  Magenta = '#D53075',
  Gray = '#7A8296',
  Yellow = '#F8A72D',
  Green = '#38C2B8',
  Bronze = '#A4846A',
}

export class Tag extends Model {
  spaceId: string;
  text: string;
  // hex color code
  color: TagColor;
  target: TagTarget;
  // how many times this tag is used
  usage: number;

  @ManyToOne('tags')
  space: Space;

  @ManyToMany('tags')
  assets: Asset[] = [];

  @ManyToMany('tags')
  tasks: Task[] = [];

  @ManyToMany('tags')
  shots: Shot[] = [];

  constructor() {
    super('tags');

    this.color = TagColor.Blue;
    this.target = 'asset';
    this.usage = 0;

    makeObservable<Tag>(this, {
      _id: observable,
      spaceId: observable,
      text: observable,
      color: observable,
      target: observable,
      usage: observable,
    });
  }

  static getOne(id: string): Tag | undefined {
    return Model.getOne(id) as Tag;
  }

  static getColorNameByHex(hex: TagColor): string {
    for (const key in TagColor) {
      if (TagColor[key as keyof typeof TagColor] === hex) {
        return key;
      }
    }
    return '';
  }

  static async create(input: SaveTagDto): Promise<Tag | null> {
    try {
      const { data } = await api.post(`/tags`, input);
      const tag = Object.assign(new Tag(), { ...data });

      entityPool.insert(tag);

      return tag;
    } catch (e) {
      catchError(e);
      return null;
    }
  }

  static groupAndSortTags = (tags: Tag[]) => {
    // group tags by target
    const groupedTags = tags.reduce(
      (acc, tag) => {
        acc[tag.target] = [...(acc[tag.target] ?? []), tag];
        return acc;
      },
      { asset: [], shot: [], task: [] } as Record<TagTarget, Tag[]>,
    );

    // sort grouped tags by text alphabetically
    for (const target in groupedTags) {
      groupedTags[target as TagTarget].sort((a: Tag, b: Tag) => a.text.localeCompare(b.text));
    }

    return groupedTags;
  };

  toPOJO(): Record<string, any> {
    return {
      _id: this._id,
      spaceId: this.spaceId,
      text: this.text,
      color: this.color,
      target: this.target,
      usage: this.usage,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}
