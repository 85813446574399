import { makeObservable, observable } from 'mobx';
import merge from 'lodash.merge';

import { Model } from '../../core/engine/model';
import { api } from '../../api';
import { ManyToMany, ManyToOne } from '../../core/engine/decorators';
import { entityPool } from '../../core/engine/engine';
import { Project, ProjectPriority } from './project';
import { catchError } from '../../core/catch-error';
import { Member } from './member';
import { Step } from './step';
import { Space } from './space';
import { Tag } from './tag';
import { taskGroupingStore } from '../../core/stores/task-store';

export enum TaskStatus {
  Todo = 'todo',
  InProgress = 'in-progress',
  OnHold = 'on-hold',
  Done = 'done',
  Canceled = 'canceled',
}

export type TaskLevel = 'space' | 'project' | 'step';

export type SaveTaskDto = {
  title: string;
  status: TaskStatus;
  priority?: ProjectPriority;
  dueDate?: string;
  description?: string;
  projectId?: string;
  stepId?: string;
  assigneeId?: string;
  tagsIds?: string[];
};

export type GetTasksDto = {
  role: ('created-by-me' | 'assigned-to-me')[];
  status: TaskStatus[];
  priority: ProjectPriority[];
  projectId: string;
  stepId: string;
};

export class Task extends Model {
  spaceId: string;

  creatorId: string;

  title: string;

  status: TaskStatus;

  priority: ProjectPriority;

  dueDate?: string;

  projectId?: string;

  stepId?: string | null;

  assigneeId?: string | null;

  description?: string;

  @ManyToOne('tasks')
  space: Space;

  @ManyToOne('tasks')
  project?: Project;

  @ManyToOne('tasks')
  step?: Step;

  @ManyToOne('tasks')
  assignee?: Member;

  tagsIds: string[] = [];

  @ManyToMany('tasks')
  tags: Tag[] = [];

  constructor() {
    super('tasks');

    this.status = TaskStatus.Todo;
    this.priority = ProjectPriority.NO_PRIORITY;

    makeObservable<Task>(this, {
      _id: observable,
      spaceId: observable,
      creatorId: observable,
      title: observable,
      status: observable,
      priority: observable,
      dueDate: observable,
      projectId: observable,
      stepId: observable,
      assigneeId: observable,
      description: observable,
      tagsIds: observable,
      tags: observable,
    });
  }

  static StatusMapper: Record<TaskStatus, { title: string }> = {
    todo: {
      title: 'Todo',
    },
    'on-hold': {
      title: 'On Hold',
    },
    'in-progress': {
      title: 'In Progress',
    },
    canceled: {
      title: 'Canceled',
    },
    done: {
      title: 'Done',
    },
  };

  async update(values: Partial<Omit<this, '_id'>>): Promise<any> {
    const currentTask = new Task();
    merge(currentTask, this);

    const updatedTask: Partial<Task> = { ...values };

    try {
      if (values.projectId) {
        const taskProject = Project.getOne(values.projectId);

        updatedTask.project = taskProject;
      }

      if (values.assigneeId) {
        const taskAssignee = Member.getOne(values.assigneeId);

        updatedTask.assignee = taskAssignee;
      }

      merge(this, updatedTask);
      await api.patch(`/tasks/${this._id}`, values);

      if (this.projectId) {
        const project = Project.getOne(this.projectId);
        if (project) project.lastUpdatedAt = new Date().toString();
      }

      this.rerenderGroupingViewIfRequired(values, currentTask);
    } catch (e) {
      merge(this, currentTask);

      catchError(e);
    }
  }

  private rerenderGroupingViewIfRequired(values: Partial<Omit<this, '_id'>>, currentTask: Task) {
    const shouldRerenderDueToAssignee =
      values.assigneeId !== undefined &&
      currentTask.assigneeId !== values.assigneeId &&
      taskGroupingStore.groupBy === 'assigneeId';

    const shouldRerenderDueToStatus =
      values.status !== undefined &&
      currentTask.status !== values.status &&
      taskGroupingStore.groupBy === 'status';

    const shouldRerenderDueToPriority =
      values.priority !== undefined &&
      currentTask.priority !== values.priority &&
      taskGroupingStore.groupBy === 'priority';

    if (shouldRerenderDueToAssignee || shouldRerenderDueToStatus || shouldRerenderDueToPriority) {
      taskGroupingStore.triggerRender();
    }
  }

  static async create(input: SaveTaskDto): Promise<Task | undefined> {
    try {
      const { data } = await api.post(`/tasks`, input);

      entityPool.insert(Object.assign(new Task(), { ...data }));

      if (data.projectId) {
        const project = Project.getOne(data.projectId);
        if (project) project.lastUpdatedAt = new Date().toString();
      }

      return data;
    } catch (e) {
      catchError(e);
    }
  }

  async delete() {
    super.delete();

    if (this.projectId) {
      const project = Project.getOne(this.projectId);
      if (project) project.lastUpdatedAt = new Date().toString();
    }
  }

  static fetchAll = async (input?: Partial<GetTasksDto>) => {
    const { role, status, priority } = input || {};

    const params = {
      ...input,
      role: role?.join(','),
      status: status?.join(','),
      priority: priority?.join(','),
    };

    const { data } = await api.get(`/tasks`, { params });

    data.forEach((task: Task) => {
      entityPool.insert(Object.assign(new Task(), task));
    });

    return data;
  };

  static fetchByProject = async (projectId: string) => {
    const { data } = await api.get(`/projects/${projectId}/tasks`);

    data.forEach((task: Task) => {
      entityPool.insert(Object.assign(new Task(), task));
    });

    return data;
  };

  static fetchBySpace = async (spaceId: string) => {
    const { data } = await api.get(`/spaces/${spaceId}/tasks`);

    data.forEach((task: Task) => {
      entityPool.insert(Object.assign(new Task(), task));
    });

    return data;
  };

  async updateTags(tagsIds: string[]) {
    const { data } = await api.patch(`/tasks/${this._id}`, { tagsIds });

    this.tagsIds = data.tagsIds;
    this.tags = data.tagsIds.map((el: string) => {
      const tag = Tag.getOne(el);
      if (tag) tag?.tasks?.push(this);
      return tag;
    });
  }

  toPOJO(): Record<string, any> {
    return {
      _id: this._id,
      spaceId: this.spaceId,
      creatorId: this.creatorId,
      projectId: this.projectId,
      status: this.status,
      dueDate: this.dueDate,
      title: this.title,
      priority: this.priority,
      stepId: this.stepId,
      assigneeId: this.assigneeId,
      description: this.description,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}
