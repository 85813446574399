import { AxiosResponse } from 'axios';
import { computed, makeObservable, observable } from 'mobx';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

import { Model } from '../../core/engine/model';
import { ManyToMany, ManyToOne, OneToMany } from '../../core/engine/decorators';
import { Project } from './project';
import { Upload } from './upload';
import { api } from '../../api';
import { entityPool } from '../../core/engine/engine';
import { uploadFile } from '../../features/assets/store/uploads.slice';
import { timeDifference } from '../../lib/utils/TimeDifference';
import { getFullName } from '../../lib/utils/get-full-name';
import { Comment } from './comment';
import { ShareLink } from './share-link';
import { Tag } from './tag';

import type { Cover } from 'core/models/cover';

const NO_EXTENSION_FILE_TYPE = ['link', 'note'];

export class Asset extends Model {
  name: string;

  @OneToMany('parent')
  children: Asset[] = [];

  get sortedChildren() {
    return this.children.slice().sort((a, b) => (a.version || 0) - (b.version || 0));
  }

  parentId: string;

  @ManyToOne('children')
  parent: Asset | null = null;

  projectId: string;

  @ManyToOne('assets')
  project: Project | null = null;

  targetId: string | null;

  @ManyToOne('assets')
  target: Model | null = null;

  @OneToMany('target')
  comments: Comment[] = [];

  type: string;

  fileSize?: number;

  size?: number;

  content?: string;

  fileType?: string;

  _extension?: string;

  commentCount: number = 0;

  // For link type assets
  url?: string;

  set extension(ext: string) {
    if (NO_EXTENSION_FILE_TYPE.includes(this.type)) {
      return;
    }

    // we might have old data from the server that doesn't have the extension
    if (ext) {
      this._extension = ext;
      return;
    }

    const splitName = this.name.split('.'); // not checking on the name is intentional to get an error to fix it
    this._extension = splitName.length > 1 ? splitName[splitName.length - 1] : undefined;

    if (this._extension) return;

    if (!this.fileType) {
      this._extension = '';
      return;
    }

    const splitFileType = this.fileType.split('/');
    this._extension = splitFileType[splitFileType.length - 1] || '';
  }

  get extension(): string | undefined {
    return this._extension;
  }

  // @deprecated
  key?: string;

  uploadStartedAt?: Date;

  originalFile: string;

  videoSnapShot?: string;

  currentVersionId?: string;

  currentVersionNumber?: number;

  version?: number;

  uploadComplete?: boolean;

  versionsCount?: number;

  cover?: Cover;
  fileName: string;
  notificationCount: number;
  thumbnail?: string;

  lastCommentedAt?: string;
  editedAt?: string;

  uploader?: {
    firstName: string;
    lastName: string;
    avatar: string;
  };

  uploaded?: boolean;

  meta?: {
    width?: number;
    height?: number;
    duration?: number;
    pages?: number;

    favIcon?: string;
    previewImage?: string;
  };

  uploaderId?: string;

  status?: string;

  tagsIds: string[] = [];

  @ManyToMany('assets')
  tags: Tag[] = [];

  description?: string;

  uploadId: string | null = null;

  @ManyToOne('assets')
  upload: Upload | null = null;

  constructor() {
    super('assets');

    this.commentCount = 0;

    makeObservable(this, {
      name: observable,
      uploaded: observable,
      upload: observable,
      children: observable,
      tagsIds: observable,
      tags: observable,
      type: observable,
      versionsCount: observable,
      currentVersionId: observable,
      uploadId: observable,
      currentVersionNumber: observable,
      comments: observable,
      commentCount: observable,
      meta: observable,
      content: observable,
      uploaderName: computed,
      relativeUploadTime: computed,
      sortedChildren: computed,
    });
  }

  toPOJO(): Record<string, any> {
    return {};
  }

  static getOne(id: string): Asset | undefined {
    return Model.getOne(id) as Asset;
  }

  get uploaderName() {
    if (!this.uploader) {
      return '';
    }

    return getFullName(this.uploader);
  }

  get relativeUploadTime() {
    return timeDifference(new Date(), this.createdAt);
  }

  addVersion = async (file: File): Promise<Asset | undefined> => {
    if (this.uploaded === false) return;

    const uploadId = uuid();
    const { data } = (await api.post(`/assets`, {
      parentId: this._id,
      name: file.name,
      size: file.size,
      fileType: file.type,
      type: 'file',
      uploadId,
    })) as {
      data: {
        originalVersion?: Asset;
        asset: Asset & { links?: { upload?: string } };
      };
    };

    const originalVersion = Object.assign(new Asset(), data.originalVersion);

    const asset = Object.assign(new Asset(), data.asset);

    if (this.type === 'file') {
      // Create stack
      this.type = 'version_stack';
    } else if (this.type === 'version_stack') {
      this.name = asset.name;
    }

    this.uploaded = false;
    this.uploadId = uploadId;
    this.currentVersionId = asset._id;
    this.currentVersionNumber = asset.version;
    this.name = asset.name;
    this.uploader = asset.uploader;
    this.size = asset.size;
    this.fileType = asset.fileType;
    if (!this.versionsCount) {
      this.versionsCount = this.children.length || 1;
    }
    this.versionsCount++;

    entityPool.insert(originalVersion);
    entityPool.insert(asset);

    /**
     * Trigger a new upload if we have a file
     */
    if (file && data.asset.links?.upload) {
      const upload = new Upload(uploadId, data.asset._id, data.asset.links.upload);
      entityPool.insert(upload);

      this.upload = upload;
      asset.upload = upload;

      await uploadFile({
        upload,
        assetId: data.asset._id,
        file,
        options: {
          parentAssetId: data.asset.parentId,
        },
      });
    }

    this.comments.forEach((comment) => {
      // remove the comment from this asset and add it to the new version
      comment.targetId = originalVersion._id;
      comment.target = originalVersion;
      originalVersion.comments = [...originalVersion.comments, comment];
      this.comments = this.comments.filter((c) => c._id !== comment._id);
    });

    this.tags.forEach((tag) => {
      originalVersion.tags = [...originalVersion.tags, tag];
      originalVersion.tagsIds = [...originalVersion.tagsIds, tag._id];
      this.tags = this.tags.filter((t) => t._id !== tag._id);
      this.tagsIds = this.tagsIds.filter((id) => id !== tag._id);
    });

    return asset;
  };

  getPlaybackLink(): Promise<string> {
    return Asset.getDownloadLinkById(this._id, false);
  }

  getBreadCrumbs(rootAssetId: string): Array<{
    label: string;
    href: string;
  }> {
    const breadCrumbs = [
      {
        label: this.name,
        href: `../${this._id}`,
      },
    ];

    let parent = this.parent;
    while (parent) {
      if (rootAssetId === parent._id) {
        break;
      }
      breadCrumbs.unshift({
        label: parent.name,
        href: `../${parent._id}`,
      });
      parent = parent.parent;
    }

    return breadCrumbs;
  }

  static getDownloadLinkById(id: string, isDirectDownload: boolean = true): Promise<string> {
    let url = `/assets/${id}/download-link?isDirectDownload=${isDirectDownload}`;

    return api.get(url).then(({ data }: AxiosResponse<string>) => data);
  }

  async updateTags(tagsIds: string[]) {
    const { data } = await api.patch(`/assets/${this._id}`, { tagsIds });

    /*
            const tag = Tag.getOne(tagRes._id);
        if (tag) tag.usage++;
     */

    this.tagsIds = data.tagsIds;
    this.tags = data.tagsIds.map((el: string) => {
      const tag = Tag.getOne(el);
      if (tag) tag?.assets?.push(this);
      return tag;
    });
  }

  async delete(): Promise<void> {
    /**
     * Stop the upload if it's uploading
     */
    if (this.upload) {
      await this.upload.cancel();
    }

    const parent = this.parent;
    if (parent && parent.type === 'version_stack') {
      let currentVersionCount = parent.versionsCount;

      parent.children = parent.children.filter((child) => child._id !== this._id);
      if (currentVersionCount) {
        currentVersionCount--;
      } else {
        currentVersionCount = parent.children.length;
      }

      if (currentVersionCount === 0) {
        return parent.delete();
      }

      // sort versions by version number
      parent.children = parent.children.sort((a, b) => (a.version || 0) - (b.version || 0));
      parent.children.forEach((child, index) => {
        child.version = index + 1;
      });
      const lastVersion = parent.children[parent.children.length - 1];
      parent.currentVersionId = lastVersion._id;
      parent.versionsCount = currentVersionCount;
      parent.currentVersionNumber = lastVersion.version;
      parent.name = lastVersion.name;
      parent.size = lastVersion.size;
      parent.cover = lastVersion.cover;
      parent.fileType = lastVersion.fileType;
      parent.uploader = lastVersion.uploader;
    }

    await super.delete();

    ShareLink.removeAsset(this._id);
  }

  async fetchComments() {
    await Comment.fetchComments(this.$name, this._id);
  }

  async addComment(input: any) {
    const assetUrl = location.href;

    if (assetUrl) {
      input.assetUrl = assetUrl;
    }

    const { data } = await api.post(`assets/${this._id}/comments`, input);

    const comment = Object.assign(new Comment(), data);
    comment.isAuthor = true;
    entityPool.insert(comment);

    this.commentCount = this.comments.length;
    this.lastCommentedAt = DateTime.now().toISO()?.toString();

    return comment?._id;
  }

  finishedUploading = async () => {
    (this as Asset).update({ uploaded: true });
    this.uploaded = true;
    this.uploadId = null;
    this.upload = null;

    if (this.type === 'version_stack' && this.children.length > 0) {
      const uploadingVersion = this.children.find((child) => child._id === this.currentVersionId);
      if (uploadingVersion) {
        uploadingVersion.uploaded = true;
        uploadingVersion.uploadId = null;
        uploadingVersion.upload = null;
      }
    } else if (this.type === 'file' && !!this.version) {
      const versionStack = this.parent;
      if (versionStack) {
        versionStack.uploaded = true;
        versionStack.uploadId = null;
        versionStack.upload = null;
      }
    }
  };

  static async deleteMany(ids: string[]) {
    await api.post('/assets/batch-delete', { assetIds: ids });
    for (const id of ids) {
      entityPool.getEntity(id)?.remove();
    }
  }
}
