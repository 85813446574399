import React from 'react';
import { observer } from 'mobx-react-lite';

import { CollapsibleItem } from '../../../../components/collapsible-item/collapsible-item';
import { getRenderedTime } from '../../../../lib/utils/Convertor';
import { getFullName } from '../../../../lib/utils/get-full-name';
import { MemberItemDropdown } from '../../../../features/teams/components/member-item-dropdown';
import {
  Header,
  Title,
  TableContainer,
  RowHeader,
  RowTitle,
  RowSubtitle,
  RowContainer,
  HorizontalCell,
  CellLabel,
  CellContent,
} from '../../../../features/shootingdays/components/styled-mobile-table';

import type { Step } from '../../../../app/entities/step';
import type { TeamContact } from '../../../../features/teams/models/team';
import type { MemberListProps } from './members-list';

type MemberTableProps = Omit<
  MemberListProps,
  'meta' | 'contacts' | 'departments' | 'onUpdate' | 'protectedTeamName' | 'onMetaUpdate'
> & {
  contacts?: (TeamContact & Exclude<Step['contactsMetaData'], undefined>[number])[];
  getDepartmentName: (departmentId: string) => string | undefined;
  showDepartment?: boolean;
  showCallTime?: boolean;
};

export const MobileMembersTable: React.FC<MemberTableProps> = observer(
  ({
    title,
    actions,
    contacts,
    onDelete,
    onToggleHide,
    defaultCallTime,
    getDepartmentName,
    placeholder,
    showCallTime,
    showDepartment,
  }) => {
    const getSubtitle = (role?: string, time?: string) => {
      if (time && role) {
        return `${time} · ${role}`;
      } else if (time) {
        return `${time}`;
      } else if (role) {
        return `${role}`;
      } else {
        return '';
      }
    };

    return (
      <div>
        <Header>
          <Title>{title}</Title>

          {actions}
        </Header>

        <TableContainer>
          {placeholder
            ? placeholder
            : contacts?.map((contact) => {
                const department = getDepartmentName(contact.departmentId || '');
                const renderedTime =
                  !contact.isHidden && getRenderedTime(contact.callTime || defaultCallTime);
                const subtitle = !!contact._id
                  ? getSubtitle(contact.role, renderedTime || '')
                  : `${contact.role || '-'}`;

                return (
                  <CollapsibleItem
                    key={contact._id}
                    title={
                      <RowHeader data-hidden={contact.isHidden}>
                        <RowTitle>{getFullName(contact)}</RowTitle>

                        {subtitle && <RowSubtitle>{subtitle}</RowSubtitle>}
                      </RowHeader>
                    }
                    isTransparent
                  >
                    <RowContainer data-hidden={contact.isHidden}>
                      {showDepartment && (
                        <HorizontalCell>
                          <CellLabel>Department</CellLabel>

                          <CellContent>{department}</CellContent>
                        </HorizontalCell>
                      )}

                      <HorizontalCell>
                        <CellLabel>Email</CellLabel>

                        {contact.email && <CellContent>{contact.email}</CellContent>}
                      </HorizontalCell>

                      <HorizontalCell>
                        <CellLabel>Phone</CellLabel>

                        {contact.phone && (
                          <CellContent>
                            {typeof contact.phone === 'string'
                              ? contact.phone
                              : contact.phone.number}
                          </CellContent>
                        )}
                      </HorizontalCell>

                      {showCallTime && !contact.isHidden && (
                        <HorizontalCell>
                          <CellLabel>Call Time</CellLabel>

                          {renderedTime && (
                            <CellContent>{!!contact._id ? renderedTime : '-'}</CellContent>
                          )}
                        </HorizontalCell>
                      )}

                      {(onDelete || onToggleHide) && (
                        <HorizontalCell>
                          <CellLabel>Actions</CellLabel>

                          <MemberItemDropdown
                            {...(onDelete && { onDelete: () => onDelete(contact._id) })}
                            {...(onToggleHide && { onToggleHide: () => onToggleHide(contact._id) })}
                            isHidden={contact.isHidden}
                            isCast={!!contact._id}
                          />
                        </HorizontalCell>
                      )}
                    </RowContainer>
                  </CollapsibleItem>
                );
              })}
        </TableContainer>
      </div>
    );
  },
);
