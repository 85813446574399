import React from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';

import { ScreenHeader } from '../../projects/components/screen-header';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { openModal } from 'core/modal/open-modal';
import { RenameStepModal } from '../../process/components/rename-step.modal';

import type { Step } from '../../../app/entities/step';

type AssetFilesHeaderProps = {
  step: Step;
  fileIds: string[];
};

export const AssetFilesHeader: React.FC<AssetFilesHeaderProps> = observer(({ step, fileIds }) => {
  const navigate = useNavigate();

  const handleDeleteStep = async () => {
    await step.delete();
    const href = `../..`;
    navigate(href);
  };

  const items: React.ComponentProps<typeof DropdownMenu>['items'] = [
    {
      title: 'Rename step',
      icon: <Pencil1Icon />,
      onSelect: () => openModal(RenameStepModal, { step }),
    },
    {
      title: 'Delete step?',
      icon: <TrashIcon />,
      type: 'danger' as const,
      onSelect: () =>
        openModal(DeleteDialog, {
          title: 'Delete step',
          text: `Are you sure you want to delete this step?${
            step.tasks?.length ? ' All linked tasks will be gone as well.' : ''
          }`,
          onSubmit: handleDeleteStep,
        }),
    },
  ];

  return (
    <ScreenHeader
      stepId={step._id}
      title={step.name}
      dueDate={step.dueDate}
      startDate={step.startDate}
      handleUpdateTimeFrame={(values) => step.update(values)}
      menu={<DropdownMenu items={items} size={'large'} />}
    />
  );
});
