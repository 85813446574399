import React from 'react';
import styled from 'styled-components';
import { PlusIcon } from '@radix-ui/react-icons';

import { breakPoint } from '../../app/theme';

type FloatingActionButtonProps = {
  onClick?: () => void;
};

export const FloatingActionButton: React.FC<FloatingActionButtonProps> = ({ onClick }) => (
  <Button onClick={onClick}>
    <PlusIcon />
  </Button>
);

const Button = styled.button`
  position: fixed;
  bottom: 2.4rem;
  right: 2.4rem;
  width: 4.2rem;
  height: 4.2rem;
  border-radius: var(--border-radius-medium);
  background-color: var(--color-secondary);
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  border: none;
  outline: none;
  color: white;
  z-index: 1000;

  &:active {
    background-color: var(--color-shades-science);
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }
`;
