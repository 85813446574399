import styled from 'styled-components';

export const HeaderStrip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.8rem 1.6rem;
  gap: 0.8rem;
  background: var(--color-surfaces-bg-elevation-1);
  border: none;
`;

export const Strip = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.8rem 1.6rem;
  gap: 0.8rem;

  &:not(:last-child) {
    border-bottom: 0.1rem solid var(--color-surfaces-bg-elevation-2);
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div:last-child {
    margin-inline-end: 0.4rem;
    margin-inline-start: 0.8rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  flex: 0 0 auto;

  &[data-full-compact='true'] {
    gap: 0.4rem;
  }
`;

export const CustomAvatar = styled.div`
  display: flex;
  min-width: 2.4rem;
  min-height: 2.4rem;
  max-width: 2.4rem;
  max-height: 2.4rem;
  justify-content: center;
  align-items: center;
  border: 1px var(--color-texts-low-contrast) dashed;
  border-radius: 0.4rem;
  padding: 0.4rem;
  color: var(--color-texts-medium-contrast);
`;

export const TaskTrigger = styled.div`
  display: flex;
  min-width: 0;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  border-radius: 0.4rem;
  gap: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;

  font-size: 1.4rem;
  font-weight: 400;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &[data-icon-only='true'] {
    padding: 0;
    min-width: 2.4rem;
    height: 2.4rem;
    align-items: center;
    justify-content: center;
    border: none;
  }

  &[data-variant='minimal'] {
    height: 2.4rem;
    padding: 0.6rem 0.8rem;
    background: var(--color-surfaces-bg-elevation-2);
    border-color: transparent;

    &[data-placeholder='true'] {
      border: 0.1rem dashed var(--color-texts-low-contrast);
    }
  }

  &[data-placeholder='true'] {
    padding: 0.6rem 0.8rem;
    flex-direction: row;
    color: var(--color-texts-low-contrast);
    background: transparent;
  }

  &:hover,
  &:focus-visible {
    border: none;
    background: var(--color-surfaces-bg-elevation-3);
    cursor: pointer;

    &[data-variant='minimal'] {
      border-color: transparent;

      &[data-placeholder='true'] {
        border: 0.1rem dashed var(--color-texts-middle-contrast);
      }
    }

    &[data-placeholder='true'] {
      background: var(--color-surfaces-bg-elevation-3);
    }

    &[data-disabled='true'] {
      cursor: default;
      pointer-events: none;
      border-color: var(--color-surfaces-bg-elevation-2);
      background: transparent;

      & > span,
      & > svg {
        color: var(--color-texts-low-contrast);
      }
    }
  }

  &[data-disabled='true'] {
    cursor: default;
    pointer-events: none;

    > div > span {
      color: var(--color-grayscale-shuttle);
    }

    &:hover,
    &:focus-visible {
      background-color: transparent !important;
    }
  }
`;

export const InfoTextLine = styled.span`
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-texts-high-contrast);
`;

export const PlaceholderText = styled.span`
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-texts-low-contrast);
`;

export const FilterTrigger = styled(TaskTrigger)`
  user-select: none;
  height: 2.4rem;
  background-color: var(--color-surfaces-bg-elevation-2);
  color: var(--color-texts-high-contrast);
`;

export const FilterTitle = styled.span`
  font-weight: 400;
  font-size: 1.2rem;
`;

export const LowContrastText = styled.span`
  color: var(--color-texts-low-contrast);
`;
