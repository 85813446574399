import { makeObservable } from 'mobx';

import { BaseFilters } from './base-filter';
import { ProjectPriority } from '../project';
import { TaskStatus } from '../task';

export class SpaceTasksFilter extends BaseFilters {
  projectIds: (string | null | undefined)[];
  assigneeIds: (string | null | undefined)[];
  status: TaskStatus[];
  priority: ProjectPriority[];
  tagIds: (string | null | undefined)[];

  tokensMapper = {
    projectIds: 'project',
    status: 'status',
    assigneeIds: 'assignee',
    priority: 'priority',
    tagIds: 'tag',
  };

  propsMapper = {
    status: 'status',
    priority: 'priority',
    project: 'projectIds',
    assignee: 'assigneeIds',
    tag: 'tagIds',
  };

  constructor() {
    super('tasksFilter');

    this.projectIds = [];
    this.assigneeIds = [];
    this.status = [];
    this.priority = [];
    this.tagIds = [];

    this.orderedTokens = [];
    this.filtersCount = 0;

    makeObservable(this, {
      projectIds: true,
      assigneeIds: true,
      status: true,
      priority: true,
      tagIds: true,
      orderedTokens: true,
      filtersCount: true,
    });
  }
}
