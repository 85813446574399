import React, { useState, useEffect } from 'react';
import { PlusIcon, CheckIcon } from '@radix-ui/react-icons';

import { ContentMenuProps } from './content-menu';
import { OptionsContainer, CheckboxItem, OptionLabel, ListItem } from './styled-content-menu';

export type ContentProps = {
  searchValue?: string;
  items: ContentMenuProps['items'];
  onCreate?: (event?: Event) => void;
};

export const DefaultContentMenu: React.FC<ContentProps> = ({ onCreate, searchValue, items }) => {
  const [options, setOptions] = useState<typeof items>();

  useEffect(() => {
    if (!searchValue) {
      return setOptions(items);
    }

    if (items) {
      const filteredItems = items.filter((item) =>
        item.title?.toString().toLowerCase().includes(searchValue.toLowerCase()),
      );

      setOptions(filteredItems);
    }
  }, [items, searchValue]);

  const shouldDisplayCreate =
    onCreate && searchValue && (options?.length !== 1 || options[0].title !== searchValue);

  return (
    <OptionsContainer>
      {options?.map((item, index) => (
        <CheckboxItem
          key={`item-${item.title}-${index}`}
          checked={item.isChecked}
          onSelect={item.onSelect}
          onCheckedChange={(isChecked) => {
            item.onCheckedChange?.(isChecked);
          }}
        >
          <OptionLabel>
            {item.icon}

            <span>{item.title}</span>
          </OptionLabel>

          {!!item.isChecked && <CheckIcon />}
        </CheckboxItem>
      ))}

      {shouldDisplayCreate && (
        <ListItem onSelect={onCreate}>
          <OptionLabel>
            <PlusIcon />

            <span>Create &quot;{searchValue}&quot;</span>
          </OptionLabel>
        </ListItem>
      )}
    </OptionsContainer>
  );
};
