import React, { useState } from 'react';
import styled from 'styled-components';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { KeyboardKeys } from '../../../assets/enums/keyboard-keys.enum';

import type { SelectDropdownProps } from '..';
import { DefaultContentMenu } from './default-content-menu';
import { GroupContentMenu } from './group-content-menu';

export type ContentMenuProps = {
  items: SelectDropdownProps['items'];
  groups: SelectDropdownProps['groups'];
  onCreate?: SelectDropdownProps['onCreate'];
  placeholder?: string;
  align?: React.ComponentProps<typeof DropdownMenu.Content>['align'];
  side?: React.ComponentProps<typeof DropdownMenu.Content>['side'];
};

export const ContentMenu: React.FC<ContentMenuProps> = ({
  items,
  groups,
  onCreate,
  placeholder,
  align = 'start',
  side = 'bottom',
}) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const handleCreate = async (event?: Event) => {
    await onCreate?.(searchValue, event);
    setSearchValue('');
  };

  return (
    <Container sideOffset={4} align={align} side={side}>
      <SearchInput
        placeholder={placeholder || 'Search or create'}
        tabIndex={0}
        value={searchValue}
        onKeyDown={(e) => {
          if (e.key !== KeyboardKeys.Escape) {
            e.stopPropagation();
          }
          if (e.key == KeyboardKeys.Enter) {
            handleCreate();
          }
        }}
        onChange={(e) => {
          const value = e.currentTarget.value;
          setSearchValue(value);
        }}
        autoFocus
      />

      <Separator />

      {items ? (
        <DefaultContentMenu
          items={items}
          searchValue={searchValue}
          {...(onCreate && { onCreate: handleCreate })}
        />
      ) : (
        <GroupContentMenu items={groups} searchValue={searchValue} />
      )}
    </Container>
  );
};

const Container = styled(DropdownMenu.Content)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.4rem 0;
  width: 100%;
  max-width: 32rem;
  min-width: 32rem;
  overflow-y: auto;
  max-height: 32rem;
  border-radius: 0.4rem;
  background-color: rgba(29, 30, 43, 0.498);
  box-shadow: 0px 4px 8px -3px #0000001a;
  backdrop-filter: blur(10px) saturate(190%) contrast(70%) brightness(80%);
  transition: all 100ms ease-in-out;
  z-index: 1000000;

  &[data-size='free-size'] {
    min-width: unset;
  }
`;

const SearchInput = styled.input`
  background-color: transparent;
  color: var(--color-grayscale-white);
  border: none;
  padding: 0.8rem 1.2rem 0;
  width: 100%;
  height: 3.2rem;
  font-weight: 400;
  font-size: 1.4rem;
  min-width: 0;
`;

const Separator = styled(DropdownMenu.Separator)`
  width: 100%;
  margin: 0.8rem 0 0.4rem;
  border-bottom: 0.1rem solid var(--color-surfaces-bg-elevation-3);
`;
