import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { SingleSelectDropdown } from '../../../components/select-dropdown';
import { MovieIcon } from '../../../components/icons/MovieIcon';
import { useCurrentSpace } from 'hooks/use-current-space';
import { TaskTrigger } from './styled-tasks-components';

type TaskProjectSelectorProps = {
  onChange: (value: string) => void;
  selectedProjectId: string | undefined;
  triggerVariant?: 'default' | 'minimal';
  isViewOnly?: boolean;
};

export const TaskProjectSelector: React.FC<TaskProjectSelectorProps> = observer(
  ({ onChange, selectedProjectId, isViewOnly = false, triggerVariant = 'default' }) => {
    const space = useCurrentSpace();

    const options: React.ComponentProps<typeof SingleSelectDropdown>['options'] =
      space?.projects?.map((project) => ({
        label: project.name,
        value: project._id,
      })) || [];

    const handleProjectChange = (value?: string) => {
      // @ts-ignore
      onChange(value || null);
    };

    return (
      <Container>
        <SingleSelectDropdown
          trigger={(label) => (
            <TaskTrigger
              data-disabled={isViewOnly}
              data-placeholder={!label}
              tabIndex={0}
              data-variant={triggerVariant}
            >
              {label ? (
                <ProjectLabel>
                  {triggerVariant !== 'minimal' && (
                    <MovieIcon fill={isViewOnly ? 'var(--color-grayscale-shuttle)' : undefined} />
                  )}

                  <InfoTextLine tabIndex={0}>{label}</InfoTextLine>
                </ProjectLabel>
              ) : (
                <>
                  <MovieIcon />

                  <span>Select project</span>
                </>
              )}
            </TaskTrigger>
          )}
          title="Project"
          value={selectedProjectId}
          placeholder="Search"
          options={options}
          onValueChange={handleProjectChange}
          disabled={isViewOnly}
          align={triggerVariant === 'minimal' ? 'end' : 'start'}
        />
      </Container>
    );
  },
);

const Container = styled.div`
  width: fit-content;
  overflow: hidden;
`;

const InfoTextLine = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-texts-high-contrast);
`;

const ProjectLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;
