import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { PersonIcon } from '@radix-ui/react-icons';
import { Avatar } from '@producer-io/ui-kit';

import { Member } from '../../app/entities/member';
import { Project } from '../../app/entities/project';
import { SingleSelectDropdown } from '../select-dropdown';
import { useCurrentSpace } from '../../hooks/use-current-space';

type AssigneeSelectorProps = {
  project?: Project;
  onChange: (value?: string) => void;
  selectedMemberId?: string | undefined | null;
  iconOnly?: boolean;
  isViewOnly?: boolean;
};

export const AssigneeSelector: React.FC<AssigneeSelectorProps> = observer(
  ({ project, onChange, selectedMemberId, iconOnly, isViewOnly }) => {
    const space = useCurrentSpace();

    const otherMembers = useMemo(
      () =>
        space.members
          .filter((member) => !project?.collaborators.includes(member))
          .sort((a, b) => a.fullName.localeCompare(b.fullName)),
      [space.members, project?.collaborators],
    );

    const memberOptions: React.ComponentProps<typeof SingleSelectDropdown>['options'] =
      project?.collaborators.map((el) => ({
        label: el.fullName,
        value: el._id,
        icon: <Avatar size="small" initials={el.initials} src={el.avatar} />,
      })) || [];

    const spaceMemberOptions: React.ComponentProps<typeof SingleSelectDropdown>['options'] =
      otherMembers?.map((el) => ({
        label: el.fullName,
        value: el._id,
        icon: <Avatar size="small" initials={el.initials} src={el.avatar} />,
      })) || [];

    const options: React.ComponentProps<typeof SingleSelectDropdown>['options'] = [
      {
        label: 'No assignee',
        value: '',
        icon: (
          <Placeholder>
            <PersonIcon />
          </Placeholder>
        ),
      },
      ...memberOptions,
    ];

    const groups: React.ComponentProps<typeof SingleSelectDropdown>['groups'] = [
      {
        items: options,
      },
      ...(spaceMemberOptions?.length
        ? [
            {
              title: 'People not yet in project',
              items: spaceMemberOptions,
            },
          ]
        : []),
    ];

    const handleStepChange = (value?: string) => {
      // @ts-ignore
      onChange(value || null); // to allow unassign assignee
    };

    const assignee = selectedMemberId && (Member.getOne(selectedMemberId) as Member);

    const triggerContent = (label?: string) => {
      if (iconOnly) {
        return assignee ? (
          <CustomAvatar>
            <Avatar size="small" initials={assignee.initials} src={assignee.avatar} />
          </CustomAvatar>
        ) : (
          <Placeholder data-is-not-option>
            <PersonIcon />
          </Placeholder>
        );
      }

      return assignee ? (
        <StepLabel>
          <CustomAvatar>
            <Avatar size="small" initials={assignee.initials} src={assignee.avatar} />
          </CustomAvatar>

          <InfoTextLine tabIndex={0}>{label}</InfoTextLine>
        </StepLabel>
      ) : (
        <>
          <Placeholder data-for-modal={!iconOnly} data-is-not-option>
            <PersonIcon />
          </Placeholder>

          {!iconOnly && <span>No assignee</span>}
        </>
      );
    };

    return (
      <Container data-icon-only={iconOnly} data-disabled={isViewOnly}>
        <SingleSelectDropdown
          trigger={(label) => (
            <Trigger
              data-icon-only={iconOnly}
              data-placeholder={!label}
              data-disabled={isViewOnly}
              tabIndex={0}
            >
              {triggerContent(label)}
            </Trigger>
          )}
          title="Assignee"
          placeholder={'Assign to...'}
          value={selectedMemberId || ''}
          groups={groups}
          onValueChange={handleStepChange}
          disabled={isViewOnly}
        />
      </Container>
    );
  },
);

const Container = styled.div`
  min-height: 3.2rem;
  min-width: 8rem;
  width: fit-content;
  overflow: hidden;
  border-radius: 0.4rem;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  &[data-icon-only='true'] {
    min-width: 0;
    max-width: 2.4rem;
    max-height: 2.4rem;
    min-height: 2.4rem;
    border: none;
  }

  &[data-disabled='true'] {
    cursor: default;
    pointer-events: none;
  }
`;

const Trigger = styled.div`
  display: flex;
  min-width: 0;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  border-radius: 0.4rem;
  gap: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;

  font-size: 1.4rem;
  font-weight: 400;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &[data-icon-only='true'] {
    padding: 0;
    min-width: 2.4rem;
    height: 2.4rem;
    align-items: center;
    justify-content: center;
    border: none;
  }

  &[data-variant='minimal'] {
    height: 2.4rem;
    padding: 0.6rem 0.8rem;
    background: var(--color-surfaces-bg-elevation-2);
    border-color: transparent;

    &[data-placeholder='true'] {
      border: 0.1rem dashed var(--color-texts-low-contrast);
    }
  }

  &[data-placeholder='true'] {
    padding: 0.6rem 0.8rem;
    flex-direction: row;
    color: var(--color-texts-low-contrast);
    background: transparent;
  }

  &:hover,
  &:focus-visible {
    border: none;
    cursor: pointer;

    &[data-variant='minimal'] {
      border-color: transparent;

      &[data-placeholder='true'] {
        border: 0.1rem dashed var(--color-texts-middle-contrast);
      }
    }

    &[data-placeholder='true'] {
      background: var(--color-surfaces-bg-elevation-3);
    }

    &[data-disabled='true'] {
      cursor: default;
      pointer-events: none;
      border-color: var(--color-surfaces-bg-elevation-2);
      background: transparent;

      & > span,
      & > svg {
        color: var(--color-texts-low-contrast);
      }
    }
  }

  &[data-disabled='true'] {
    cursor: default;
    pointer-events: none;

    > div > span {
      color: var(--color-grayscale-shuttle);
    }

    &:hover,
    &:focus-visible {
      background-color: transparent !important;
    }
  }
`;

const CustomAvatar = styled.div`
  display: flex;
  min-width: 2.4rem;
  min-height: 2.4rem;
  max-width: 2.4rem;
  max-height: 2.4rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  padding: 0.4rem;
  color: var(--color-texts-medium-contrast);

  > span > img,
  > span > span {
    border: 1px solid transparent;
    border-radius: 0.4rem;

    &:hover,
    &:focus-visible {
      border: 1px var(--color-grayscale-ghost) solid;
      border-radius: 0.4rem;
    }
  }

  > span > img {
    background: transparent;
  }

  > span > span {
    background: var(--color-grayscale-trout);
  }
`;

const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4rem;
  width: 2.4rem;
  min-width: 2.4rem;
  border-radius: var(--border-radius-small);
  border: 1px var(--color-grayscale-light-slate) dashed;
  color: var(--color-grayscale-light-slate);
  background-color: transparent;

  &[data-is-not-option='true'] {
    &:hover {
      cursor: pointer;
      color: var(--color-texts-high-contrast);
      border-color: var(--color-texts-high-contrast);
    }
  }

  &[data-for-modal='true'] {
    min-width: 0;
    width: unset;
    height: unset;
    border: unset;

    &:hover {
      color: var(--color-texts-low-contrast);
    }
  }
`;

const InfoTextLine = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-texts-high-contrast);
`;

const StepLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;
