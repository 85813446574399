import React, { useEffect, useRef } from 'react';

import { SelectDropdown } from './select-dropdown';

import type { MultiSelectDropdownProps } from '.';

export const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  title,
  options,
  selectedOptions,
  onValueChange,
  onOpenChange,
  trigger,
  onCreate,
  side,
  align,
}) => {
  const [items, setItems] = React.useState<React.ComponentProps<typeof SelectDropdown>['items']>(
    [],
  );
  const labels = useRef<Record<string, string>>({});

  useEffect(() => {
    const mappedIOptions: React.ComponentProps<typeof SelectDropdown>['items'] = options?.map(
      (option) => {
        labels.current[option.value] = option.label;

        return {
          title: option.label,
          icon: option.icon,
          onSelect: option.onSelect,
          isChecked: selectedOptions?.some((value) => value === option.value),
          onCheckedChange: (isChecked) => {
            onValueChange(option.value, isChecked);
          },
        };
      },
    );

    setItems(mappedIOptions);
  }, [onValueChange, options, selectedOptions]);

  const selectedItemsLabel = selectedOptions
    ?.map((value) => labels.current?.[value] || '')
    .join(', ');

  return (
    <SelectDropdown
      side={side}
      align={align}
      title={title}
      selectedItemsLabel={selectedItemsLabel}
      items={items}
      onOpenChange={onOpenChange}
      onCreate={onCreate}
      trigger={trigger}
      isMulti
    />
  );
};
