import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { InlineTimeSelector } from './InlineTimeSelector';
import { minutesFormatter } from '../../../lib/time-formatters';
import { Step } from '../../../app/entities/step';
import { Stripboard } from '../../../app/entities/stripboard';
import { formatDate } from '../../../lib/utils/DateHelper';
import { dateShort } from '../../../assets/contants/dates-fns';
import { BaseRow, Cell, BaseBlock, RowMirror } from './styled-strips';

import { DayBreakStrip } from '../models/types';
import { DayStartStripCore } from './day-start-strip';
import { useCurrentStep } from '../../../hooks/use-current-step';

export type DayBreakStripProps = {
  stripboard: Stripboard;
  isShotList?: boolean;
  strip: DayBreakStrip;
};

export const DayBreakStripComponent = observer(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLTableRowElement, DayBreakStripProps>(
    ({ isShotList, stripboard, strip, ...draggableProps }, ref) => {
      const { dod, stepId, endTime, totalEstimatedTime, totalShotsDuration } = strip.data || {};
      const step = useCurrentStep();

      const shootingDay = Step.getOne(stepId)!;
      const totalShootingDays = stripboard.totalShootingDays;

      if (!shootingDay) return null;

      const formattedDate = shootingDay?.dueDate ? formatDate(shootingDay.dueDate, dateShort) : '';

      const nextShootingDayStrip =
        totalShootingDays > 0 &&
        dod !== undefined &&
        dod < totalShootingDays &&
        strip.position !== undefined
          ? stripboard.getNextShootingDay(strip._id)
          : undefined;

      const nextShootingDay =
        step?.type !== 'shootingDay' && nextShootingDayStrip?.data
          ? Step.getOne(nextShootingDayStrip.data.stepId)
          : undefined;

      return (
        <>
          <Row ref={ref} data-has-start={!!nextShootingDayStrip} {...draggableProps}>
            <Cell
              colSpan={100}
              style={{
                padding: 0,
              }}
            >
              <RowMirror data-shotlist={isShotList}>
                <EndOfDay>
                  End of day{' '}
                  {totalShootingDays && (
                    <>
                      {dod} of {totalShootingDays.toString()}
                    </>
                  )}
                  <DateWrapper>{formattedDate}</DateWrapper>
                </EndOfDay>

                <CellMirror>
                  <BlockWrapper>{!isShotList && <span>Total</span>}</BlockWrapper>
                </CellMirror>

                {isShotList && (
                  <>
                    <CellMirror>Total</CellMirror>

                    <CellMirror>{minutesFormatter(totalShotsDuration)}</CellMirror>

                    <CellMirror />
                  </>
                )}

                {!isShotList && (
                  <>
                    <CellMirror>{minutesFormatter(totalEstimatedTime)}</CellMirror>

                    <CellMirror>
                      <InlineTimeSelector time={endTime || { hour: 7, minute: 0 }} disabled />
                    </CellMirror>
                  </>
                )}
              </RowMirror>

              {nextShootingDayStrip && nextShootingDay && (
                <DayStartStripCore
                  stripboard={stripboard}
                  strip={nextShootingDayStrip}
                  startDate={nextShootingDay.dueDate}
                  isShotList={isShotList}
                  shootingDay={nextShootingDay}
                />
              )}
            </Cell>
          </Row>
        </>
      );
    },
  ),
);

const Row = styled(BaseRow)`
  height: 10.4rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  cursor: default !important;

  &[data-has-start='false'] {
    height: 5.2rem;
  }
`;

const EndOfDay = styled(BaseBlock)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 400;
  font-size: 1.4rem;
  padding: 0.8rem;
`;

const BlockWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 1.4rem;
`;

const CellMirror = styled.div`
  padding-inline-end: 1.6rem;

  &:nth-child(2) {
    grid-column-start: 6;
  }

  &:last-child {
    justify-self: end;
    width: -webkit-fill-available;
  }
`;

const DateWrapper = styled.span`
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  margin-inline-start: calc(100% + 1.2rem);
  position: absolute;
  white-space: nowrap;
  color: var(--color-grayscale-ghost);
`;
