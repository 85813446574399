import styled, { css } from 'styled-components';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

export const OptionsContainer = styled.div`
  padding: 0.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

const baseMenuItemStyles = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.2rem;
  padding: 0.8rem;
  font-size: 1.2rem;
  font-weight: 400;
  height: 3.2rem;
  min-height: 3.2rem;
  min-width: 0;
  width: 100%;
  color: var(--color-texts-high-contrast);
  overflow: hidden;
  gap: 0.8rem;

  > svg:last-child {
    width: 1.6rem;
    height: 1.6rem;
  }

  &[data-highlighted] {
    cursor: pointer;
    background-color: rgba(124, 124, 163, 0.125);
  }

  &[data-disabled] {
    color: var(--color-grey-7);
    pointer-events: none;
  }

  &[data-type='danger'] {
    color: var(--color-error);
  }
`;

export const CheckboxItem = styled(DropdownMenu.CheckboxItem)`
  ${baseMenuItemStyles}
`;

export const ListItem = styled(DropdownMenu.Item)`
  ${baseMenuItemStyles}
`;

export const SubTrigger = styled(DropdownMenu.SubTrigger)`
  ${baseMenuItemStyles}
`;

export const OptionLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 400;
  gap: 0.8rem;
  color: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
