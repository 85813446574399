import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { PlusIcon } from '@radix-ui/react-icons';
import upperFirst from 'lodash/upperFirst';

import { TagItem } from './tag-item';
import { Tag, TagTarget } from '../../../app/entities/tag';
import { Left, Strip, Wrapper } from './styled-tags-components';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { IconButton } from '../../../components/buttons';
import { CreateTagModal } from './create-tag.modal';

type TagCollectionProps = {
  target: TagTarget;
  tags: Tag[];
  defaultValue: object;
};

export const TagCollection: React.FC<TagCollectionProps> = observer(({ target, tags }) => {
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

  const getTargetTitle = (target: TagTarget) => `${upperFirst(target)} tags`;

  return (
    <>
      <Strip data-header="true">
        <Left>
          <StatusTitle>{getTargetTitle(target)}</StatusTitle>

          <Count>{tags.length}</Count>
        </Left>

        <Tooltip text="Create new tag">
          <Wrapper>
            <IconButton
              variant="x-dark"
              icon={<PlusIcon />}
              onClick={() => setCreateModalIsOpen(true)}
            />
          </Wrapper>
        </Tooltip>
      </Strip>

      {tags.map((tag) => (
        <TagItem key={tag._id} tag={tag} />
      ))}

      {createModalIsOpen && (
        <CreateTagModal onCancel={() => setCreateModalIsOpen(false)} target={target} />
      )}
    </>
  );
});

const StatusTitle = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-texts-high-contrast);
  user-select: none;
  margin-inline-end: 0.8rem;
`;

const Count = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-texts-low-contrast);
  user-select: none;
`;
