import React from 'react';
import styled from 'styled-components';
import { Avatar } from '@producer-io/ui-kit';

import { Tooltip } from '../tooltip/Tooltip';
import { breakPoint } from '../../app/theme';
import { Member } from '../../app/entities/member';

type CollaboratorAvatarProps = {
  collaborator: Member;
  onClick?: () => void;
  tooltip?: boolean;
  theme?: 'light' | 'dark';
  collapsible?: boolean;
};

export const CollaboratorAvatar: React.FC<CollaboratorAvatarProps> = ({
  collaborator,
  tooltip,
  onClick,
  theme = 'light',
  collapsible,
}) => {
  const { avatar, fullName, initials } = collaborator;

  return (
    <Tooltip text={fullName} position="bottom" disabled={!tooltip}>
      <Container onClick={onClick} data-collapsible={collapsible}>
        <Avatar src={avatar} initials={initials} theme={theme} />
      </Container>
    </Tooltip>
  );
};

const Container = styled.div`
  z-index: 999;
  height: 3.2rem;
  width: 3.2rem;

  > div {
    box-sizing: border-box;
    height: 100%;

    > span {
      border: 0.1rem solid #bf0160;
    }
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    margin: 0;

    > div {
      > span {
        border: none;
      }
    }
  }

  &:hover {
    z-index: 99999;
    transform: scale(1.05);
    transition: all 0.1s ease-in;
    cursor: pointer;
  }
`;
