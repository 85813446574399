import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import * as Dialog from '@radix-ui/react-dialog';

import { AddCover } from '../../../../components/add-file/add-cover';
import { ProjectTemplateItem, ProjectTemplateType } from './project-template-item';
import { formatPicture } from '../../../../core/services/image.service';
import { ModalHeader } from '../../../../components/form-builder-modal/modal-header';
import { HasServerResponse } from '../../../../components/PanelContainer';
import { Alert } from '../../../../components/alert/Alert';
import { TextInput } from '../../../../components/text-input/TextInput';
import { SelectionProvider } from '../../../../core/contexts/selection.context';
import { projectTemplates } from './desktop-create-project.modal';
import { PageLoader } from '../../../../components/page-loader/PageLoader';

interface MobileCreateProjectModalProps {
  spaceId: string;
  isOpen: boolean;
  onSubmit: (values: any) => Promise<void>;
  onCancel: () => void;
}

export const MobileCreateProjectModal: React.FC<MobileCreateProjectModalProps> = ({
  isOpen,
  onSubmit,
  onCancel,
  spaceId,
}) => {
  const { t } = useTranslation('createProject');
  const [file, setFile] = useState<File | null>();
  const [projectTemplate, setProjectTemplate] = useState(ProjectTemplateType.Commercial);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<any>();
  const [fileError, setFileError] = useState(null);

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .transform((value) => value.trim())
          .matches(/^(.*)?\S+(.*)?$/, t('common:fieldRequired'))
          .required(t('common:fieldRequired'))
          .max(32, t('common:fieldMax', { max: 32 })),
        client: yup.string().max(32, t('common:fieldMax', { max: 32 })),
      }),
    [t],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      client: '',
      template: projectTemplate,
    },
  });

  const watchedName = watch('name');

  useEffect(() => {
    setValue('template', projectTemplate);

    const defaultDemoName = 'Breitling - History (Demo)';
    if (projectTemplate === ProjectTemplateType.Corporate) {
      if (!watchedName) {
        setValue('name', defaultDemoName);
      }
    } else {
      if (watchedName === defaultDemoName) {
        setValue('name', '');
      }
    }
  }, [projectTemplate]);

  const handleOpenChange = (value: boolean) => {
    if (!value) {
      onCancel();
    }
  };

  const onHandleSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }

    setFileError(null);
    setIsLoading(true);

    let frame;
    if (file) {
      const options = { width: 640, height: 380 };
      frame = await formatPicture(file, options);
    }

    try {
      await onSubmit({
        spaceId,
        ...values,
        ...(frame && { file: frame }),
      });

      onCancel();
    } catch (e) {
      setServerError(e);
    }
  };

  return (
    <>
      <Dialog.Root
        key={'create-project'}
        open={isOpen}
        onOpenChange={handleOpenChange}
        modal={true}
      >
        <Dialog.Portal>
          <Overlay />

          <Content className="DialogContent">
            <ModalHeader
              title={'Create project'}
              onClose={onCancel}
              action={'Create'}
              onAction={handleSubmit(onHandleSubmit)}
            />

            <Container>
              {serverError && serverError.message && (
                <HasServerResponse>
                  <Alert text={serverError.message} />
                </HasServerResponse>
              )}

              <Form>
                <InputsWrapper>
                  <TextInput
                    {...register('name')}
                    errorMessage={errors.name?.message}
                    label={t('name')}
                    placeholder={t('name_placeholder')}
                    required
                  />

                  <TextInput
                    {...register('client')}
                    name={'client'}
                    label={t('client')}
                    placeholder={t('client_placeholder')}
                    autocomplete="false"
                    errorMessage={errors.client?.message}
                  />
                </InputsWrapper>

                <AddCoverWrapper>
                  <AddCover
                    setFieldValue={(_, file) => setFile(file)}
                    errorMessage={fileError}
                    file={file}
                    accept={'image/*'}
                    acceptedFiles={['.png', '.jpeg']}
                  />
                </AddCoverWrapper>
              </Form>

              <ProjectTemplatesContainer>
                <p>Use a demo or a template to get started</p>

                <ProjectTemplates>
                  <SelectionProvider items={projectTemplates.map((el) => el.value)}>
                    {projectTemplates.map((template) => (
                      <ProjectTemplateItem
                        key={template.value}
                        category={template.category}
                        value={template.value as ProjectTemplateType}
                        defaultValue={ProjectTemplateType.Commercial}
                        badge={template.badge as 'Template' | 'Demo'}
                        backgroundImage={template.backgroundImage}
                        onSelect={() => setProjectTemplate(template.value)}
                      />
                    ))}
                  </SelectionProvider>
                </ProjectTemplates>
              </ProjectTemplatesContainer>
            </Container>

            {isLoading && (
              <LoadingOverlay>
                <PageLoader />
              </LoadingOverlay>
            )}
          </Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 50%);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: var(--layer-overlay);

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  flex: 1;
`;

const AddCoverWrapper = styled.div`
  width: 100%;
  margin-top: 1.6rem;

  & > div {
    & > div {
      height: 12.2rem;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }
`;

const Content = styled(Dialog.Content)`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: var(--tab-bar-height);
  right: 0;
  bottom: 0;
  z-index: var(--layer-modal);
  width: 100%;
  max-width: 100%;
  background: var(--color-grayscale-eerie-black);
  box-shadow: hsl(206 22% 7% / 35%) 0 10px 38px -10px, hsl(206 22% 7% / 20%) 0 10px 20px -15px;
  animation: content-show 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes content-show {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
`;

const Container = styled.div`
  padding: 0 2.4rem 2.4rem;
  overflow-y: scroll;
  margin-bottom: 0.8rem;
  background: var(--color-grayscale-eerie-black);
  flex: 1;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const ProjectTemplatesContainer = styled.div`
  margin-top: 1.6rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & > p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    color: var(--color-grayscale-white);
    margin-bottom: 1.6rem;
  }
`;

const ProjectTemplates = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.6rem;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
`;
