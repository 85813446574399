import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Header } from './project.page';
import { uiStore } from '../../core/stores/ui-store';
import { Storyboard } from '../../app/entities/storyboard';
import { DropdownMenu, Item } from '../../components/modals/dropdown-menu/DropdownMenu';
import { RenameDialog } from '../../components/dialogs/rename.dialog';
import { StoryboardView } from '../../features/storyboards/components/storyboard-view';
import { LoaderContainer } from './components/step-container';
import { PageLoader } from '../../components/page-loader/PageLoader';
import { DeleteDialog } from '../../components/dialogs/DeleteDialog';
import { openModal } from '../../core/modal/open-modal';

export const ProjectStoryboardRoute = observer(() => {
  const { storyboardId } = useParams();
  const storyboard = Storyboard.getOne(storyboardId!);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    uiStore.currentLocation = 'project.storyboards';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1);
  }, []);

  const handleConfirmDelete = async () => {
    navigate('../');
    await storyboard?.delete();
  };

  const handleRename = (name: string) => {
    storyboard?.update({
      name,
    });
  };

  const items: Item[] = [
    {
      title: 'Rename storyboard',
      onSelect: () =>
        openModal(RenameDialog, {
          defaultName: storyboard!.name,
          onSubmit: handleRename,
          title: 'Rename storyboard',
          label: 'File name',
        }),
    },
    {
      title: 'Delete storyboard',
      type: 'danger' as 'danger',
      onSelect: () =>
        openModal(DeleteDialog, {
          onSubmit: handleConfirmDelete,
          text: 'Delete storyboard',
          title: 'Are you sure you want to delete this storyboard ?',
        }),
    },
  ];

  return (
    <>
      <Header>
        <Crumb>
          <Parent to="../">Storyboards</Parent>
          <ChevronRightIcon />
          {storyboard?.name}
        </Crumb>

        <DropdownMenu items={items} />
      </Header>

      {isLoading || !storyboard ? (
        <LoaderContainer>
          <PageLoader />
        </LoaderContainer>
      ) : (
        <StoryboardView storyboard={storyboard} />
      )}
    </>
  );
});

const Crumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
`;

const Parent = styled(Link)`
  color: #e3e4e7;

  &:hover {
    color: white;
  }
`;
