import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { ScreenHeader } from '../../projects/components/screen-header';
import { ScreenContainer } from '../../projects/components/styled-project-components';
import { LocationBreadcrumb } from '../components/location-breadcrumb';
import { ActionBar } from '../../assets/components/action-bar';
import { FolderView } from '../../assets/components/folder-view';
import { Location } from '../../../app/entities/location';

import type { ScreenProps } from '../../projects/models/types';

export const LocationFilesScreen: React.FC<ScreenProps> = observer(({ step, spaceId }) => {
  const { locationId } = useParams() as { locationId: string };
  const location = Location.getOne(locationId)!;

  return (
    <>
      <ScreenHeader
        breadcrumb={<LocationBreadcrumb locationId={locationId!} />}
        stepId={step._id}
        title={step.name}
        dueDate={step.dueDate}
        startDate={step.startDate}
        handleUpdateTimeFrame={(values) => step.update(values)}
        canValidateStep={false}
      />

      <Content>
        <FolderView stepId={step._id} folderId={location.assetsFolderId || ''} isRoot />
      </Content>

      <ActionBar spaceId={spaceId!} projectId={step.projectId} />
    </>
  );
});

const Content = styled(ScreenContainer)`
  max-height: calc(100% - 4rem);
`;
