import React, { useEffect, useMemo, useState } from 'react';

import { AssignContactsModal } from '../../contacts/components/assign-contacts.modal';
import { Project } from '../../../app/entities/project';
import { TeamContact } from '../../teams/models/team';
import { Space } from '../../../app/entities/space';
import { Contact } from '../../../app/entities/contact';

type AddKeyContactModalProps = {
  projectId: string;
  spaceId: string;
  onCancel: () => void;
  title: string;
  isOpen?: boolean;

  /**
   * This props is called position instead of role to avoid ARIA confusion
   */
  position: string;
};

export const AddKeyContactModal: React.FC<AddKeyContactModalProps> = ({
  projectId,
  spaceId,
  onCancel,
  title,
  position,
}) => {
  const [addedContacts, setAddedContacts] = useState<TeamContact[]>([]);

  const space = Space.getOne(spaceId);

  const contacts = space?.contacts || [];

  const project = Project.getOne(projectId)!;

  const assignedContacts = useMemo(() => {
    return project?.contacts || [];
  }, [project?.contacts]);

  useEffect(() => {
    const addedContacts = assignedContacts?.filter((contact) => contact.role === position) || [];

    setAddedContacts(addedContacts);
  }, [assignedContacts, position]);

  const handleAddKeyContact = async (contactId: string) => {
    const addedContact = contacts.find((contact) => contact._id === contactId);

    if (addedContact) {
      setAddedContacts((addedContacts) => [
        ...addedContacts,
        {
          ...addedContact,
          role: position,
        },
      ]);
    }

    const contact = Contact.getOne(contactId);

    if (!contact || !project) return;

    try {
      await project.addKeyContact(contact, position);
    } catch (e: any) {
      console.log(e.data.message);
    }
  };

  const handleRemoveKeyContact = async (contactId: string) => {
    setAddedContacts((addedContacts) =>
      addedContacts?.filter((contact) => contact._id !== contactId),
    );

    try {
      await project.removeKeyContact(contactId);
    } catch (e: any) {
      console.log(e.data.message);
    }
  };

  return (
    <AssignContactsModal
      assignedContacts={assignedContacts}
      addedContacts={addedContacts}
      onAdd={handleAddKeyContact}
      onDelete={handleRemoveKeyContact}
      onCancel={onCancel}
      spaceId={spaceId}
      title={title}
      subtitle={`Selected ${position?.toLowerCase()}`}
    />
  );
};
