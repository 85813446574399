import React, { useRef, useState } from 'react';
import { Avatar } from '@producer-io/ui-kit';
import { observer } from 'mobx-react-lite';
import {
  BadgeIcon,
  MixerHorizontalIcon,
  MixerVerticalIcon,
  PersonIcon,
  PieChartIcon,
} from '@radix-ui/react-icons';
import styled from 'styled-components';

import { Task, TaskStatus } from '../../../../app/entities/task';
import { CheckboxItem } from '../../../../components/modals/dropdown-menu/DropdownMenu';

import { useCurrentSpace } from '../../../../hooks/use-current-space';
import { FlagIcon } from '../../../../components/icons';
import { DrilldownMenu } from '../../../../components/drilldown-menu/drilldown-menu';
import { Dot } from '../../../process/components/step-status-selector';
import { PriorityIcon } from '../../../process/components/priority-selector';
import { Project, ProjectPriority } from '../../../../app/entities/project';
import { ProjectFilterChip } from './project-filter-chip';
import { CustomAvatar, FilterTrigger } from '../styled-tasks-components';
import { Point } from 'features/tags/components/styled-tags-components';
import { useCurrentProject } from '../../../../hooks/use-current-project';

type ProjectFiltersProps = {
  status?: TaskStatus[];
  assigneeIds?: string[];
  tagIds?: string[];
  priority?: ProjectPriority[];
};

export const ProjectTasksFilters: React.FC = observer(() => {
  const project = useCurrentProject()!;
  const space = useCurrentSpace();
  const projectTasksFilter = project!.tasksFilter;
  const currentTokens = projectTasksFilter.orderedTokens;

  const triggerRef = useRef<HTMLDivElement>(null);

  const [alignOffset, setAlignOffset] = useState(0);
  const [defaultMenuPath, setDefaultMenuPath] = useState<number[]>([]);

  const handleCheckSelect = (
    event: Event,
    prop: keyof Pick<typeof projectTasksFilter, 'status' | 'priority' | 'assigneeIds' | 'tagIds'>,
  ) => {
    const target = event.currentTarget as HTMLElement;
    const isChecked = target.getAttribute('data-state') === 'checked';

    if (projectTasksFilter[prop]?.length === 1 && isChecked) {
      if (projectTasksFilter.filtersCount === 1) {
        setDefaultMenuPath([]);
        event.preventDefault();
      }
      return;
    }
    event.preventDefault();
  };

  const statusItems: CheckboxItem[] = Object.entries(Task.StatusMapper).map(([key, { title }]) => {
    const isChecked = projectTasksFilter.status?.includes(key as TaskStatus);

    return {
      title: title,
      onCheckedChange: (isChecked) => {
        if (isChecked) {
          projectTasksFilter.addToFilter('status', key);
        } else {
          projectTasksFilter.removeFromFilter('status', key);
        }
      },
      onSelect: (e) => handleCheckSelect(e, 'status'),
      isChecked,
      icon: <Dot data-status={key as TaskStatus} />,
    };
  });

  const sortedMembersItems: CheckboxItem[] = project.collaborators
    .map((el) => {
      const isChecked = projectTasksFilter.assigneeIds?.includes(el._id);

      return {
        title: el.fullName,
        value: el._id,
        icon: <Avatar size="small" initials={el.initials} src={el.avatar} />,
        onCheckedChange: (isChecked: boolean) => {
          if (isChecked) {
            projectTasksFilter.addToFilter('assigneeIds', el._id);
          } else {
            projectTasksFilter.removeFromFilter('assigneeIds', el._id);
          }
        },
        onSelect: (e: any) => handleCheckSelect(e, 'assigneeIds'),
        isChecked,
      };
    })
    .sort((a, b) => a.title.localeCompare(b.title));

  const assigneeItems: CheckboxItem[] = [
    {
      title: 'No assignee',
      icon: (
        <CustomAvatar>
          <PersonIcon />
        </CustomAvatar>
      ),
      onCheckedChange: (isChecked) => {
        if (isChecked) {
          projectTasksFilter.addToFilter('assigneeIds', ['', null, undefined]);
        } else {
          projectTasksFilter.removeFromFilter('assigneeIds', ['', null, undefined]);
        }
      },
      onSelect: (e) => handleCheckSelect(e, 'assigneeIds'),
      isChecked: projectTasksFilter.assigneeIds?.includes(''),
    },
    ...sortedMembersItems,
  ];

  const sortedTagsItems: CheckboxItem[] = space.tags
    .filter((tag) => tag.target === 'task')
    .map((tag) => {
      const isChecked = projectTasksFilter.tagIds?.includes(tag._id);

      return {
        title: tag.text,
        value: tag._id,
        icon: <Point color={tag.color} />,
        onCheckedChange: (isChecked) => {
          if (isChecked) {
            projectTasksFilter.addToFilter('tagIds', tag._id);
          } else {
            projectTasksFilter.removeFromFilter('tagIds', tag._id);
          }
        },
        onSelect: (e) => handleCheckSelect(e, 'tagIds'),
        isChecked,
      };
    });

  const tagItems: CheckboxItem[] = [
    {
      title: 'No tag',
      icon: <Point color="defaultColor" />,
      onCheckedChange: (isChecked) => {
        if (isChecked) {
          projectTasksFilter.addToFilter('tagIds', ['', null, undefined]);
        } else {
          projectTasksFilter.removeFromFilter('tagIds', ['', null, undefined]);
        }
      },
      onSelect: (e) => handleCheckSelect(e, 'tagIds'),
      isChecked: projectTasksFilter.tagIds?.includes(''),
    },
    ...sortedTagsItems,
  ];

  const priorityItems: CheckboxItem[] = Project.PriorityArray.map(([key, { title }]) => {
    const isChecked = projectTasksFilter.priority?.includes(Number(key));

    return {
      title: title,
      icon: <PriorityIcon priority={Number(key)} />,
      onCheckedChange: (isChecked) => {
        if (isChecked) {
          projectTasksFilter.addToFilter('priority', Number(key));
        } else {
          projectTasksFilter.removeFromFilter('priority', Number(key));
        }
      },
      onSelect: (e) => handleCheckSelect(e, 'priority'),
      isChecked: isChecked,
    };
  });

  const options: React.ComponentProps<typeof DrilldownMenu>['items'] = [
    {
      menuKey: 'status',
      title: 'Status',
      icon: <PieChartIcon />,
      subItems: statusItems,
      onSelect: (e) => {
        e.preventDefault();
      },
    },
    {
      menuKey: 'assignee',
      title: 'Assignee',
      icon: <PersonIcon />,
      subItems: assigneeItems,
      onSelect: (e) => {
        e.preventDefault();
      },
    },
    {
      menuKey: 'priority',
      title: 'Priority',
      icon: <FlagIcon />,
      subItems: priorityItems,
      onSelect: (e) => {
        e.preventDefault();
      },
    },
    {
      menuKey: 'tag',
      title: 'Tag',
      icon: <BadgeIcon />,
      subItems: tagItems,
      onSelect: (e) => {
        e.preventDefault();
      },
    },
  ];

  const presetFilterTrigger = (event: React.MouseEvent<HTMLDivElement>, token?: string) => {
    if (!triggerRef.current) {
      return;
    }

    const div = event.currentTarget;
    const offsetLeft = div.offsetLeft;

    const offset = Math.ceil(offsetLeft - triggerRef.current.offsetLeft);

    setAlignOffset(offset);

    if (!token) {
      setDefaultMenuPath([]);
      return;
    }

    const menuPath = options.findIndex((option) => option.menuKey === token);
    setDefaultMenuPath([menuPath]);
  };

  return (
    <DrilldownMenu
      items={options}
      align="start"
      alignOffset={alignOffset}
      defaultMenuPath={defaultMenuPath}
      trigger={
        <Container ref={triggerRef}>
          {currentTokens?.map((token) => {
            const selectedProp = projectTasksFilter.propsMapper[
              token as keyof typeof projectTasksFilter.propsMapper
            ] as keyof ProjectFiltersProps;

            return (
              <div
                key={token}
                onPointerEnter={(e) => presetFilterTrigger(e, token)}
                onClick={(e) => presetFilterTrigger(e, token)}
              >
                <ProjectFilterChip space={space} project={project!} selectedProp={selectedProp} />
              </div>
            );
          })}

          <FilterTrigger
            onPointerEnter={(e) => presetFilterTrigger(e)}
            onClick={(e) => presetFilterTrigger(e)}
          >
            {!projectTasksFilter.filtersCount ? (
              <>
                <MixerHorizontalIcon />

                <span>Filter</span>
              </>
            ) : (
              <MixerVerticalIcon />
            )}
          </FilterTrigger>
        </Container>
      }
      modal
    />
  );
});

const Container = styled.div`
  display: flex;
  gap: 0.8rem;
`;
