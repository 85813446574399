import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';

import { SettingScreenLayout } from '../../features/settings/components/setting-screen-layout';
import { InputWithBackground } from '../../components/input-with-background/InputWithBackground';
import { ElevatedButton } from '../../components/buttons';
import { updateSpacePicture } from '../../features/spaces/stores/space.slice';
import { SettingsAvatar } from '../../components/settings-avatar/settings-avatar';
import { uiStore } from '../../core/stores/ui-store';
import { useResponsive } from '../../hooks/useResponsive';
import { breakPoint } from '../../app/theme';
import { useCurrentSpace } from '../../hooks/use-current-space';

type FormValues = {
  name: string;
};

export const SpaceSettingsRoute: React.FC = observer(() => {
  const { t } = useTranslation();
  const { isDesktop } = useResponsive();
  const space = useCurrentSpace();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    uiStore.pageTitle = {
      label: 'General',
    };

    uiStore.showBack = true;
    uiStore.action = handleSubmit(onHandleSubmit);

    uiStore.currentLocation = 'space.settings';

    return () => {
      uiStore.action = undefined;
      uiStore.currentLocation = '';
    };
  }, []);

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .matches(/^(.*)?\S+(.*)?$/, t('common:fieldRequired'))
          .required(t('common:fieldRequired'))
          .min(4, t('common:fieldMin', { min: 4 }))
          .max(32, t('common:fieldMax', { max: 32 })),
      }),
    [t],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { name: space.name },
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onHandleSubmit = async (values: any) => {
    if (isLoading && values.name) {
      return;
    }

    setIsLoading(true);

    await space.update({ name: values.name });

    setIsLoading(false);
  };

  const handleSetLogo = async (file: File) => {
    await updateSpacePicture({
      space,
      file,
      type: 'logo',
    });
  };

  const handleSetFavicon = async (file: File) => {
    await updateSpacePicture({
      space,
      file,
      type: 'avatar',
    });
  };

  return (
    <SettingScreenLayout title={isDesktop ? 'General' : ''}>
      <Container>
        <Description>
          Edit your workspace name, upload a large logo for your workspace cover and a small one for
          sidebar.
        </Description>

        <Form onSubmit={handleSubmit(onHandleSubmit)}>
          <FieldContainer>
            <InputWithBackground
              {...register('name')}
              placeholder={'Space name'}
              label={'Creative space name'}
              errorMessage={errors.name?.message}
              required
            />
          </FieldContainer>

          <DownloadImagesContainer>
            <CompanyIconWrapper>
              <SettingsAvatar
                placeholder="text"
                defaultUrl={space?.avatar}
                onChange={handleSetFavicon}
                title="Company icon"
                detail="80x80 px"
              />
            </CompanyIconWrapper>

            <CompanyLogoWrapper>
              <SettingsAvatar
                placeholder="text"
                defaultUrl={space.logo}
                onChange={handleSetLogo}
                title="Company logo"
                detail="16:9, 800x450px"
                aspectRatio={{
                  minimum: 1,
                  maximum: 4.5,
                }}
                theme="light"
              />
            </CompanyLogoWrapper>
          </DownloadImagesContainer>

          <ButtonGroupContainer>
            <ElevatedButton text="Save" type="submit" isLoading={isLoading} />
          </ButtonGroupContainer>
        </Form>
      </Container>
    </SettingScreenLayout>
  );
});

const Container = styled.div`
  padding: 1.6rem;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--color-texts-low-contrast);
  overflow-wrap: break-word;
  margin-bottom: 2.4rem;
`;

const DownloadImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-bottom: 1.6rem;

  @media (min-width: ${breakPoint.medium}px) {
    flex-direction: row;
  }
`;

const CompanyIconWrapper = styled.div`
  & > div {
    border: unset;

    & > div {
      background: unset;
      padding: unset;
    }

    & > div:last-child,
    #photo-uploader {
      width: 21.7rem;
      height: 21.7rem;
    }

    img {
      object-fit: cover;
    }
  }
`;

const CompanyLogoWrapper = styled.div`
  width: 100%;

  & > div {
    border: unset;

    & > div {
      background: unset;
      padding: unset;
    }

    & div[data-theme],
    #photo-uploader {
      height: 24.2rem;
      max-width: 43rem;
      border-radius: var(--border-radius-medium);

      @media (min-width: ${breakPoint.medium}px) {
        height: 21.7rem;
        width: 38.8rem;
      }
    }
  }
`;

const ButtonGroupContainer = styled.div`
  display: none;
  flex-direction: row;
  justify-content: end;
  border-top: 1px solid #31343c;
  padding: 2rem 0;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: flex;
  }
`;

export const FieldContainer = styled.div`
  margin-bottom: 1.6rem;

  span {
    min-height: unset;
    margin-bottom: unset;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 64.8rem;
`;
