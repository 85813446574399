import React from 'react';
import styled from 'styled-components';

import { ElevatedButton } from '../../../components/buttons';
import { breakPoint } from '../../../app/theme';

export const CrashErrorScreen: React.FC = () => (
  <Container>
    <Wrapper>
      <Text>
        There has been an error! Hit refresh to break the suspense and get back to working on your
        masterpiece.
      </Text>

      <ButtonWrapper>
        <ElevatedButton text="Refresh page" onClick={() => window.location.reload()} isFullWidth />
      </ButtonWrapper>
    </Wrapper>
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url(/images/dramatic-pause.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 2rem;

  @media screen and (min-width: ${breakPoint.small}px) {
    gap: 2.4rem;
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    gap: 3.2rem;
  }
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--color-grayscale-anti-flash-white);

  @media screen and (min-width: ${breakPoint.small}px) {
    font-size: 2rem;
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 2.4rem;
  }
`;

const ButtonWrapper = styled.div`
  width: 24rem;
  max-width: 80vw;

  & > button {
    &[data-full='true'] {
      height: 3.2rem;
    }
  }
`;
