import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Tag, TagTarget } from '../../../app/entities/tag';
import { Model } from '../../../core/engine/model';
import { Point, TagLabel } from '../../tags/components/styled-tags-components';
import { TagSelector } from '../../tags/components/tag-selector';
import { useCurrentSpace } from '../../../hooks/use-current-space';

type TagsViewerProps = {
  targetType: TagTarget;
  targetId?: string;
  onSelect?: (tagsIds: string[]) => void;
  tagsIds?: string[];
  onMoreClick?: () => void;
  overflow?: 'wrap' | 'collapse';
  alignment?: 'start' | 'end';
};

type TagTargetEntity = {
  tags: Tag[];
  tagsIds: string[];
  updateTags: (tagsIds: string[]) => Promise<void>;
} & Model;

export const TagsViewer: React.FC<TagsViewerProps> = observer(
  ({ targetId, targetType, onSelect, tagsIds, overflow = 'wrap', alignment = 'start' }) => {
    const targetEntity = targetId ? (Model.getOne(targetId) as TagTargetEntity) : null;
    let tags = targetEntity?.tags || [];

    const selectedTags = tagsIds ? tagsIds : tags.map((tag) => tag._id);

    const space = useCurrentSpace()!;

    if (tagsIds) {
      tags = space.tags.filter((el) => tagsIds.includes(el._id));
    }

    const handleChange = (tags: string[]) => {
      onSelect ? onSelect(tags) : targetEntity?.updateTags(tags);
    };

    return (
      <Container data-align={alignment}>
        <Filler />

        {tags.map((tag) => (
          <div key={tag._id} style={{ minWidth: 0 }}>
            <div style={{ display: 'flex', minWidth: 0 }}>
              <div>
                <TagSelector
                  targetType={targetType}
                  selectedTags={selectedTags}
                  onChange={handleChange}
                  trigger={() => (
                    <TagLabel
                      onClick={(e) => e.stopPropagation()}
                      key={tag._id}
                      data-visible={true}
                      color={tag.color}
                    >
                      <Point color={tag.color} />
                      {tag.text}
                    </TagLabel>
                  )}
                />
              </div>
            </div>
          </div>
        ))}

        <TagSelector targetType={targetType} selectedTags={selectedTags} onChange={handleChange} />
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 0.4rem;
  flex-shrink: 1.5;
  flex-grow: 0;
  justify-content: space-between;
  // min-width: 18rem;
`;

const Filler = styled.div`
  min-width: 0;
  display: flex;
  flex-grow: 1;
`;
