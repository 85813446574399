import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ProjectContactItem } from './project-contact-item';
import { EmptyContact } from './empty-contact';
import { AddKeyContactModal } from '../../collaborators/components/add-key-contact.modal';
import { breakPoint } from '../../../app/theme';
import { getFullName } from '../../../lib/utils/get-full-name';

import type { TeamContact } from '../../teams/models/team';
import { openModal } from 'core/modal/open-modal';

type ProjectContactsProps = {
  projectId: string;

  spaceId: string;

  contacts: TeamContact[];
};

export const ProjectContacts: React.FC<ProjectContactsProps> = observer(
  ({ projectId, spaceId, contacts }) => {
    const producer = contacts.find(
      (collaborator) => collaborator.role?.toLowerCase() === 'producer',
    );
    const director = contacts.find(
      (collaborator) => collaborator.role?.toLowerCase() === 'director',
    );
    const line = contacts.find(
      (collaborator) => collaborator.role?.toLowerCase() === 'line producer',
    );

    const openKeyContactModal = (position: string) => {
      openModal(AddKeyContactModal, {
        projectId,
        spaceId,
        position,
        title: `Select ${position?.toLowerCase()}`,
      });
    };

    return (
      <Container>
        {producer ? (
          <ProjectContactItem
            avatar={producer.avatar || ''}
            name={getFullName(producer)}
            position="Producer"
          />
        ) : (
          <EmptyContact contactName="Producer" onClick={() => openKeyContactModal('Producer')} />
        )}

        {director ? (
          <ProjectContactItem
            avatar={director.avatar || ''}
            name={getFullName(director)}
            position="Director"
          />
        ) : (
          <EmptyContact contactName="Director" onClick={() => openKeyContactModal('Director')} />
        )}

        {line ? (
          <ProjectContactItem
            avatar={line.avatar || ''}
            name={getFullName(line)}
            position="Line Producer"
          />
        ) : (
          <EmptyContact
            contactName="Line Producer"
            onClick={() => openKeyContactModal('Line producer')}
          />
        )}
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    min-width: 24rem;
    gap: 0;
    border-left: 1px solid #3c404a;
    padding-left: 2.4rem;
  }
`;
