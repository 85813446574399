import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ValidationError, AnySchema } from 'yup';

import { KeyboardKeys } from '../../assets/enums/keyboard-keys.enum';

interface EditableTextProps {
  onSubmit: (value?: string) => void;
  value?: string;
  schema?: AnySchema;
  disabled?: boolean;
  isBorderless?: boolean;
  maxLength?: number;
  validationType?: 'onChange' | 'onSubmit';
}

export const InlineEditableText: React.FC<EditableTextProps> = ({
  onSubmit,
  value,
  schema,
  disabled,
  maxLength,
  isBorderless,
  validationType = 'onSubmit',
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [currentValue, setCurrentValue] = useState(value);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);

  const validate = useCallback(async () => {
    if (!schema) {
      return;
    }

    return schema
      .validate(currentValue, { abortEarly: false })
      .then((res) => {
        setHasError(false);
      })
      .catch((error: any) => {
        if (error instanceof ValidationError) {
          setHasError(true);
          throw new Error(error?.inner?.[0]?.message);
        }
      });
  }, [currentValue, schema]);

  useEffect(() => {
    if (validationType === 'onChange') {
      validate();
    }
  }, [currentValue, validate, validationType]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleSubmit = async () => {
    /**
     * If the value is the same as the initial text, we don't submit
     * to avoid unnecessary API calls
     */
    if (!inputRef?.current || currentValue === value) {
      return;
    }

    try {
      await validate();
      await onSubmit(currentValue);
    } catch (e: any) {
      setErrorMessage(e.message);

      inputRef?.current?.focus();
    }
  };

  const handleBlur = async (e: any) => {
    setHasError(false);
    setErrorMessage(null);

    if (e.currentTarget.disabled) {
      return;
    }
    inputRef.current?.setAttribute('readOnly', 'true');

    await handleSubmit();
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!inputRef?.current) {
      return;
    }

    if (e.key === KeyboardKeys.Escape) {
      setCurrentValue(value);
      setHasError(false);
      setErrorMessage(null);

      /**
       * Setting the disabled property to true will trigger the onblur event
       * and will not call the handleSubmit function
       * We re-enable it immediately after for further update
       */
      inputRef.current.disabled = true;
      inputRef.current.disabled = false;
    }

    if (e.key === KeyboardKeys.Enter) {
      inputRef.current.blur();
    }
  };

  const handleFocus = () => {
    if (!inputRef?.current) {
      return;
    }

    inputRef.current.removeAttribute('readOnly');
  };

  return (
    <Container data-borderless={isBorderless}>
      <Editable
        ref={inputRef}
        placeholder={'-'}
        value={currentValue}
        onChange={({ target }) => {
          setCurrentValue(target.value);
        }}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
        onFocus={handleFocus}
        disabled={disabled}
        maxLength={maxLength}
        data-has-error={hasError}
        data-borderless={isBorderless}
        readOnly
      />

      {errorMessage && <ErrorToolTip>{errorMessage}</ErrorToolTip>}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  margin-inline-start: -1.2rem;

  &[data-borderless='true'] {
    width: 100%;
    margin-inline-start: 0;
  }
`;

const Editable = styled.input`
  cursor: text;
  border-radius: var(--border-radius-medium);
  border: 1px solid transparent;
  height: 3.2rem;
  padding: 0 1.2rem;
  display: flex;
  align-items: center;
  background: transparent;
  color: var(--color-grayscale-ghost);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:focus-within {
    border-color: var(--color-secondary);
  }

  &:hover {
    background-color: var(--color-grayscale-arsenic);
    color: var(--color-grayscale-white);
    cursor: text;
  }

  &[data-has-error='true'] {
    border-color: var(--color-error);
  }

  &:disabled {
    cursor: default;
    background: transparent;
    color: var(--color-grayscale-ghost);
  }

  &[data-borderless='true'] {
    width: 100%;
    padding: 0.2rem 0;
    height: 2.4rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--color-texts-high-contrast);
    border-radius: 0;

    &:focus-within {
      border-color: transparent;
    }

    &:hover {
      background-color: transparent;
      cursor: text;
    }

    &[data-has-error='true'] {
      border-bottom: 0.1rem solid var(--color-error);
    }
  }
`;

const ErrorToolTip = styled.div`
  display: flex;
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-error);
  color: var(--color-texts-high-contrast);
  padding: 0.6rem 0.8rem;
  border-radius: var(--border-radius-small);
  font-size: 1.2rem;
  font-weight: 400;
  transition: all 0.1s ease-in;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &::before {
    content: '';
    position: absolute;
    border: 0.4rem solid transparent;
    border-top-color: var(--color-error);
    bottom: 100%;
    transform: rotate(180deg);
  }
`;
