import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { FilterButton } from '../../assets/components/filter-button';
import { Plus, Search } from '../../../components/icons';
import { IconButton } from '../../../components/buttons';
import { TextInput } from '../../../components/text-input/TextInput';
import { LoaderContainer } from '../../../routes/project/components/step-container';
import { PageLoader } from '../../../app/components/page-loader';
import { PagePlaceholder } from '../../../components/page-placeholder/page-placeholder';
import { CandidatesList } from './candidates-list';
import { Candidate } from '../../../app/entities/candidate';
import { SORTING_TYPES } from '../../../assets/enums/assetType.enum';
import { AssignContactsModal } from 'features/contacts/components/assign-contacts.modal';
import { Character } from '../../../app/entities/character';

import type { Contact } from '../../../app/entities/contact';
import { ToolBar, Tools } from 'components/toolbar/toolbar';
import { openModal } from 'core/modal/open-modal';

export type CandidatesTabProps = {
  characterId: string;
  spaceId: string;
};

export const CandidatesTab: React.FC<CandidatesTabProps> = observer(({ characterId, spaceId }) => {
  const { t } = useTranslation('gear');

  const character = Character.getOne(characterId)!;
  const castId = character?.cast?.candidateId;

  const [searchInput, setSearchInput] = useState('');
  const [sortBy, setSortBy] = useState(SORTING_TYPES.A_TO_Z);
  const [filteredCandidates, setFilteredCandidates] = useState<Candidate[]>([]);

  useEffect(() => {
    setFilteredCandidates(character.candidates);
  }, [character.candidates]);

  type SearchField = 'firstName' | 'lastName';
  type SearchFieldsProps = Array<keyof Pick<Contact, SearchField>>;

  const startSearch = useCallback(
    async (value: string) => {
      if (!character) {
        return;
      }

      const searchFields: SearchFieldsProps = ['firstName', 'lastName'];

      const filteredCandidates = character.candidates.filter((candidate) =>
        searchFields.some((searchField) =>
          value
            .split(' ')
            .some((inputValue: string) =>
              candidate[searchField]?.toLowerCase().includes(inputValue.toLowerCase()),
            ),
        ),
      );
      setFilteredCandidates(filteredCandidates);
    },
    [character],
  );

  const hasCharacters = !!character?.candidates?.length;

  const handleDelete = (candidateId: string) => {
    character.removeCandidate(candidateId);
  };

  const openCandidateModal = () => {
    openModal(AssignContactsModal, {
      title: 'Add Candidates',
      subtitle: 'Added candidates',
      addedContacts: (character?.candidates as any) || [],
      onAdd: character.addCandidate,
      onDelete: handleDelete,
      spaceId,
    });
  };

  return (
    <Container>
      <ToolBar>
        <CustomTextInput
          key="search-candidates"
          value={searchInput}
          icon={<Search />}
          placeholder={t('search')}
          onChange={(e) => {
            const value = e.currentTarget.value;
            setSearchInput(value);
            startSearch(value);
          }}
        />
        <FilterButton
          key="candidates-buttons"
          sortTypes={[SORTING_TYPES.A_TO_Z, SORTING_TYPES.Z_TO_A]}
          value={sortBy}
          onSelect={setSortBy}
        />
        <IconButton key="add-candidates" icon={<Plus />} onClick={openCandidateModal} />
      </ToolBar>

      {!character?.candidates ? (
        <LoaderContainer>
          <PageLoader />
        </LoaderContainer>
      ) : hasCharacters ? (
        <CandidatesList candidates={filteredCandidates} castId={castId} sortBy={sortBy} />
      ) : (
        <PagePlaceholder
          backgroundImgUrl="/images/Process_Badges_Pre_Production.svg"
          description='Candidates will be displayed here, you can add them by clicking on the "Add candidate" button'
          actionButton={{ text: 'Add Candidate', onClick: openCandidateModal }}
        />
      )}
    </Container>
  );
});

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & > header {
    margin: 1.6rem 0;
  }
`;

const CustomTextInput = styled(TextInput)`
  min-width: 32rem;

  input {
    background-color: var(--color-grey-8);
    border: none;
    border-radius: 6px;
    color: var(--color-grey-4);
    height: 3.2rem;
  }

  & svg {
    fill: var(--color-grey-4);
    width: 1.6rem;
    height: 1.6rem;
  }
`;
