import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { Tag, TagTarget } from '../../../app/entities/tag';
import { TagCollection } from './tag-collection';

type TagListProps = {
  tags: Tag[];
};

export const TagList: React.FC<TagListProps> = observer(({ tags }) => {
  const groupedTags: Record<TagTarget, Tag[]> = Tag.groupAndSortTags(tags);

  return (
    <>
      <ListHeader>
        <div>Name</div>

        <Right>
          <div>Usage</div>
          <div />
        </Right>
      </ListHeader>

      <Container>
        {Object.entries(groupedTags).map(([target, tags]) => (
          <TagCollection
            key={target}
            target={target as TagTarget}
            tags={tags}
            defaultValue={{ target }}
          />
        ))}
      </Container>
    </>
  );
});

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-grayscale-light-slate);
`;

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.2rem;
  min-width: 12rem;
`;
