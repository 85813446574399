import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Step } from '../../../app/entities/step';
import { Stripboard } from '../../../app/entities/stripboard';
import { formatDate } from '../../../lib/utils/DateHelper';
import { dateShort } from '../../../assets/contants/dates-fns';

import { DayBreakStrip } from '../models/types';
import { IconButton } from '../../../components/buttons';
import { Pencil1Icon, PlusIcon } from '@radix-ui/react-icons';
import { RenameStepModal } from '../../process/components/rename-step.modal';
import { timeTo12HourFormat, timeTo24HourFormat } from '../../../lib/utils/Convertor';
import { InlineTimeSelector } from './InlineTimeSelector';
import { BaseRow, Cell, RowMirror } from './styled-strips';
import { DropdownTrigger } from '../../projects/components/styled-project-components';
import { Shot } from '../../../app/entities/shot';
import { Tooltip } from '../../../components/tooltip/Tooltip';

export type DayStartStripProps = {
  strip: DayBreakStrip;
  stripboard: Stripboard;
  isShotList?: boolean;
  startDate?: string;
};

export const DayStartStripCore: React.FC<DayStartStripProps & { shootingDay: Step }> = observer(
  ({ strip, stripboard, shootingDay, startDate }) => {
    const { dod } = strip.data || {};
    const [showNameModal, setShowNameModal] = useState(false);

    const handleChangeTime = async (value: { hour: number; minute: number }) => {
      const time = timeTo24HourFormat(value!);
      const { hour, minute } = time || {};

      await shootingDay.update({
        info: {
          generalCallTime: {
            hour,
            minute,
          },
        },
      });
    };

    const formattedDate = startDate ? formatDate(startDate, dateShort) : '';

    return (
      <>
        <Wrapper>
          <NameWrapper>
            <DayName>
              (#{dod}) {shootingDay.name}
            </DayName>

            <IconButton
              icon={<Pencil1Icon color="#fff" />}
              size="small"
              color="#fff"
              onClick={() => setShowNameModal(true)}
            />
          </NameWrapper>

          <CenterWrapper>
            <DayText>{formattedDate}</DayText>

            {shootingDay.info?.generalCallTime && (
              <InlineTimeSelector
                time={timeTo12HourFormat(shootingDay.info?.generalCallTime)}
                textToDisplay="General call time "
                backgroundVisible
                style={{ margin: '0' }}
                onChange={handleChangeTime}
              />
            )}
          </CenterWrapper>

          <DodText>
            Day {dod} of {stripboard.totalShootingDays}
          </DodText>

          {shootingDay.info?.generalCallTime && (
            <InlineTimeWrapper>
              <InlineTimeSelector
                backgroundVisible
                time={timeTo12HourFormat(shootingDay.info.generalCallTime)}
                style={{ margin: '0' }}
                onChange={handleChangeTime}
              />
            </InlineTimeWrapper>
          )}

          <ActionMenuWrapper>
            <Tooltip text={'Add shot'}>
              <DropdownTrigger
                onClick={() => {
                  Shot.createShot({
                    projectId: stripboard.projectId,
                    stripPosition: stripboard.getPreviousShootingDayPosition(strip._id),
                    shootingDayId: shootingDay._id,
                  });
                }}
              >
                <PlusIcon />
              </DropdownTrigger>
            </Tooltip>
          </ActionMenuWrapper>
        </Wrapper>

        {showNameModal && (
          <RenameStepModal onCancel={() => setShowNameModal(false)} step={shootingDay} />
        )}
      </>
    );
  },
);

export const DayStartStrip: React.FC<DayStartStripProps & {}> = observer(
  ({ strip, stripboard }) => {
    const shootingDay = strip ? Step.getOne(strip.data.stepId) : undefined;

    if (!shootingDay) return null;

    return (
      <Row>
        <Cell colSpan={100}>
          <DayStartStripCore
            stripboard={stripboard}
            strip={strip}
            shootingDay={shootingDay}
            startDate={shootingDay.dueDate}
          />
        </Cell>
      </Row>
    );
  },
);

const Row = styled(BaseRow)`
  height: 5.2rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  cursor: default !important;
  position: relative;
`;

const Wrapper = styled(RowMirror)`
  top: unset;
  bottom: 0;
  background-color: var(--color-primary-sandal);
  border-radius: 0.4rem;
  padding-left: 1.6rem;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  grid-column: 1 / span 3;
`;

const DayName = styled.h3`
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--color-grayscale-white, #fafafa);
  line-height: 2.4rem;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CenterWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 1.6rem;
  justify-content: center;
`;

const DayText = styled.p`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-grayscale-ghost, #cacdd5);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8rem;

  &:first-child {
    text-align: right;
    left: -10rem;
  }
`;

const ActionMenuWrapper = styled.div`
  grid-column: 9 / span 1;
  padding-inline-end: 1.8rem;
  margin-left: auto;
`;

const InlineTimeWrapper = styled.div`
  grid-column: 8 / span 1;
  padding-inline-end: 1.6rem;
  margin-inline-start: -1.2rem;
`;

const DodText = styled.p`
  grid-column: 6 / span 1;
  color: var(--color-grayscale-ghost, #cacdd5);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8rem;
  text-align: left;

  @media screen and (max-width: 1130px) {
    padding-left: 0.8rem;
  }
`;
