import { Model } from '../../core/engine/model';
import { ManyToOne } from '../../core/engine/decorators';
import { Space } from './space';
import { api } from '../../api';
import { getFullName } from '../../lib/utils/get-full-name';
import { getNameInitials } from '../../lib/utils/get-name-initials';
import { toastPool } from '../../features/toasts/models/toast-pool';
import { Toast } from '../../features/toasts/models/toast';

export class Invitation extends Model {
  spaceId: string;
  displayName: string;
  userId: string;
  role: string;
  email: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  status?: string;
  invitedAt?: string;

  @ManyToOne('invitations')
  space: Space;

  get fullName() {
    return getFullName(this) || this.email;
  }

  get initials() {
    return getNameInitials(this) || this.email[0];
  }

  constructor() {
    super('invitations');
  }

  async resend() {
    await api.put(`/invitations/${this._id}/resend`);
    const invitedAtDate = new Date();
    this.invitedAt = invitedAtDate.toISOString();
  }

  async delete() {
    const shouldUpdateSeat =
      this.space &&
      this.space.hasSubscription() &&
      !this.space.isSelfServing &&
      this.role !== 'guest';

    if (shouldUpdateSeat) {
      this.space.seats!.current--;
    }

    try {
      await super.delete();
      toastPool.insert(new Toast('Invitation revoked.'));
    } catch (e) {
      if (shouldUpdateSeat) {
        this.space.seats!.current++;
      }
    }
  }

  toPOJO(): Record<string, any> {
    return {};
  }
}
