import { action, makeAutoObservable, observable } from 'mobx';

import { Model } from './model';

type TypeKey = keyof Omit<Model, '$name'>;

type ModelWithRelations = Model & {
  relations?: Array<{
    type: 'one-to-one' | 'one-to-many' | 'many-to-one' | 'many-to-many';
    key: TypeKey;
    foreignKey: TypeKey;
  }>;
};

type Relation = {
  key: TypeKey;
  id: string;
  foreignKey: TypeKey;
  type: string;
};

export class Engine {
  pool: Map<string, Model> = new Map();
  danglingEntities: Map<string, Relation[]> = new Map();
  loading: boolean = true;

  constructor() {
    makeAutoObservable(this, {
      pool: observable,
      loading: observable,
      insert: action,
      setLoading: action,
      updateEntity: action,
    });
  }

  insert(object: ModelWithRelations) {
    if (this.pool.has(object._id)) {
      // const currentObject = this.pool.get(object._id)!;
      // Object.assign(currentObject, object);
      return;
    }

    this.pool.set(object._id, object);

    if (object.relations && object.relations.length > 0) {
      for (const relation of object.relations) {
        const { type, key, foreignKey } = relation;

        if (type === 'one-to-many') {
          // loop over the dangling
          let projects = this.danglingEntities.get(object._id); // Array of relations
          if (!projects) continue;

          projects = projects.filter((relation) => relation.foreignKey === key);
          if (projects.length === 0) continue;

          projects.forEach(({ id }) => {
            const project = this.pool.get(id);

            if (!project) {
              return;
            }

            if (!(object[key] as any).some((item: Model) => item._id === project._id)) {
              object[key] = [...(object[key] as any), project] as any; // using the spread operator to trigger mobx observable
            }

            if (foreignKey) {
              project[foreignKey] = object as any;
            }
          });

          // if the current item in the loop is a child add it to the array
          // this.danglingEntities.delete(object._id);
          continue;
        }

        if (type === 'many-to-many') {
          const localFieldName = `${key}Ids` as TypeKey;

          /**
           * If there is no list of ids locally it means the relationships will be created by the counterpart
           */
          if (!object[localFieldName] || !Array.isArray(object[localFieldName])) {
            continue;
          }

          // @ts-ignore
          object[localFieldName].map((id: string) => {
            const foreign = this.pool.get(id);

            if (foreign) {
              if (!(object[key] as any).some((item: Model) => item._id === foreign._id)) {
                object[key] = [...(object[key] as any), foreign] as any; // using the spread operator to trigger mobx observable
              }

              if (
                foreignKey &&
                !(foreign[foreignKey] as any).some((item: Model) => item._id === object._id)
              ) {
                foreign[foreignKey] = [...(foreign[foreignKey] as any), object] as any;
              }
            } else {
              const relation = {
                key,
                id: object._id,
                foreignKey,
                type,
              };

              if (this.danglingEntities.has(id)) {
                this.danglingEntities.get(id)!.push(relation);
              } else {
                this.danglingEntities.set(id, [relation]);
              }
            }
          });
          continue;
        }

        const foreignIdKey = `${key}Id` as TypeKey;

        const foreign = this.pool.get(object[foreignIdKey] as unknown as string);

        if (foreign) {
          switch (type) {
            case 'many-to-one':
              object[key] = foreign as any;

              if (
                foreignKey &&
                !(foreign[foreignKey] as any).some((item: Model) => item._id === object._id)
              ) {
                foreign[foreignKey] = [...(foreign[foreignKey] as any), object] as any; // using the spread operator to trigger mobx observable
              }
              break;
            case 'one-to-one':
              object[key] = foreign as any;

              if (foreignKey) {
                foreign[foreignKey] = object as any;
              }
              break;
          }
        } else {
          const relation = {
            key,
            id: object._id,
            foreignKey,
            type,
          };

          if (this.danglingEntities.has(object[foreignIdKey] as string)) {
            this.danglingEntities.get(object[foreignIdKey] as string)!.push(relation);
          } else if (object[foreignIdKey]) {
            this.danglingEntities.set(object[foreignIdKey] as string, [relation]);
          }
        }
      }
    }

    /**
     * Search for any relationships that are waiting for this object to be inserted
     */
    if (this.danglingEntities.has(object._id)) {
      const relations = this.danglingEntities.get(object._id) as Relation[];
      const missingRelations: Relation[] = [];

      relations.forEach(({ key, foreignKey, id, type }) => {
        const foreign = this.pool.get(id);

        if (!foreign) {
          missingRelations.push({ key, foreignKey, id, type });
          return;
        }

        switch (type) {
          case 'many-to-many':
            foreign[key] = [...(foreign[key] as any), object] as any;

            if (foreignKey) {
              object[foreignKey] = [...(object[foreignKey] as any), foreign] as any;
            }
            break;
          case 'one-to-many':
            break;
          case 'many-to-one':
            break;
          case 'one-to-one':
            foreign[key] = object as any;
            object[foreignKey] = foreign as any;
            break;
        }
      });

      if (missingRelations.length) {
        this.danglingEntities.set(object._id, missingRelations);
      } else {
        this.danglingEntities.delete(object._id);
      }
    }
  }

  delete(object: ModelWithRelations) {
    if (object.relations && object.relations.length > 0) {
      for (const relation of object.relations) {
        const { type, key, foreignKey } = relation;

        const foreignIdKey = `${key}Id` as TypeKey;

        if (foreignKey) {
          switch (type) {
            case 'many-to-many': {
              // @ts-ignore
              object[key].forEach((item: Model) => {
                // @ts-ignore
                item[foreignKey] = item[foreignKey].filter((i: Model) => i !== object) as any;
              });
              break;
            }

            case 'many-to-one': {
              const foreign = this.pool.get(object[foreignIdKey] as string);

              if (foreign) {
                foreign[foreignKey] = (foreign[foreignKey] as unknown as Array<Model>).filter(
                  (item) => item._id !== object._id,
                ) as any;
              }

              break;
            }

            case 'one-to-one': {
              const foreign = this.pool.get(object[foreignIdKey] as string);

              if (foreign) {
                foreign[foreignKey] = null as any;
              }

              break;
            }
          }
        }
      }
    }

    this.pool.delete(object._id);
  }

  updateEntity<T extends Model>(id: string, entity: Partial<T>) {
    const currentEntity = this.getEntity<T>(id);
    if (!currentEntity) return;
    Object.keys(entity).forEach((key) => {
      currentEntity[key as TypeKey] = entity[key as TypeKey] as any;
    });
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  getEntity<T extends Model>(id: string): T | undefined {
    return this.pool.get(id) as T;
  }
}

export const entityPool = new Engine();
