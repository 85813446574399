import React, { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { AvatarCreatableSelect } from '../../../components/select/components/avatar-creatable-select/avatar-creatable-select';
import { RoleDropdown, type RoleTypes } from './role-dropdown';

import { type AvatarOption } from '../../../components/select/components/avatar-select/avatar-select-option';
import { type SelectRefType } from '../../../components/select';
import { type Space } from '../../../app/entities/space';

type ContactSelectProps = Omit<
  React.ComponentProps<typeof AvatarCreatableSelect>,
  'value' | 'onChange'
> & {
  space: Space;
  options: React.ComponentProps<typeof AvatarCreatableSelect>['options'];
  value?: string;
  isLoading?: boolean;
  errorMessage?: string;
  setErrorMessage?: (error?: string) => void;
  onChange?: (value?: string) => void;
  role?: RoleTypes;
  onRoleChange?: (role?: RoleTypes) => void;
};

export const MemberRoleSelector = React.forwardRef<SelectRefType<AvatarOption>, ContactSelectProps>(
  (
    {
      space,
      options,
      value,
      isLoading,
      errorMessage,
      role,
      onChange,
      setErrorMessage,
      onRoleChange,
    },
    ref,
  ) => {
    const [hasOptions, setHasOptions] = useState<boolean>();
    const [showDropdown, setShowDropdown] = useState<boolean>();

    const dropdownWidth = React.useRef<number>(0);

    useEffect(() => {
      if (!hasOptions && value) {
        setShowDropdown(true);
      } else {
        setShowDropdown(false);
      }
    }, [hasOptions, value]);

    const handleChange: React.ComponentProps<typeof AvatarCreatableSelect>['onChange'] = (
      option: any,
    ) => {
      onChange?.(option?.value);
      onRoleChange?.('guest');
    };

    const handleCreate: React.ComponentProps<typeof AvatarCreatableSelect>['onCreate'] = (
      value: string,
    ) => {
      onChange?.(value);
    };

    const controlContainerCallbackRef = (node: HTMLDivElement) => {
      if (!node) {
        return;
      }
      dropdownWidth.current = node.clientWidth;
    };

    return (
      <Container data-error={!!errorMessage} ref={controlContainerCallbackRef}>
        <AvatarCreatableSelect
          ref={ref}
          name="invite-member"
          placeholder={'name@domain.com'}
          options={options}
          onChange={handleChange}
          onCreate={handleCreate}
          isLoading={isLoading}
          width={dropdownWidth.current}
          menuPortalTarget={document.body}
          setHasOptions={setHasOptions}
          onInputChange={(value) => {
            onChange?.(value);
            setErrorMessage?.(undefined);
          }}
          isSearchable
          isClearable
          backspaceRemovesValue
        />

        <DropdownWrapper data-hide={!showDropdown}>
          <RoleDropdown
            trigger={(label) => (
              <Trigger>
                <Label>{label}</Label>

                <ChevronDownIcon color="var(--color-grayscale-shuttle)" />
              </Trigger>
            )}
            width={dropdownWidth.current - 8}
            space={space}
            onChange={onRoleChange}
            role={role}
          />
        </DropdownWrapper>

        {errorMessage && <Error>{errorMessage}</Error>}
      </Container>
    );
  },
);

MemberRoleSelector.displayName = 'MemberRoleSelector';

const DropdownWrapper = styled.div`
  display: flex;

  &[data-hide='true'] {
    z-index: -1;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    animation: dropdown-slide-out 300ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }

  &[data-hide='false'] {
    z-index: auto;
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
    animation: dropdown-slide-in 300ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  @keyframes dropdown-slide-in {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes dropdown-slide-out {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1;
  border: 0.1rem solid var(--color-grayscale-trout);
  border-radius: var(--border-radius-small);

  & > *:first-child {
    flex: 1;
    width: 100%;
  }

  &:hover {
    border-color: var(--color-primary-crayola);
  }

  &[data-error='true'] {
    border-color: var(--color-secondary-roman, #db5c66);
  }
`;

const Trigger = styled.div`
  user-select: none;
  display: flex;
  min-width: 7.2rem;
  padding: 0 0.8rem;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;
  border-inline-start: 0.1rem solid var(--color-grayscale-trout);

  & + *[data-radix-popper-content-wrapper] {
    & > [data-radix-menu-content] {
      backdrop-filter: unset;
      background: var(--color-grayscale-tuna, #31343c);
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
      border-radius: var(--border-radius-small);
    }
  }
`;

const Label = styled.p`
  color: var(--color-grayscale-white);
  text-align: start;
  font-size: 1.2rem;
  font-weight: 400;
`;

const Error = styled.span`
  position: absolute;
  right: 0;
  bottom: -1.6rem;
  color: var(--color-secondary-roman, #db5c66);
  font-family: Inter, sans-serif;
  font-size: 1rem;
  letter-spacing: -0.2px;
  text-align: start;
  width: 100%;
`;
