import React from 'react';
import {
  CodeSandboxLogoIcon,
  DownloadIcon,
  EnterIcon,
  Pencil1Icon,
  TrashIcon,
} from '@radix-ui/react-icons';

import { download } from '../../../lib/utils/download';
import { RenameDialog } from '../../../components/dialogs/rename.dialog';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { catchError } from '../../../core/catch-error';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { UpdateLinkModal } from './update-link.modal';
import { useCurrentSpace } from '../../../hooks/use-current-space';
import { toastPool } from 'features/toasts/models/toast-pool';
import { Toast } from 'features/toasts/models/toast';
import { api } from 'api';
import { capitalizeFirstLetter } from '../../../lib/utils/capitalize-first-letter';
import { openModal } from 'core/modal/open-modal';

import type { Asset } from '../../../app/entities/asset';

type AssetMenuProps = {
  asset: Asset;
  onOpen: () => void;
};

export const AssetMenu: React.FC<AssetMenuProps> = ({ asset, onOpen }) => {
  const space = useCurrentSpace();

  const handleDownload = async (event: Event) => {
    event.stopPropagation();
    if (!asset.uploaded) return;
    await download(asset._id);
  };

  const handleDelete = () => {
    asset.delete();
  };

  const handleRenameFile = async (name: string) => {
    try {
      await asset.update({ name });
    } catch (e) {
      catchError(e);
    }
  };

  const handleExtractScript = async () => {
    try {
      await api.post(`/assets/${asset._id}/extract-script`);
      toastPool.insert(new Toast('Script imported!'));
    } catch (e) {
      toastPool.insert(new Toast('Error importing script!', 'error'));
    }
  };

  const items = [
    {
      onSelect: onOpen,
      title: asset.type === 'link' ? 'Open in new tab' : 'Open',
      icon: <EnterIcon />,
    },
    ...(asset.type === 'link'
      ? [
          {
            onSelect: () =>
              openModal(UpdateLinkModal, {
                onSubmit: handleOnEditLink,
                asset: asset,
              }),
            title: 'Edit link',
            icon: <Pencil1Icon />,
          },
        ]
      : []),
    ...(asset.type !== 'link'
      ? [
          {
            onSelect: () =>
              openModal(RenameDialog, {
                defaultName: asset.name,
                onSubmit: handleRenameFile,
                title: `Rename ${asset.type}`,
                label: `${capitalizeFirstLetter(asset.type)} name`,
              }),
            title: 'Rename',
            icon: <Pencil1Icon />,
          },
          ...(['version-stack', 'file'].includes(asset.type)
            ? [
                {
                  onSelect: handleDownload,
                  title: 'Download',
                  icon: <DownloadIcon />,
                  disabled: !asset.uploaded,
                  tooltipText: 'File is still uploading.',
                },
              ]
            : []),
        ]
      : []),
    ...(asset.fileType === 'application/pdf' && space!.flags?.isScriptExtractEnabled
      ? [
          {
            title: 'Extract script',
            icon: <CodeSandboxLogoIcon />,
            onSelect: handleExtractScript,
          },
        ]
      : []),
    {
      onSelect: () =>
        openModal(DeleteDialog, {
          title: `Delete ${asset.type} ?`,
          text: `Are you sure you want to delete ${asset.name} ?`,
          onSubmit: handleDelete,
        }),
      title: 'Delete',
      icon: <TrashIcon />,
      type: 'danger' as 'danger',
    },
  ];

  const handleOnEditLink = async (values: { address?: string; name?: string }) => {
    const updatedAsset = await asset.update({
      ...(values.name !== undefined && { name: values.name }),
      ...(values.address && { url: values.address }),
    });

    if (updatedAsset.meta) asset.meta = updatedAsset.meta;
    if (updatedAsset.name) asset.name = updatedAsset.name;
  };

  return <DropdownMenu items={items} />;
};
