import React from 'react';
import { createRoot } from 'react-dom/client';

export const openModal = (Modal: any, options: any) => {
  const el = document.createElement('div');
  const body = document.getElementsByTagName('body');
  body[0]!.appendChild(el);
  const root = createRoot(el);

  const handleClose = () => {
    root.unmount();
    el.remove();
  };

  root.render(<Modal {...options} onCancel={handleClose} />);
};
