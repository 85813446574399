import React, { useMemo } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';

import { CollapsibleItem } from '../../../components/collapsible-item/collapsible-item';
import { TableContainer } from '../../../components/table/styled-shared-table';
import { useResponsive } from '../../../hooks/useResponsive';
import { LocationsTable } from './locations-table';
import { MobileLocationsTable } from './locations-table.mobile';

import type { Project } from '../../../app/entities/project';
import type { Step } from '../../../app/entities/step';

type LocationListProps = {
  project: Project;
  step: Step;
};

export const LocationList: React.FC<LocationListProps> = observer(({ step }) => {
  const { isDesktop } = useResponsive();

  const searchLocationOnMaps = (address: string) => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${address}`, '_blank');
  };

  const handleMetaUpdate = async (locationId: string, parking: string) => {
    // @ts-ignore
    await step.update({ locationsMetaData: { locationId, parking } });
  };

  return (
    <>
      {isDesktop ? (
        <CollapsibleItem title="Locations" defaultOpen>
          <TableContainer>
            <LocationsTable
              step={step}
              searchLocationOnMaps={searchLocationOnMaps}
              handleMetaUpdate={handleMetaUpdate}
            />
          </TableContainer>
        </CollapsibleItem>
      ) : (
        <MobileLocationsTable step={step} searchLocationOnMaps={searchLocationOnMaps} />
      )}
    </>
  );
});
