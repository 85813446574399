import React from 'react';
import styled from 'styled-components';
import { EyeOpenIcon, TrashIcon, EyeNoneIcon } from '@radix-ui/react-icons';

import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { DropdownMenu, Item } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { DropdownTrigger } from '../../projects/components/styled-project-components';
import { openModal } from 'core/modal/open-modal';

interface MemberItemDropdownProps {
  onDelete?: () => void;
  onToggleHide?: () => void;
  isHidden?: boolean;
  isCast?: boolean;
}

export const MemberItemDropdown: React.FC<MemberItemDropdownProps> = ({
  onDelete,
  onToggleHide,
  isHidden,
  isCast = true,
}) => {
  const menuItems: Item[] = [
    ...(onToggleHide
      ? [
          {
            title: isHidden ? 'Show for this day' : 'Hide for this day',
            icon: isHidden ? <EyeOpenIcon /> : <EyeNoneIcon />,
            onSelect: onToggleHide,
            disabled: !isCast,
          },
        ]
      : []),
    ...(onDelete
      ? [
          {
            title: 'Delete',
            icon: <TrashIcon />,
            type: 'danger' as const,
            onSelect: () =>
              openModal(DeleteDialog, {
                text: 'Confirm delete crew member',
                onSubmit: onDelete!,
                title: 'Delete Crew Member',
              }),
          },
        ]
      : []),
  ];

  return (
    <Container>
      <DropdownMenu
        trigger={<DropdownTrigger data-dark="true">⋮</DropdownTrigger>}
        items={menuItems}
      />
    </Container>
  );
};

const Container = styled.div`
  display: inline-block;

  & button:hover > svg {
    stroke: var(--color-grey-5);
  }
`;
