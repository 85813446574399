import { action, computed, makeObservable, observable } from 'mobx';
import { DateTime } from 'luxon';

import { Address } from '../../dto/common/address.dto';
import { Stage } from './stage';
import { Model } from '../../core/engine/model';
import { api } from '../../api';
import { Asset } from './asset';
import { ManyToOne, OneToMany, OneToOne } from '../../core/engine/decorators';
import { entityPool } from '../../core/engine/engine';
import { Project } from './project';
import { Shot } from './shot';
import { catchError } from '../../core/catch-error';
import { Member } from './member';
import { Storyboard } from './storyboard';
import { Location } from './location';
import { toastPool } from 'features/toasts/models/toast-pool';
import { Toast } from 'features/toasts/models/toast';
import { Task } from './task';

export type StepStatus =
  | 'not-started'
  | 'in-progress'
  | 'on-hold'
  | 'needs-review'
  | 'changes-requested'
  | 'approved';

type ContactsCallTime = {
  hour: number;
  minute: number;
  period: string;
};

type ContactsMetaData = {
  contactId: string;
  callTime?: ContactsCallTime;
  note?: string;
  isHidden?: boolean;
};

type LocationsMetaData = {
  locationId: string;
  parking?: string;
};

export class Step extends Model {
  /** The step's name **/
  name: string;

  type: string;
  projectId: string;
  dueDate?: string;
  startDate?: string;
  timeZone?: string;
  validations: any[];
  phase: number;
  current: boolean;
  order: number;
  data: StepData[];
  status: StepStatus = 'not-started';

  info?: StepInfo;

  productionNotes?: string;

  contactsMetaData?: ContactsMetaData[];

  locationsMetaData?: LocationsMetaData[];

  stageId: string;

  @ManyToOne('steps')
  stage?: Stage;

  assigneeId: string | null;

  @OneToOne()
  assignee: Member | null;

  @ManyToOne('steps')
  project: Project | null = null;

  /** The step's assets **/
  @OneToMany('step')
  assets: Asset[] = [];

  /** If the step is a shooting day, the shots for this day **/
  @OneToMany('step')
  shots: Shot[] = [];

  @OneToMany('step')
  tasks: Task[] = [];

  folderAssetId: string;

  storyboardId?: string;

  @OneToOne('step')
  storyboard?: Storyboard;

  static StatusMapper: Record<StepStatus, { title: string; color: string }> = {
    'not-started': {
      title: 'Not Started',
      color: '#367bff',
    },
    'in-progress': {
      title: 'In Progress',
      color: '#367bff',
    },
    'on-hold': {
      title: 'On Hold',
      color: '#7a8296',
    },
    'needs-review': {
      title: 'Needs Review',
      color: '#f8a72d',
    },
    'changes-requested': {
      title: 'Changes Requested',
      color: '#db5c66',
    },
    approved: {
      title: 'Approved',
      color: '#38c2b8',
    },
  };

  constructor() {
    super('steps');

    this.status = 'not-started';

    makeObservable<Step>(this, {
      _id: observable,
      name: observable,
      type: observable,
      projectId: observable,
      status: observable,
      dueDate: observable,
      startDate: observable,
      validations: observable,
      phase: observable,
      current: observable,
      order: observable,
      data: observable,
      info: observable,
      contactsMetaData: observable,
      locationsMetaData: observable,
      assigneeId: observable,
      assignee: observable,
      stageId: observable,
      stage: observable,
      assets: observable,
      tasks: observable,
      productionNotes: observable,
      storyboardId: observable,
      storyboard: observable,
      update: action,
      completedTask: computed,
      activeTasksCount: computed,
      shots: observable,
      locations: computed,
      locationsWithMetaData: computed,
    });
  }

  get done(): boolean {
    return this.status === 'approved';
  }

  set done(isDone: boolean) {
    if (isDone) {
      this.status = 'approved';
    }
  }

  get completedTask(): number {
    return this.tasks.reduce((a, task) => (task.status === 'done' ? a + 1 : a), 0);
  }

  get activeTasksCount(): number {
    return this.tasks.reduce((value, task) => (task.status !== 'canceled' ? value + 1 : value), 0);
  }

  get displayName() {
    if (this.type === 'shootingDay') {
      return `(#${this.order}) ${this.name}`;
    }
    if (this.type === 'storyboard' && this.storyboard && !this.name) {
      return `Storyboard: ${this.storyboard.name}`;
    }
    return this.name;
  }

  set displayName(_: string) {}

  static getOne(id: string): Step | undefined {
    return Model.getOne(id) as Step;
  }

  static getAll(): Step[] {
    return Model.getAll().filter((el) => el instanceof Step) as Step[];
  }

  static async createShootingDay({
    projectId,
    dueDate,
  }: {
    projectId: string;
    dueDate?: string;
  }): Promise<Step | undefined> {
    try {
      const { data } = await api.post(`/projects/${projectId}/shooting-days`, {
        dueDate,
      });

      const { progress, strip, ...shootingDayData } = data;

      const shootingDay = Object.assign(new Step(), shootingDayData);
      entityPool.insert(shootingDay);

      const project = Project.getOne(projectId);
      const stripboard = project?.stripboard;

      if (stripboard) {
        stripboard.addStrip(strip);
      }

      const stage = Stage.getOne(shootingDayData.stageId)!;

      stage?.reorderShootingDays();
      stripboard?.reorderShootingDaysStrips(stage!.steps!);

      if (project) {
        project.progress = progress;
      }

      return shootingDay;
    } catch (e) {
      catchError(e);
    }
  }

  get locations() {
    if (this.type !== 'shootingDay') return [];
    const locations: Location[] = [];
    for (const shot of this.shots) {
      if (shot.location && !locations.some((loc) => loc._id === shot.location._id)) {
        locations.push(shot.location);
      }
    }
    return locations;
  }

  get locationsWithMetaData() {
    const metaData: { [key: string]: string | undefined } = {};

    this.locationsMetaData?.forEach((meta) => {
      const { locationId, parking } = meta;
      metaData[locationId] = parking;
    });

    return this.locations.map((location) => {
      return Object.assign(new Location(), location, { parking: metaData[location._id] });
    });
  }

  async updateWeatherDetails() {
    if (this.type !== 'shootingDay') return;

    if (!this.locations.length) {
      toastPool.insert(new Toast('No locations found for this step.', 'error'));
      return;
    }

    let foundAddress = false;

    for (const location of this.locations) {
      if (location.address && location.address.city && location.address.country) {
        foundAddress = true;
        break;
      }
    }

    if (!foundAddress) {
      toastPool.insert(
        new Toast('No location with a city and country found for this step.', 'error'),
      );
      return;
    }

    // check if date is set
    if (!this.startDate) {
      toastPool.insert(new Toast('No date found for this step.', 'error'));
      return;
    }

    // Convert dates to DateTime objects for comparison
    const startDate = DateTime.fromISO(this.startDate);
    const now = DateTime.now().startOf('day');
    const maxFutureDate = now.plus({ days: 8 });

    // Check if the date is before today
    if (startDate < now) {
      toastPool.insert(new Toast('Date cannot be in the past.', 'error'));
      return;
    }

    // Check if the date is more than 16 days in the future
    if (startDate > maxFutureDate) {
      toastPool.insert(
        new Toast('Cannot get weather for dates more than 8 days in the future.', 'error'),
      );
      return;
    }

    try {
      const { data } = await api.patch(`/steps/${this._id}/update-weather-details`);
      this.info = data.info;
    } catch (e: any) {
      if (e.response?.data?.message) {
        toastPool.insert(new Toast(e.response.data.message, 'error'));
      }
    }
  }

  async update(values: Partial<Omit<this, '_id'>>) {
    if (values.startDate) {
      const startDate = DateTime.fromISO(values.startDate).toUTC();
      const normalizedDate = startDate.set({ hour: 12, minute: 0 });

      values.startDate = normalizedDate.toISO() || undefined;
    }

    if (values.dueDate) {
      const dueDate = DateTime.fromISO(values.dueDate).toUTC();
      const normalizedDate = dueDate.set({ hour: 12, minute: 0 });

      values.dueDate = normalizedDate.toISO() || undefined;
    }

    const data = await super.update(values);

    if (values.contactsMetaData) {
      this.contactsMetaData = data.contactsMetaData;
    }

    if (values.locationsMetaData) {
      this.locationsMetaData = data.locationsMetaData;
    }

    if (values.status) {
      this.status = data.status;
    }

    if (values.assigneeId === null) {
      this.assignee = null;
    } else {
      this.assignee = (Member.getOne(values.assigneeId!) as Member) || null;
    }

    const project = Project.getOne(this.projectId);

    if (values.startDate !== undefined && this.type === 'shootingDay') {
      const stage = Stage.getOne(this.stageId);
      if (stage) {
        stage.reorderShootingDays();
      }

      if (project?.stripboard) {
        project.stripboard.reorderShootingDaysStrips(stage!.steps!);
      }
    }

    if (values.info?.generalCallTime) {
      const newCallTime = values.info.generalCallTime;

      if (project?.stripboard) {
        const dayIndex = project.stripboard.strips.findIndex((el) => el.data.stepId === this._id);

        if (!project.stripboard.strips[dayIndex].startTime) {
          project.stripboard.strips[dayIndex].startTime = {};
        }

        project.stripboard.strips[dayIndex].startTime.hour = newCallTime.hour;
        project.stripboard.strips[dayIndex].startTime.minute = newCallTime.minute;

        project.stripboard.updateSchedule();
      }
    }
  }

  async toggleHideContact(contactId: string) {
    const contact = this.contactsMetaData?.find((cont) => cont.contactId === contactId);
    const contactMeta = { contactId, isHidden: !contact?.isHidden };
    await this.update({ contactsMetaData: contactMeta } as any);
  }

  async delete() {
    try {
      const { data } = await api.delete(`/steps/${this._id}`);

      const project = Project.getOne(this.projectId);
      const stripboard = project?.stripboard;

      if (stripboard) {
        stripboard.removeShootingDayStrips(this);
      }

      const stage = Stage.getOne(this.stageId);
      if (stage) {
        stage.steps = stage.steps.filter((step) => step?._id !== this._id);
        stage.reorderShootingDays();
      }

      if (project) {
        project.progress = data.progress;
      }

      entityPool.delete(this);
    } catch (e) {
      catchError(e);
    }
  }

  toPOJO(): Record<string, any> {
    return {
      _id: this._id,
      name: this.name,
      type: this.type,
      projectId: this.projectId,
      status: this.status,
      dueDate: this.dueDate,
      startDate: this.startDate,
      validations: this.validations,
      phase: this.phase,
      current: this.current,
      order: this.order,
      data: this.data,
      info: this.info,
      productionNotes: this.productionNotes,
      contactsMetaData: this.contactsMetaData,
      locationsMetaData: this.locationsMetaData,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}

type StepInfo = {
  generalNote?: string;
  teamId?: string;

  generalCallTime?: {
    hour: number;
    minute: number;
  };

  weather?: {
    temp: {
      min: string;

      max: string;
    };

    sunrise: string;

    sunset: string;

    description: string;
  };

  hospital?: {
    name: string;
    phone: string;
    address: Address;
  };

  police?: {
    name: string;
    phone: string;
    address: Address;
  };
};

export type StepData = {
  assetId?: string;
  _id: string;
  name?: string;
  directorId?: string;
  producerId?: string;
  lineProducerId?: string;
  order?: number;
  directorsNote?: {
    createdAt: Date;
    text: string;
  };
  cast?: {
    _id: string;
    name: string;
    talents: string[];
  };
  crew?: {
    _id: string;
    name: string;
    talents: string[];
  };
  meta?: {
    status?: string;
    presentation?: {
      authorId: string;
      timestamp: Date;
      likes: string[];
      presentation: string;
    };
    tags: [
      {
        name: string;
        type: string;
      },
    ];
  };
};
