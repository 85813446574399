import React from 'react';
import { observer } from 'mobx-react-lite';
import { ClipboardCopyIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { SingleSelectDropdown } from '../../../components/select-dropdown';
import { Project } from '../../../app/entities/project';
import { InfoTextLine, PlaceholderText, TaskTrigger } from './styled-tasks-components';

type TaskStepSelectorProps = {
  // Selected project or predefined project
  project?: Project;
  onChange: (value: string) => void;
  selectedStepId: string | undefined;
  isViewOnly?: boolean;
  withIcon?: boolean;
  placeholder?: string;
  triggerVariant?: 'default' | 'minimal';
};

export const TaskStepSelector: React.FC<TaskStepSelectorProps> = observer(
  ({
    project,
    onChange,
    selectedStepId,
    withIcon,
    placeholder = 'Select step',
    isViewOnly,
    triggerVariant = 'default',
  }) => {
    const options: React.ComponentProps<typeof SingleSelectDropdown>['groups'] = project
      ? project.stages
          ?.map((stage) => ({
            title: stage.name,
            items: stage.steps.map((step) => ({
              label: step.displayName,
              value: step._id,
            })),
          }))
          ?.filter((el) => el.items.length)
      : [];

    const handleStepChange = (value?: string) => {
      onChange(value || '');
    };

    return (
      <Container>
        <SingleSelectDropdown
          trigger={(label) => (
            <TaskTrigger
              data-placeholder={!label}
              data-disabled={isViewOnly}
              tabIndex={0}
              data-variant={triggerVariant}
            >
              {label ? (
                <StepLabel>
                  {withIcon && (
                    <ClipboardCopyIcon
                      style={{
                        color: isViewOnly
                          ? 'var(--color-grayscale-shuttle)'
                          : 'var(--color-texts-high-contrast)',
                      }}
                    />
                  )}

                  <InfoTextLine tabIndex={0}>{label}</InfoTextLine>
                </StepLabel>
              ) : (
                <>
                  <ClipboardCopyIcon />

                  <PlaceholderText>{placeholder}</PlaceholderText>
                </>
              )}
            </TaskTrigger>
          )}
          title="Step"
          value={selectedStepId}
          groups={options}
          placeholder="Search"
          onValueChange={handleStepChange}
          disabled={isViewOnly || !project}
          align={triggerVariant === 'minimal' ? 'end' : 'start'}
        />
      </Container>
    );
  },
);

const Container = styled.div`
  height: 3.2rem;
  height: 2.4rem;
  width: fit-content;
`;

const StepLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;
