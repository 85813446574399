import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@radix-ui/react-icons';
import * as yup from 'yup';

import { StatusSelector } from '../../process/components/status-selector';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { DropdownTrigger } from '../../projects/components/styled-project-components';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { PrioritySelector } from '../../process/components/priority-selector';
import { TaskStepSelector } from './task-step-selector';
import { TaskDateSelector } from './task-date-selector';
import { InlineEditableText } from '../../../components/editable-text/inline-editable-text';
import { authStore } from '../../../core/stores/auth-store';
import { useCurrentMember } from '../../../hooks/use-current-member';
import { useCurrentProject } from '../../../hooks/use-current-project';
import { Strip, Left, Wrapper } from './styled-tasks-components';
import { Task, TaskStatus } from '../../../app/entities/task';
import { differenceInCalendarDays } from 'date-fns';
import { TaskProjectSelector } from './task-project-selector';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { Project } from '../../../app/entities/project';
import { AssigneeSelector } from '../../../components/assignee-selector/assignee-selector';
import { TagsViewer } from './tags-viewer';
import { openModal } from '../../../core/modal/open-modal';

type TaskItemProps = {
  task: Task;
};

export const TaskItem: React.FC<TaskItemProps> = observer(({ task }) => {
  const currentMember = useCurrentMember();
  const currentProject = useCurrentProject();
  const taskProject = task.projectId ? Project.getOne(task.projectId) : undefined;

  const dueDateStatus = useRef<'warning' | 'danger'>();

  const isViewOnly =
    !currentMember || (currentMember.role === 'guest' && currentMember._id !== task.assigneeId);

  useEffect(() => {
    if (!task.dueDate || [TaskStatus.Done, TaskStatus.Canceled].includes(task.status)) {
      return;
    }

    if (task.dueDate) {
      const today = new Date();
      const dueDate = new Date(task.dueDate);

      const dateDifference = differenceInCalendarDays(dueDate, today);

      if (dateDifference <= 0) {
        dueDateStatus.current = 'danger';
      } else if (dateDifference < 4) {
        dueDateStatus.current = 'warning';
      }
    }
  }, [task.dueDate, task.status]);

  const handleOpenDelete = async () => {
    if (isViewOnly) {
      return authStore.setAuthorized(false);
    }

    openModal(DeleteDialog, {
      title: 'Delete task',
      text: `The task will be deleted completely from the project.`,
      onSubmit: () => {
        task.delete();
      },
    });
  };

  const menuItems: React.ComponentProps<typeof DropdownMenu>['items'] = [
    {
      title: 'Delete task',
      icon: <TrashIcon />,
      type: 'danger' as const,
      onSelect: handleOpenDelete,
    },
  ];

  const titleValidatorSchema = yup.string().max(128, '');

  return (
    <Strip>
      <Wrapper data-full-compact="true">
        <Left>
          <StatusSelector
            isViewOnly={isViewOnly}
            disableTooltip={isViewOnly}
            status={task.status}
            onChange={(status) => task.update({ status })}
            statusMap={Task.StatusMapper}
          />

          <PrioritySelector
            isViewOnly={isViewOnly}
            priority={task.priority}
            onChange={(priority) => task.update({ priority })}
          />

          <AssigneeSelector
            isViewOnly={isViewOnly}
            project={taskProject}
            selectedMemberId={task.assigneeId}
            onChange={(assigneeId) => {
              task.update({
                assigneeId: assigneeId || null,
                projectId: task.projectId,
              });
            }}
            iconOnly
          />
        </Left>
      </Wrapper>

      <span style={{ display: 'flex', flexShrink: '1', flexGrow: '1', minWidth: 0 }}>
        <InlineEditableText
          disabled={isViewOnly}
          maxLength={129}
          validationType="onChange"
          schema={titleValidatorSchema}
          value={task.title}
          onSubmit={(title) => task.update({ title: title || 'No task title' })}
          isBorderless
        />
      </span>

      <TagsViewer targetId={task._id} targetType="task" overflow="collapse" alignment="end" />

      {!currentProject && (
        <Wrapper>
          <TaskProjectSelector
            triggerVariant="minimal"
            onChange={(projectId) => task.update({ projectId, stepId: null })}
            selectedProjectId={task.projectId}
            isViewOnly={isViewOnly}
          />
        </Wrapper>
      )}

      <Wrapper>
        <Tooltip text="Select project first" disabled={isViewOnly || !!task.projectId}>
          <div>
            <TaskStepSelector
              triggerVariant="minimal"
              isViewOnly={isViewOnly || !task.projectId}
              project={taskProject}
              onChange={(stepId) => task.update({ stepId, projectId: task.projectId })}
              selectedStepId={task.stepId || undefined}
            />
          </div>
        </Tooltip>
      </Wrapper>

      <Wrapper>
        <TaskDateSelector
          isViewOnly={isViewOnly}
          date={task.dueDate}
          onUpdate={(date) => task.update({ dueDate: date })}
          status={task.status}
        />
      </Wrapper>

      <Wrapper>
        <DropdownMenu
          items={menuItems}
          trigger={<DropdownTrigger data-size="small">⋮</DropdownTrigger>}
        />
      </Wrapper>
    </Strip>
  );
});
