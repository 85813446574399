import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { CollaboratorAvatar } from '../../../components/collaborators-list/CollaboratorAvatar';
import { Close } from '../../../components/icons/Close';
import { BaseDialog } from '../../../components/dialog';
import { MemberSelect } from '../components/member-select';
import { GroupAdd } from '../../../components/icons';
import { Note } from '../../../components/note/note';
import { ElevatedButton } from 'components/buttons';
import { breakPoint } from '../../../app/theme';
import { ModalHeader } from '../../../components/form-builder-modal/modal-header';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { Space } from '../../../app/entities/space';
import { RoleDropdown } from '../components/role-dropdown';
import { toastPool } from '../../toasts/models/toast-pool';
import { Toast } from '../../toasts/models/toast';
import { catchError } from '../../../core/catch-error';

import type { RoleTypes } from '../components/role-dropdown';
import type { Member } from '../../../app/entities/member';
import type { Project } from '../../../app/entities/project';

interface ProjectCollaboratorModalProps {
  project: Project;
  onCancel: () => void;
  onSubmit: (email: string, role?: RoleTypes) => void;
  collaborators: Member[];
  isOpenProps?: boolean;
  spaceId: string;
  serverError?: string;
  setServerError?: React.Dispatch<React.SetStateAction<string | undefined>>;
  currentMemberRole: string;
}

export const ProjectCollaboratorModal: React.FC<ProjectCollaboratorModalProps> = observer(
  ({
    project,
    onSubmit,
    onCancel,
    collaborators,
    spaceId,
    serverError,
    setServerError,
    currentMemberRole,
  }) => {
    const space = Space.getOne(spaceId)!;

    const [hasValue, setHasValue] = useState<boolean>(false);
    const [actionDisabled, setActionDisabled] = useState<boolean>(false);
    const [hint, setHint] = useState<string>();

    const dropdownWidth = React.useRef<number>(0);

    const handleRoleChange = (role?: RoleTypes) => {
      if (role === 'creator') {
        const limit = space.seats?.limit || 1;
        const current = space.seats?.current || 1;

        if (current < limit) {
          return;
        }

        if (space.subscription?.subscriptionId && !space.isSelfServing) {
          setHint('All seats taken. Reach out to customer support to extend your plan.');
          setActionDisabled(true);
        } else if (space.isSelfServing) {
          setHint("You'll be charged on adding a billable creator.");
        }
      } else {
        setHint(undefined);
        setActionDisabled(false);
      }
    };

    const promoteCollaborator = async (collaborator: Member, role: RoleTypes = 'guest') => {
      if (collaborator.role === role || role === 'guest') {
        return;
      }

      if (role === 'creator') {
        try {
          await collaborator.promote();
          toastPool.insert(
            new Toast(
              `${collaborator.fullName} successfully added as an additional creator. Billing information sent via email.`,
              'success',
            ),
          );
        } catch (error) {
          catchError(error);
        }
      }
    };

    const listContainerCallbackRef = (node: HTMLDivElement) => {
      if (!node) {
        return;
      }
      dropdownWidth.current = node.clientWidth - 72;
    };

    return (
      <BaseDialog onClose={onCancel} mobilePosition="bottom" hideClose>
        <Header
          title="Manage people in project"
          action="Invite"
          onAction={() => {
            const submitButton = document.getElementById('invite-project-collaborators-button');
            if (submitButton) submitButton.click();
            else onCancel();
          }}
          onClose={onCancel}
          actionDisabled={actionDisabled || !hasValue}
        />

        <Content>
          {hint && <Note isFullWidth>{hint}</Note>}

          {serverError ? (
            <ServerError>
              <Note variant="danger" isFullWidth>
                {serverError}
              </Note>

              {setServerError && (
                <ElevatedButton text="Try again" onClick={() => setServerError(undefined)} />
              )}
            </ServerError>
          ) : (
            <MemberSelect
              space={space}
              collaborators={collaborators}
              actionDisabled={actionDisabled}
              onSubmit={onSubmit}
              onChange={(value) => {
                if (!value) {
                  setHasValue(false);
                } else {
                  setHasValue(true);
                }
              }}
              onRoleChange={handleRoleChange}
            />
          )}
        </Content>

        {collaborators?.length ? (
          <List ref={listContainerCallbackRef}>
            {collaborators.map((collaborator) => {
              const { email, _id, fullName } = collaborator;

              return (
                <CollaboratorItem key={_id}>
                  <Left>
                    <CollaboratorAvatar collaborator={collaborator} theme="dark" />

                    <MemberInfo>
                      <span>{fullName}</span>
                      <span>{email}</span>
                    </MemberInfo>
                  </Left>

                  <Right>
                    {collaborator.role === 'guest' ? (
                      <RoleDropdown
                        trigger={(label) => (
                          <Trigger>
                            <Tooltip text="Promote to creator">
                              <TooltipChild>
                                <RoleLabel>{label}</RoleLabel>

                                <ChevronDownIcon color="var(--color-grayscale-shuttle)" />
                              </TooltipChild>
                            </Tooltip>
                          </Trigger>
                        )}
                        space={space}
                        role={collaborator.role}
                        width={dropdownWidth.current}
                        onChange={(role) => promoteCollaborator(collaborator, role)}
                      />
                    ) : (
                      <RoleLabel>{collaborator.role || 'guest'}</RoleLabel>
                    )}

                    {['owner', 'admin'].includes(currentMemberRole) && (
                      <Tooltip text="Remove from project">
                        <CloseButton onClick={() => project.removeCollaborator(collaborator._id)}>
                          <Close fill="var(--color-grayscale-light-slate)" />
                        </CloseButton>
                      </Tooltip>
                    )}
                  </Right>
                </CollaboratorItem>
              );
            })}
          </List>
        ) : (
          <CollaboratorsPlaceholder>
            <GroupAdd />

            <span>No people in this project yet.</span>
          </CollaboratorsPlaceholder>
        )}
      </BaseDialog>
    );
  },
);

const Right = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
`;

const CloseButton = styled.button`
  all: unset;
  cursor: pointer;
  border-radius: 0.4rem;
  display: flex;
  height: 2.4rem;
  width: 2.4rem;
  justify-content: center;
  align-items: center;

  svg {
    width: 0.8rem;
    height: 0.8rem;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-grayscale-arsenic);
    & svg,
    & svg > * {
      fill: var(--color-grayscale-white);
    }
  }
`;

const CollaboratorItem = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
`;

const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;

  span:first-child {
    color: var(--color-grayscale-white, #fafafa);
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
  }

  span:last-child {
    color: var(---color-grayscale-light-slate, #7a8296);
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4rem;
  }
`;

const List = styled.div`
  border-top: 0.1rem solid var(--color-grayscale-tuna);
  padding: 2.4rem 1.6rem;
  margin-top: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  @media screen and (min-width: ${breakPoint.medium}px) {
    height: 26.4rem;
  }
`;

const CollaboratorsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: center;
  align-items: center;
  height: 26.4rem;

  margin-top: 1.6rem;
  padding: 2.4rem 1.6rem;
  border-top: 0.1rem solid var(--color-grayscale-tuna);

  & > span {
    color: var(--color-grayscale-shuttle, #626878);
    text-align: center;
    font-size: 1.6rem;
    font-weight: 400;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.6rem 1.6rem 0;
  cursor: default;
  gap: 1.6rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    max-width: min(48rem, 80vw);
    min-width: min(48rem, 80vw);
  }
`;

const ServerError = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: center;
  justify-content: center;
  padding: 2.4rem 0;
`;

const Header = styled(ModalHeader)`
  margin-bottom: 0;
  border-bottom: 0.1rem solid var(--color-grayscale-tuna, #31343c);
  padding: 1.6rem;

  & > h1 {
    font-size: 1.4rem;
    font-weight: 500;
  }
`;

const RoleLabel = styled.div`
  color: var(--color-grayscale-white, #fafafa);
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: 400;
  user-select: none;
`;

const Trigger = styled.button`
  all: unset;
  user-select: none;
  display: flex;
  cursor: pointer;

  & + *[data-radix-popper-content-wrapper] {
    & > [data-radix-menu-content] {
      backdrop-filter: unset;
      background: var(--color-grayscale-tuna, #31343c);
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      border-radius: var(--border-radius-small);
    }
  }
`;

const TooltipChild = styled.div`
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
`;
