import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { PlusIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { IconButton } from '../../../components/buttons';
import { Task, TaskLevel } from '../../../app/entities/task';
import { SaveTaskModal } from './save-task.modal';
import { Tooltip } from 'components/tooltip/Tooltip';
import { useCurrentMember } from '../../../hooks/use-current-member';
import { TaskItem } from './task-item';
import { Strip, Left, Wrapper, HeaderStrip } from './styled-tasks-components';
import { authStore } from '../../../core/stores/auth-store';

type TaskCollectionProps = {
  title: string;
  value: string;
  tasks: Task[];
  defaultValue: object;
  taskLevel: TaskLevel;
  getIcon?: (value: any) => React.ReactElement | null;
};

export const TaskCollection: React.FC<TaskCollectionProps> = observer(
  ({ title, value, tasks, defaultValue, taskLevel, getIcon }) => {
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

    const currentMember = useCurrentMember();

    const isViewOnly = !currentMember || currentMember.role === 'guest';

    const handleOpenCreate = async () => {
      if (isViewOnly) {
        return authStore.setAuthorized(false);
      }

      setCreateModalIsOpen(true);
    };

    return (
      <>
        <HeaderStrip>
          <Left>
            {getIcon && getIcon(value)}

            <StatusTitle>{title}</StatusTitle>

            <Count>{tasks.length}</Count>
          </Left>

          <Tooltip text="Create task">
            <Wrapper>
              <IconButton variant="x-dark" icon={<PlusIcon />} onClick={handleOpenCreate} />
            </Wrapper>
          </Tooltip>
        </HeaderStrip>

        {tasks
          .sort((a: any, b: any) => (a.priority > b.priority ? -1 : 1))
          .map((task) => (
            <TaskItem key={task._id} task={task} />
          ))}

        {createModalIsOpen && (
          <SaveTaskModal
            defaultValues={defaultValue}
            onClose={() => setCreateModalIsOpen(false)}
            title="Create task"
            project={['step', 'project'].includes(taskLevel) ? tasks[0]?.project : undefined}
            step={taskLevel === 'step' ? tasks[0]?.step : undefined}
          />
        )}
      </>
    );
  },
);

const StatusTitle = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-texts-high-contrast);
  user-select: none;
  margin-inline-end: 0.8rem;
`;

const Count = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-texts-low-contrast);
  user-select: none;
`;
