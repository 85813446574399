import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Header } from '../../../routes/project/project.page';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb';

interface SettingScreenProps extends React.PropsWithChildren {
  title: string;
  className?: string;
  actions?: ReactElement | ReactElement[];
  onBack?: () => void;
}

export const SettingScreenLayout: React.FC<SettingScreenProps> = ({
  children,
  title,
  className,
  actions,
  onBack,
}) => {
  return (
    <Wrapper className={className}>
      <Header>
        {!onBack ? (
          <div>{title}</div>
        ) : (
          <Breadcrumb
            breadcrumbs={[
              {
                href: '../billings',
                label: 'Billing',
              },
              {
                href: '',
                label: 'Billing history',
              },
            ]}
          />
        )}
        {actions}
      </Header>

      <Screen>{children}</Screen>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: start;
`;

const Screen = styled.div`
  min-height: 0;
  width: 100%;
  height: 100%;
`;
