import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { ScreenHeader } from '../../projects/components/screen-header';
import { CommonFileBreadcrumb } from '../components/common-file-breadcrumb';
import { AssetViewerScreen } from './asset-viewer.screen';

import { Asset } from '../../../app/entities/asset';

import type { Step } from '../../../app/entities/step';
import { FolderView } from '../components/folder-view';
import { ScreenContainer } from '../../projects/components/styled-project-components';

type CommonFileScreenProps = {
  step: Step;
  spaceId: string;
};

export const CommonFileScreen: React.FC<CommonFileScreenProps> = observer(({ step, spaceId }) => {
  const { assetId } = useParams();

  const asset = Asset.getOne(assetId!);

  if (!asset) {
    return <div />; // TODO: handle display file not found placeholder
  }

  return (
    <React.Fragment>
      <ScreenHeader
        breadcrumb={<CommonFileBreadcrumb step={step} assetId={assetId!} />}
        stepId={step._id}
        title={step.name}
        dueDate={step.dueDate}
        startDate={step.startDate}
        handleUpdateTimeFrame={(values) => step.update(values)}
        canValidateStep={false}
      />

      <Content>
        {asset.type === 'folder' ? (
          <FolderView stepId={step._id} folderId={asset._id} />
        ) : (
          <AssetViewerScreen asset={asset} />
        )}
      </Content>
    </React.Fragment>
  );
});

const Content = styled(ScreenContainer)`
  max-height: calc(100% - 4rem);
`;
