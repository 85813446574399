import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { TextInput } from '../../../components/text-input/TextInput';
import { HasServerResponse } from '../../../components/PanelContainer';
import { Alert } from '../../../components/alert/Alert';
import { ElevatedButton, TextButton } from '../../../components/buttons';
import { BaseDialog, DialogHeader, DialogFooter } from '../../../components/dialog';
import { Tag, TagColor, TagTarget } from '../../../app/entities/tag';

type CreateTagFromValues = {
  text: string;
  color: TagColor;
};

type CreateTagModalProps = {
  target: TagTarget;
  onCancel: () => void;
  onFinish?: (values: any) => void;
};

export const CreateTagModal: React.FC<CreateTagModalProps> = ({ onFinish, onCancel, target }) => {
  const { t } = useTranslation('video');
  const [serverError, setServerError] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const schema = useMemo(
    () =>
      yup.object({
        text: yup
          .string()
          .matches(/^(.*)?\S+(.*)?$/, t('common:fieldRequired'))
          .required(t('common:fieldRequired'))
          .max(24, t('common:fieldMax', { max: 24 })),
      }),
    [t],
  );

  const { register, handleSubmit, control, getValues, formState } = useForm<CreateTagFromValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      text: '',
      color: TagColor.Blue,
    },
  });

  const createTag = async (values: CreateTagFromValues) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const payload = { ...values, target };

    try {
      if (onFinish) {
        await onFinish(payload);
      } else {
        await Tag.create(payload);
      }
      onCancel();
    } catch (e) {
      setServerError(e);
    }

    setIsLoading(false);
  };

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title={'Add tag'} onClose={onCancel} />

      <Content>
        {serverError && serverError.response && (
          <HasServerResponse>
            <Alert text={serverError.response.data.message} />
          </HasServerResponse>
        )}

        <form onSubmit={handleSubmit(createTag)} id="add-tag-form">
          <TextInput
            label={'Text'}
            errorMessage={formState.errors.text?.message}
            placeholder={'Add a tag text'}
            {...register('text', { required: true })}
            required
          />

          <ColorContainer>
            <Label>{'Tag color'}</Label>

            <Controller
              name={'color'}
              control={control}
              render={({ field }) => (
                <ColorCubes>
                  {Object.values(TagColor).map((color) => (
                    <ColorCube
                      key={color}
                      style={{ backgroundColor: color }}
                      selected={getValues('color') === color}
                      onClick={() => field.onChange(color)}
                    />
                  ))}
                </ColorCubes>
              )}
            />
          </ColorContainer>
        </form>
      </Content>

      <DialogFooter
        actions={[
          <TextButton key="cancel" text={t('common:cancel')} onClick={onCancel} />,
          <ElevatedButton
            key="submit"
            text={t('common:submit')}
            isLoading={isLoading}
            type="submit"
            form="add-tag-form"
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Content = styled.div`
  padding: 1.6rem;
`;

const ColorContainer = styled.div`
  margin-top: 1.75rem;
`;

const Label = styled.label`
  display: block;
  font-family: Inter, sans-serif;
  font-weight: 600;
  color: white;
  letter-spacing: 0.7px;
  line-height: 1.1rem;
  margin-bottom: 1.6rem;
`;

interface ColorCubeProps {
  type?: string;
  selected?: boolean;
}

const ColorCubes = styled.div<ColorCubeProps>`
  display: flex;

  &:last-of-type {
    margin-right: 0;
  }
`;

const ColorCube = styled.div<ColorCubeProps>`
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 0.4rem;
  border: ${({ selected }) => (selected ? 'solid 2px #FFFFFF' : 'solid 2px transparent')};
  margin-right: 1.25rem;
  cursor: pointer;

  &:last-of-type {
    margin-right: 0;
  }
`;
