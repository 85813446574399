import React, { ReactNode, useEffect, useState } from 'react';
import { CheckIcon } from '@radix-ui/react-icons';
import * as RadixMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';
import {
  ListItem,
  CheckboxItem as CheckboxMenuItem,
  OptionLabel,
} from '../select-dropdown/content-menu/styled-content-menu';

import { Tooltip } from '../tooltip/Tooltip';
import { breakPoint } from '../../app/theme';

type Item = {
  menuKey?: string;
  onSelect: (event: Event) => void;
  title?: ReactNode;
  icon?: ReactNode;
  type?: 'danger' | 'warning';
  disabled?: boolean;
  hidden?: boolean;
  tooltipText?: string;
  subItems?: DrilldownMenuProps['items'];
  isChecked?: never;
  onCheckedChange?: never;
  customComponent?: React.ReactElement;
};

type CheckboxItem = Omit<Item, 'onSelect' | 'isChecked' | 'onCheckedChange'> & {
  isChecked?: boolean;
  onCheckedChange?: (isChecked: boolean) => void;
  onSelect?: (event: Event) => void;
};

type DrilldownMenuProps = {
  items: Array<Item | CheckboxItem>;
  align?: 'start' | 'end';
  size?: 'small' | 'medium' | 'large' | 'free-size';
  trigger?: ReactNode;
  itemComponent?: React.ReactElement;
  tooltipText?: string;
  modal?: boolean;
  onOpenChange?: (value: boolean) => void;
  portalContainer?: HTMLDivElement;
  side?: 'bottom' | 'top';
  alignOffset?: number;
  className?: string;
  disabled?: boolean;
  defaultMenuPath: number[];
};

export const DrilldownMenu: React.FC<DrilldownMenuProps> = ({
  items,
  size,
  trigger,
  itemComponent: optionComponent,
  align = 'end',
  alignOffset,
  modal = false,
  onOpenChange,
  portalContainer,
  side,
  className,
  disabled,
  defaultMenuPath,
}) => {
  const [currentOptions, setCurrentOptions] = useState(items);
  const [menuPath, setMenuPath] = useState<number[]>(defaultMenuPath);

  const hasIcon = currentOptions.some((item) => !!item.icon || !!item.onCheckedChange);
  const hasIconAndCheck = currentOptions.some((item) => !!item.icon && !!item.onCheckedChange);

  useEffect(() => {
    if (menuPath.length === 0) {
      setCurrentOptions(items);
    } else {
      const newOptions = menuPath.reduce((acc, index) => {
        return acc[index]?.subItems || [];
      }, items);

      setCurrentOptions(newOptions);
    }
  }, [items, menuPath]);

  useEffect(() => {
    setMenuPath(defaultMenuPath);
  }, [defaultMenuPath]);

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setMenuPath([]);
    }

    onOpenChange?.(isOpen);
  };

  const renderItem = (item: Item | CheckboxItem, index: number, hasIcon?: boolean) => {
    if (item.hidden) {
      return;
    }

    if (optionComponent && React.isValidElement(optionComponent)) {
      return React.cloneElement(optionComponent, { ...item });
    }

    if (hasIconAndCheck) {
      return (
        <CheckboxMenuItem
          key={`option-${index}`}
          checked={item.isChecked}
          onCheckedChange={item.onCheckedChange}
          onSelect={item.onSelect}
        >
          <OptionLabel>
            {item.icon}

            <span>{item.title}</span>
          </OptionLabel>

          <RadixMenu.ItemIndicator>
            <CheckIcon />
          </RadixMenu.ItemIndicator>
        </CheckboxMenuItem>
      );
    }

    if (item.onCheckedChange) {
      return (
        <CheckboxMenuItem
          key={`option-${item.title}-${index}`}
          onSelect={item.onSelect}
          onCheckedChange={(isChecked) => {
            item.onCheckedChange?.(isChecked);
          }}
          checked={item.isChecked}
        >
          <OptionLabel>
            {item.icon}

            <span>{item.title}</span>
          </OptionLabel>

          <RadixMenu.ItemIndicator>
            <CheckIcon />
          </RadixMenu.ItemIndicator>
        </CheckboxMenuItem>
      );
    }

    if (item.customComponent) {
      return React.cloneElement(item.customComponent, { ...item });
    }

    return (
      <ListItem
        key={`option-${index}`}
        id={`option-${index}`}
        disabled={item.disabled}
        onSelect={(e) => {
          if (item.subItems?.length) {
            e.preventDefault();
            setMenuPath((prev) => [...prev, index]);
          }
          item.onSelect?.(e);
        }}
        data-type={item.type}
      >
        <OptionLabel>
          {hasIcon && item.icon}

          <span>{item.title}</span>
        </OptionLabel>
      </ListItem>
    );
  };

  return (
    <RadixMenu.Root modal={modal} onOpenChange={handleOpenChange}>
      <RadixMenu.Trigger onClick={(e) => e.stopPropagation()} disabled={disabled} asChild>
        {trigger || <Trigger data-size={size}>⋮</Trigger>}
      </RadixMenu.Trigger>

      <RadixMenu.Portal {...(portalContainer && { container: portalContainer })}>
        <Content
          side={side || 'bottom'}
          align={align}
          sideOffset={6}
          alignOffset={alignOffset}
          asChild
        >
          <MenuModal
            className={className || ''}
            data-size={size}
            onClick={(e) => e.stopPropagation()}
          >
            {currentOptions.map((item, index) => {
              return (
                <Tooltip
                  text={item.tooltipText || 'Action not allowed'}
                  key={`tip-${index}`}
                  disabled={!item.disabled || !item.tooltipText}
                >
                  {renderItem(item, index, hasIcon)}
                </Tooltip>
              );
            })}
          </MenuModal>
        </Content>
      </RadixMenu.Portal>
    </RadixMenu.Root>
  );
};

const Content = styled(RadixMenu.Content)`
  overflow-y: auto;
  max-height: 32rem;

  @media screen and (max-width: ${breakPoint.small - 1}px) {
    max-width: 100vw;
  }
`;

const Trigger = styled.button`
  all: unset;
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2.8rem;
  font-size: 1.8rem;
  border-radius: 0.4rem;
  transition: 0.1s ease-in background-color;
  user-select: none;
  background-color: var(--color-grayscale-tuna);
  color: var(--color-grayscale-manatee);

  &:hover {
    cursor: pointer;
  }

  &[data-size='large'] {
    width: 3.2rem;
    height: 3.2rem;
  }

  &[data-size='free-size'] {
    width: unset;
    height: unset;
  }
`;

const MenuModal = styled.div`
  opacity: 2;
  min-width: 18rem;
  border-radius: 0.6rem;
  padding: 0.4rem;
  z-index: calc(var(--layer-modal) + 1);
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 24px;
  backdrop-filter: blur(10px) saturate(190%) contrast(70%) brightness(80%);
  background-color: rgba(29, 30, 43, 0.498);

  &[data-size='free-size'] {
    min-width: unset;
  }
`;
