import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CheckIcon } from '@radix-ui/react-icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { OptionsContainer, CheckboxItem, OptionLabel } from './styled-content-menu';
import { ContentProps } from './default-content-menu';

import type { SelectDropdownProps } from '..';

type GroupContentProps = Omit<ContentProps, 'items'> & {
  items: SelectDropdownProps['groups'];
};

//TODO: Implement the GroupContentMenu create flow
export const GroupContentMenu: React.FC<GroupContentProps> = ({ items, searchValue }) => {
  const [options, setOptions] = useState<typeof items>();

  useEffect(() => {
    if (!searchValue) {
      return setOptions(items);
    }

    if (items) {
      const filteredOptions = items
        .map((group) => {
          const filteredItems = group.items.filter((item) =>
            item.title?.toString().toLowerCase().includes(searchValue.toLowerCase()),
          );

          if (filteredItems.length > 0) {
            return { ...group, items: filteredItems };
          }

          return null;
        })
        .filter((group) => group !== null);

      setOptions(filteredOptions);
    }
  }, [items, searchValue]);

  return (
    <OptionsContainer>
      {items?.length !== 0 &&
        options?.map((group, index) => (
          <div key={`${group.title}-${index}`}>
            {group.title && group.items?.length && <GroupTitle>{group.title}</GroupTitle>}

            <DropdownMenu.Group>
              {group.items.map((item, itemIndex) => (
                <CheckboxItem
                  key={`item-${item.title}-${itemIndex}`}
                  checked={item.isChecked}
                  onSelect={item.onSelect}
                  onCheckedChange={(isChecked) => {
                    item.onCheckedChange?.(isChecked);
                  }}
                >
                  <OptionLabel>
                    {item.icon}

                    <span>{item.title}</span>
                  </OptionLabel>

                  {!!item.isChecked && <CheckIcon />}
                </CheckboxItem>
              ))}
            </DropdownMenu.Group>
          </div>
        ))}
    </OptionsContainer>
  );
};

const GroupTitle = styled.div`
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 400;
  padding: 1.2rem 0.8rem 0.8rem;
  color: var(--color-texts-low-contrast);
  cursor: default;
`;
