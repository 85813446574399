import { runInAction } from 'mobx';

export abstract class BaseFilters {
  filtersCount: number;
  tokensMapper: Record<string, string>;
  orderedTokens: string[];

  protected constructor(readonly $name: string) {
    this.filtersCount = 0;
  }

  addToFilter<T>(key: keyof this, item: T) {
    const array = this[key] as T[];

    if (!Array.isArray(array)) {
      throw new Error(`${String(key)} is not an array.`);
    }

    runInAction(() => {
      if (Array.isArray(item)) {
        array.push(...item);
      } else {
        array.push(item);
      }

      this.filtersCount += 1;

      const token = this.tokensMapper[key as string];
      this.addToken(token);
    });
  }

  removeFromFilter<T>(key: keyof this, item: T) {
    const array = this[key];

    if (!Array.isArray(array)) {
      throw new Error(`${String(key)} is not an array.`);
    }

    runInAction(() => {
      if (Array.isArray(item)) {
        for (const prop of item) {
          const index = array.indexOf(prop);
          if (index > -1) {
            array.splice(index, 1);
          }
        }
      } else {
        const index = array.indexOf(item);
        if (index > -1) {
          array.splice(index, 1);
        }
      }

      this.filtersCount -= 1;

      if (array.length === 0) {
        const token = this.tokensMapper[key as string];
        this.removeToken(token);
      }
    });
  }

  emptyFilter(key: keyof this) {
    const array = this[key];

    if (!Array.isArray(array)) {
      throw new Error(`${String(key)} is not an array.`);
    }

    runInAction(() => {
      if (Array.isArray(array)) {
        const filteredArr = [...new Set(array.map((item) => item || ''))];
        this.filtersCount -= filteredArr.length;

        array.splice(0, array.length);

        const token = this.tokensMapper[key as string];

        this.removeToken(token);
      }
    });
  }

  addToken(token?: string) {
    if (!token) {
      return;
    }

    const set = new Set(this.orderedTokens);

    if (!set.has(token)) {
      runInAction(() => {
        this.orderedTokens.push(token);
      });
    }
  }

  removeToken(token: string) {
    if (!token) {
      return;
    }

    const tokenIndex = this.orderedTokens.indexOf(token);

    if (tokenIndex > -1) {
      runInAction(() => {
        this.orderedTokens.splice(tokenIndex, 1);
      });
    }
  }

  clearFilters() {
    runInAction(() => {
      const filterPropKeys = Object.keys(this);

      filterPropKeys.forEach((key) => {
        const prop = this[key as keyof this];

        if (Array.isArray(prop)) {
          prop.splice(0, prop.length);
        }
      });

      this.filtersCount = 0;
    });
  }
}
