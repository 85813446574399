import React from 'react';
import styled from 'styled-components';

import { Point } from './styled-tags-components';
import { TagColor } from '../../../app/entities/tag';
import { SingleSelectDropdown } from '../../../components/select-dropdown';

type ColorPickerProps = {
  colorHex: TagColor;
  onChange: (value: TagColor) => void;
  onCreate?: (value: string) => void;
};

export const ColorPicker = ({ onChange, colorHex, onCreate }: ColorPickerProps) => {
  const options = Object.entries(TagColor).map(([color, colorHex]) => {
    return {
      label: color,
      value: colorHex,
      icon: <Point style={{ marginRight: '0.8rem' }} color={colorHex} />,
    };
  });

  return (
    <SingleSelectDropdown
      trigger={() => (
        <Next>
          <Point color={colorHex} />
        </Next>
      )}
      title="Color picker"
      options={options}
      value={colorHex}
      onValueChange={(colorHex) => onChange(colorHex as TagColor)}
      onCreate={onCreate}
      placeholder="Pick a color for tag"
    />
  );
};

const Next = styled.button`
  display: flex;
  position: relative;
  margin-right: 0.8rem;
  justify-content: center;
  align-items: center;
  width: 1.6rem;
  height: 1.6rem;
  transition: all 0.3s ease;
  border-radius: 0.4rem;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  outline: none;
  background-color: transparent;

  & > div {
    z-index: 10;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
    border-radius: var(--border-radius-small);
    background-color: var(--color-grayscale-tuna);
    border: 1px solid var(--color-grayscale-trout);
    height: calc(100% + 0.4rem);
    width: calc(100% + 0.4rem);
  }

  &[data-isOpen='true']::after {
    display: block;
  }
`;
