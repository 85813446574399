import React from 'react';
import prettyBytes from 'pretty-bytes';
import styled from 'styled-components';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@radix-ui/react-icons';
import { IconButton } from '@producer-io/ui-kit';

import { DeleteAssetDialog } from '../../../components/video-asset/delete-asset.dialog';
import { CircularProgressIndicator } from '../../../components/circular-progress-bar';
import { IconButtonWrapper } from '../../../components/buttons/icon-button';
import { ListViewItem } from '../../../components/list-view/list-view';
import { timeDifference } from '../../../lib/utils/TimeDifference';
import { toastPool } from '../../toasts/models/toast-pool';
import { Toast } from '../../toasts/models/toast';
import { useSelection } from '../../../core/contexts/selection.context';
import { getModifier } from '../../../core/services/event.service';
import { TagsViewer } from '../../tags/components/tags-viewer';
import { AssetMenu } from './asset-menu';
import { useResponsive } from '../../../hooks/useResponsive';
import { useLongTouch } from '../../../app/hooks/use-long-touch';
import folderImage from '../../../assets/images/folder.png';
import { CommentCount } from '../../comments/components/comment-count';
import { openModal } from 'core/modal/open-modal';

import type { Asset } from '../../../app/entities/asset';
import type { User } from '../../../app/entities/user';

interface Props {
  asset: Asset;
  assetsRouteTo?: (asset: string, assetId: string) => void;
  isSelected?: boolean;
  currentUser?: User | null;
}

export const FileListItem: React.FC<Props> = observer(
  ({ assetsRouteTo, asset, isSelected, currentUser }) => {
    const { select, toggleSelectMode, selectionModeOn } = useSelection();
    const { isMobile } = useResponsive();
    const { longTouchRef } = useLongTouch({
      onLongTouch: () => toggleSelectMode(asset._id),
    });

    const handleOpenFile = () => {
      if (asset.type === 'link') {
        const hasValidSchema = new RegExp(/^https?:\/\//).test(asset.url!);
        const url = hasValidSchema ? asset.url : `https://${asset.url}`;

        yup
          .string()
          .url()
          .validate(url)
          .then(() => {
            const newTab = window.open(url, '_blank');
            newTab?.focus();
          })
          .catch(() => {
            toastPool.insert(new Toast('Invalid URL.', 'error'));
          });

        return;
      }

      if (asset.uploaded === false && currentUser?._id !== asset.uploaderId) {
        toastPool.insert(new Toast('File is not uploaded yet.', 'error'));
        return;
      }
      return assetsRouteTo && asset && assetsRouteTo('file', asset._id);
    };

    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();

      if (!isMobile || selectionModeOn) {
        const modifier = getModifier(e.nativeEvent);
        select(asset._id, modifier);
        return;
      }

      handleOpenFile();
    };

    let assetType =
      asset.type === 'link' ? 'Link' : asset.type === 'note' ? 'Note' : asset.extension;

    const assetSize = ['link', 'note', 'folder'].includes(asset.type)
      ? '-'
      : prettyBytes(asset.size || 0);

    const openDeleteModal = () =>
      openModal(DeleteAssetDialog, {
        name: asset.name,
        onSubmit: () => asset.delete(),
      });

    return (
      <Row
        key={asset._id}
        onDoubleClick={handleOpenFile}
        onClick={handleClick}
        data-selected={isSelected}
        ref={longTouchRef}
      >
        <td>{asset.name}</td>
        <td>
          {asset.type === 'folder' ? (
            <FolderImage>
              <img src={folderImage} alt="Folder" />
            </FolderImage>
          ) : (
            assetType
          )}
        </td>
        <td>{assetSize}</td>
        <td>{asset.createdAt && timeDifference(new Date(), asset.createdAt)}</td>
        <td>{!!asset.uploaderId && asset.uploaderName}</td>
        <td>
          {['link', 'folder'].includes(asset.type) ? (
            '-'
          ) : (
            <CommentCount count={asset.commentCount} />
          )}
        </td>
        <td>
          {['link', 'folder'].includes(asset.type)
            ? '-'
            : asset.lastCommentedAt && timeDifference(new Date(), asset.lastCommentedAt)}
        </td>
        <td style={{ paddingRight: '1.6rem' }}>
          {asset.upload ? (
            <UploadingContainer>
              <ListItemCircularProgressIndicator
                controlsAlwaysDisplayed
                progress={asset.upload?.progress || 0}
                status={asset.upload?.status}
                onPause={() => {
                  asset.upload?.pauseUploading();
                }}
                onResume={() => {
                  asset.upload?.resumeUploading();
                }}
              />
              <UploadProgress>{asset.upload?.progress}% uploaded</UploadProgress>
            </UploadingContainer>
          ) : (
            <TagsViewer
              targetId={asset._id}
              targetType="asset"
              overflow="collapse"
              alignment="end"
            />
          )}
        </td>

        <td style={{ paddingRight: '0.8rem', width: '4.8rem' }}>
          <LastCellWrapper>
            {asset.uploaded ||
            asset.type === 'link' ||
            asset.type === 'note' ||
            asset.type === 'folder' ? (
              <AssetMenu asset={asset} onOpen={handleOpenFile} />
            ) : (
              <IconButtonWrapper>
                <DeleteButton icon={<TrashIcon color="#db5c66" />} onClick={openDeleteModal} />
              </IconButtonWrapper>
            )}
          </LastCellWrapper>
        </td>
      </Row>
    );
  },
);

const Row = styled(ListViewItem)`
  &[data-selected='true'] {
    border: 1px solid var(--color-primary-crayola, #367bff);
    background: rgba(54, 123, 255, 0.3);

    & td {
      border-top: 1px solid var(--color-primary-crayola, #367bff);
      border-bottom: 1px solid var(--color-primary-crayola, #367bff);

      &:first-child {
        border-left: 1px solid var(--color-primary-crayola, #367bff);
      }

      &:last-child {
        border-right: 1px solid var(--color-primary-crayola, #367bff);
      }
    }
  }
`;

const LastCellWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
`;

const DeleteButton = styled(IconButton)`
  color: #db5c66;
  svg path {
    fill: #db5c66;
  }
`;

const UploadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  justify-content: flex-end;
`;

const UploadProgress = styled.span`
  color: var(--color-grayscale-manatee, #959bab);
  font-size: 14px;
  font-weight: 400;
`;

const ListItemCircularProgressIndicator = styled(CircularProgressIndicator)`
  height: 3.2rem;
  width: 3.2rem;
`;

const FolderImage = styled.div`
  max-width: 2.5rem;
  max-height: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
