import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Tag, TagTarget } from '../../../app/entities/tag';
import { Model } from '../../../core/engine/model';
import { Point, TagLabel, TAGS_CONTAINER_GAP } from './styled-tags-components';
import { TagSelector } from './tag-selector';
import { useCurrentSpace } from '../../../hooks/use-current-space';

type TagsViewerProps = {
  targetType: TagTarget;
  targetId?: string;
  onSelect?: (tagsIds: string[]) => void;
  tagsIds?: string[];
  onMoreClick?: () => void;
  overflow?: 'wrap' | 'collapse';
  alignment?: 'start' | 'end';
};

type TagTargetEntity = {
  tags: Tag[];
  tagsIds: string[];
  updateTags: (tagsIds: string[]) => Promise<void>;
} & Model;

export const TagsViewer: React.FC<TagsViewerProps> = observer(
  ({ targetId, targetType, onSelect, tagsIds, overflow = 'wrap', alignment = 'start' }) => {
    const targetEntity = targetId ? (Model.getOne(targetId) as TagTargetEntity) : null;
    let tags = targetEntity?.tags || [];

    const selectedTags = tagsIds ? tagsIds : tags.map((tag) => tag._id);

    const space = useCurrentSpace()!;

    if (tagsIds) {
      tags = space.tags.filter((el) => tagsIds.includes(el._id));
    }

    const handleChange = (tags: string[]) => {
      onSelect ? onSelect(tags) : targetEntity?.updateTags(tags);
    };

    return (
      <Container data-align={alignment}>
        <TagsContainer data-wrap={overflow === 'wrap'}>
          {tags.map((tag, index) => (
            <TagSelector
              key={tag._id}
              targetType={targetType}
              selectedTags={selectedTags}
              onChange={handleChange}
              trigger={() => (
                <TagLabel
                  onClick={(e) => e.stopPropagation()}
                  key={tag._id}
                  data-visible={true}
                  color={tag.color}
                >
                  <Point color={tag.color} />
                  {tag.text}
                </TagLabel>
              )}
            />
          ))}

          <TagSelector
            targetType={targetType}
            selectedTags={selectedTags}
            onChange={handleChange}
          />
        </TagsContainer>
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;

  &[data-align='end'] {
    justify-content: flex-end;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${TAGS_CONTAINER_GAP}rem;
  min-width: 0;
  overflow: hidden;

  &[data-wrap='true'] {
    flex-wrap: wrap;
  }
`;
