import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { PageLoader } from '@producer-io/ui-kit';
import { observer } from 'mobx-react-lite';
import { initializePaddle, Paddle } from '@paddle/paddle-js';

import { SettingScreenLayout } from '../../features/settings/components/setting-screen-layout';
import { PlanCard } from '../../features/billing/components/plan-card';
import { api } from '../../api';
import { PlanCycleSwitch } from '../../features/billing/components/billing-and-plans/plan-cycle-switch';
import { currencyFormatter } from '../../lib/utils/currency-formatter';
import { ChangePlanModal } from '../../features/billing/components/billing-and-plans/change-plan.modal';
import { SubscriptionStatus } from '../../app/entities/space';
import { getTransactionDetails } from '../../features/billing/store/billing.slice';
import { globalConfig } from '../../configuration/config';
import { uiStore } from '../../core/stores/ui-store';
import { breakPoint } from '../../app/theme';

import type { LineItem } from '@paddle/paddle-js/types/price-preview/price-preview';
import { useCurrentSpace } from 'hooks/use-current-space';

export type Plan = {
  productId: string;
  name: string;
  label?: string;
  description?: string;
  features: string[];
  disabled?: boolean;
  priceId: string;
  formattedPrice?: string;
  price: string;
  imageUrl?: string;
  interval: 'month' | 'year' | 'both';
  status?: 'currentPlan' | 'downgrade' | 'upgrade' | 'changeInterval';
};

type Plans = {
  month: Plan[];
  year: Plan[];
};

const defaultPlans: Plan[] = [
  {
    productId: 'custom_plan',
    name: 'Custom Machine',
    label: 'Enterprise',
    description: 'Tailored security, control, and support for organization.',
    features: [
      'Everything on Production plus:',
      '24h premium support',
      'Customized storage',
      'Scales to unlimited creators',
      'Custom branding',
      'Unlimited guests',
      'Dedicated support team',
      'Asset lifecycle management',
      'SAML and SCIM (coming soon)',
    ],
    priceId: 'custom_plan',
    price: '',
    formattedPrice: '',
    imageUrl: '/images/plans/custom-machine.png',
    interval: 'both',
  },
];

export const PlansSettingsRoute: React.FC = observer(() => {
  const { spaceId } = useParams() as { spaceId: string };
  const space = useCurrentSpace();

  const [plans, setPlans] = useState<Plans>();
  const [cycle, setCycle] = useState<'month' | 'year'>('year');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSupportModalOpen, setSupportModalOpen] = useState<boolean>();
  const [isSalesModalOpen, setSalesModalOpen] = useState<boolean>();
  const [hint, setHint] = useState(
    'Choose your plan. Simple pricing. No hidden fees. Advanced features for your business.',
  );

  const currency = useRef<string>();
  const paddleInstance = useRef<Paddle>();
  const priceList = useRef<LineItem[]>();

  const paddleToken = globalConfig.config.paddleToken;

  const paddlePriceItems = globalConfig.config.paddlePriceItems.map((item) => {
    return {
      quantity: 1,
      priceId: item,
    };
  });

  useEffect(() => {
    if (space?.subscription?.subscriptionId && !space.isSelfServing) {
      setHint(
        'To make any change to your contract in terms of seats or storage, please contact us at <a href="mailto:sales@the-producer.io">sales@the-producer.io</a>',
      );
    }

    uiStore.showBack = true;
    uiStore.currentLocation = 'space.settings';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  useEffect(() => {
    if (!space?.subscription?.userPriceId || !priceList.current) {
      return;
    }

    const currentPlan = space.hasSubscription()
      ? priceList.current.find((item: any) => {
          return item.price.id === space?.subscription?.userPriceId;
        })
      : null;

    setPlans((plans) => {
      if (!plans) {
        return;
      }

      const newPlans: Plans = { month: [...plans.month], year: [...plans.year] };

      updatePlanStatus(newPlans.month, currentPlan);

      updatePlanStatus(newPlans.year, currentPlan);
      return newPlans;
    });
  }, [space, space?.subscription?.subscriptionId, space?.subscription?.userPriceId]);

  useEffect(() => {
    initializePaddle({
      environment: globalConfig.config.environment === 'production' ? 'production' : 'sandbox',
      token: paddleToken!,
      eventCallback: async (event) => {
        if (event.name === 'checkout.completed') {
          const { customer, items, transaction_id } = event.data || {};

          // The event data does not include any subscription details
          let counter = 0;
          const timerHandler = setInterval(async () => {
            counter++;

            try {
              const transactionDetails = await getTransactionDetails(space!._id, transaction_id!);

              if (transactionDetails?.subscription_id) {
                const subscriptionId = (transactionDetails?.subscription_id as string) || '';
                const customerId = customer?.id || '';
                const businessId = customer?.business?.id || '';
                const userPriceId = items?.[0].price_id;
                const newSubscription = {
                  subscriptionId,
                  userPriceId,
                  businessId,
                  customerId,
                  collectionMode: transactionDetails?.collection_mode,
                  status: SubscriptionStatus.ACTIVE,
                };

                space!.updateSubscription(newSubscription);

                clearInterval(timerHandler);
              }
            } catch {
              clearInterval(timerHandler);
              window.location.reload();
            }

            // If timer goes beyond 5sec, clear it.
            if (counter >= 10) {
              clearInterval(timerHandler);
            }
          }, 500);

          /**
           * Open the thank you page for tracking in another tab
           */
          if (globalConfig.config.checkoutRedirectUrl) {
            window.open(globalConfig.config.checkoutRedirectUrl, '_blank');
          }
        }
      },
    })
      .then((paddle: Paddle | undefined) => {
        if (!paddle) {
          return;
        }
        paddleInstance.current = paddle;

        return paddle.PricePreview({
          items: paddlePriceItems,
        });
      })
      .then((result) => {
        if (!result) {
          return;
        }

        currency.current = result.data.currencyCode;

        const items = result.data.details.lineItems;
        priceList.current = items;

        const initialPlans: Plans = {
          month: [...defaultPlans],
          year: [...defaultPlans],
        };

        for (const item of items) {
          const plan = createPlan(item);
          initialPlans[plan.interval as 'month' | 'year'].push(plan);
        }

        sortPlans(initialPlans.month);
        sortPlans(initialPlans.year);

        const currentPlan =
          space?.hasSubscription() &&
          priceList.current!.find((item: any) => {
            return item.price.id === space?.subscription?.userPriceId;
          });

        updatePlanStatus(initialPlans.month, currentPlan);
        updatePlanStatus(initialPlans.year, currentPlan);

        setPlans(initialPlans);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPlan = (item: any) => {
    const plan: Plan = {
      productId: '',
      name: '',
      price: '',
      priceId: '',
      features: [],
      interval: 'month',
    };

    const currencyCode = currency.current!;
    const featureString = item.product.customData?.features;
    const planFeatures = (featureString as string)
      .split(',')
      .map((feature: string) => feature.trim());

    plan['productId'] = item.product.id;
    plan['name'] = item.product.name;
    plan['label'] = item.product.customData.label;
    plan['description'] = item.product.description || undefined;
    plan['imageUrl'] = item.product.imageUrl || undefined;
    plan['features'] = planFeatures;
    plan['priceId'] = item.price.id;
    plan['price'] = item.price.unitPrice.amount;
    plan['status'] = 'upgrade';
    plan['disabled'] = item.price.customData?.disabled === 'true';
    plan['interval'] = item.price.billingCycle!.interval as 'month' | 'year';

    if (item.price.billingCycle!.interval === 'year') {
      const price = parseFloat(item.price.unitPrice.amount) / 100 / 12;

      plan['formattedPrice'] = currencyFormatter(price, currencyCode);
    } else {
      const price = parseFloat(item.price.unitPrice.amount) / 100;

      plan['formattedPrice'] = currencyFormatter(price, currencyCode);
    }

    return plan;
  };

  const sortPlans = (plans: Plan[]) => {
    return plans.sort((a, b) => {
      const numA = a.price === '' ? Number.MAX_SAFE_INTEGER : parseFloat(a.price);
      const numB = b.price === '' ? Number.MAX_SAFE_INTEGER : parseFloat(b.price);

      return numA - numB;
    });
  };

  const updatePlanStatus = (plans: Plan[], currentPlan: any) => {
    let isCurrentPlanFound = false;

    plans.map((plan: Plan) => {
      if (plan.priceId === 'custom_plan') {
        return;
      }

      if (
        !space?.subscription ||
        !['active', 'trialing'].includes(space?.subscription?.status as SubscriptionStatus)
      ) {
        plan['status'] = 'upgrade';
      } else if (isCurrentPlanFound) {
        plan['status'] = 'upgrade';
      } else if (!currentPlan?.price?.id || plan.priceId === currentPlan?.price?.id) {
        plan['status'] = 'currentPlan';
        isCurrentPlanFound = true;
      } else if (plan.productId === currentPlan?.product?.id) {
        plan['status'] = 'changeInterval';
        isCurrentPlanFound = true;
      } else {
        plan['status'] = 'downgrade';
      }
    });
  };

  const handleSelectPlan = async (priceId: string) => {
    await api
      .post(`spaces/${spaceId}/transactions`, {
        priceId,
      })
      .then(({ data }) => {
        const paddle = paddleInstance.current;

        paddle?.Checkout.open({
          settings: {
            theme: 'dark',
            locale: 'en',
            allowLogout: false,
          },
          transactionId: data.transactionId,
          customer: {
            id: data.customerId,
          },
        });
      });
  };

  const selectedPlans: Plan[] = plans?.[cycle] || [];

  return (
    <SettingScreenLayout title="Plans">
      {isLoading ? (
        <LoaderContainer>
          <PageLoader />
        </LoaderContainer>
      ) : (
        <div style={{ padding: '1.6rem', maxWidth: '96rem' }}>
          <Description dangerouslySetInnerHTML={{ __html: hint }} />

          <PlanCycleWrapper>
            <PlanCycleSwitch cycle={cycle} setCycle={setCycle} />
          </PlanCycleWrapper>

          <CardsContainer>
            {selectedPlans?.map((plan) => (
              <PlanCard
                key={plan.priceId}
                name={plan.name}
                label={plan.label}
                description={plan.description}
                features={plan.features}
                disabled={plan.disabled}
                formattedPrice={plan.formattedPrice}
                priceId={plan.priceId}
                status={plan.status}
                imageUrl={plan.imageUrl}
                interval={plan.interval}
                isSelfServing={space?.isSelfServing ?? true}
                onSelect={handleSelectPlan}
                handleDowngrade={() => setSupportModalOpen(true)}
                handleUpgrade={() => setSalesModalOpen(true)}
              />
            ))}
          </CardsContainer>

          <FreePlanCard
            data-current={
              !space?.subscription ||
              !['active', 'trailing'].includes(space?.subscription?.status as SubscriptionStatus)
            }
          >
            <h2
              style={{
                color: 'var(--color-grayscale-white)',
                fontSize: '2.4rem',
                fontWeight: 600,
              }}
            >
              Free machine
            </h2>
            <p>Manage contacts, design storyboards and prepare unlimited call sheets.</p>
            <p>Limited to 2 projects per company account, 10 shots per project.</p>
            <p>1 user / 5 GB</p>
          </FreePlanCard>
        </div>
      )}

      {isSupportModalOpen && (
        <ChangePlanModal
          title="Contact Support"
          description='Please contact us at <a href="mailto:support@the-producer.io">support@the-producer.io</a> to change your plan.'
          onCancel={() => setSupportModalOpen(false)}
        />
      )}

      {isSalesModalOpen && (
        <ChangePlanModal
          title="Contact Sales"
          description='Please contact us at <a href="mailto:sales@the-producer.io">sales@the-producer.io</a> to upgrade your plan.'
          onCancel={() => setSalesModalOpen(false)}
        />
      )}
    </SettingScreenLayout>
  );
});

const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--color-texts-low-contrast);
  overflow-wrap: break-word;
  margin-bottom: 2.4rem;

  a {
    color: white;
    text-decoration: underline;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @media (min-width: ${breakPoint.medium}px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    padding-bottom: 3.2rem;
    min-height: 100%;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const FreePlanCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  width: 100%;
  padding: 1.6rem;
  background: var(--color-grayscale-eerie-black);
  border-radius: 0.4rem;

  &[data-disabled='true'] {
    background: var(--color-grayscale-eerie-black);

    h1,
    p,
    span {
      color: var(--color-grayscale-arsenic) !important;
    }

    img {
      opacity: 0.5;
    }

    svg,
    svg * {
      fill: var(--color-grayscale-arsenic);
    }
  }

  &[data-current='true'] {
    box-sizing: border-box;
    box-shadow: inset 0 0 0 2px var(--color-grayscale-tuna);
    background: var(--color-grayscale-charleston);
  }
`;

const PlanCycleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2.4rem;
`;
