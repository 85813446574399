import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PlusIcon } from '@radix-ui/react-icons';
import { Avatar } from '@producer-io/ui-kit';

import { ElevatedButton, IconButton } from '../../../components/buttons';
import { getFullName } from '../../../lib/utils/get-full-name';
import { BaseDialog } from '../../../components/dialog';
import { Close } from '../../../components/icons/Close';
import { ContactSearch } from './contact-search';
import { AssignAddedContactForm } from './assign-added-contact.form';
import { breakPoint } from '../../../app/theme';
import { useResponsive } from '../../../hooks/useResponsive';
import { ModalHeader } from '../../../components/form-builder-modal/modal-header';
import { getNameInitials } from '../../../lib/utils/get-name-initials';
import { Space } from '../../../app/entities/space';

import type { TeamContact } from '../../teams/models/team';
import type { Candidate } from '../../../app/entities/candidate';

type AssignContactsModalProps = {
  addedContacts: Array<TeamContact | Candidate>;
  assignedContacts?: TeamContact[];
  onAdd: (contactId: string, role?: string) => Promise<void>;
  onDelete?: (contactId: string) => void;
  spaceId: string;
  onCancel: () => void;
  title: string;
  subtitle: string;
};

export const AssignContactsModal: React.FC<AssignContactsModalProps> = ({
  spaceId,
  title,
  subtitle,
  addedContacts,
  assignedContacts,
  onCancel,
  onAdd,
  onDelete,
}) => {
  const space = Space.getOne(spaceId);
  const { isMobile, isDesktop } = useResponsive();

  const contacts = space?.contacts || [];

  const [displayedPage, setDisplayedPage] = useState<'select-contact' | 'create-contact'>(
    'select-contact',
  );

  useEffect(() => {
    if (isDesktop) {
      setDisplayedPage('select-contact');
    }
  }, [isDesktop]);

  const assignedContactsIds = assignedContacts?.map((candidate) => candidate._id) || [];
  const addedContactsIds =
    addedContacts?.map((candidate) =>
      'contactId' in candidate ? candidate.contactId : candidate._id,
    ) || [];

  const existingContactIds = new Set([...assignedContactsIds, ...addedContactsIds]);

  return (
    <BaseDialog
      key="assign-contact-modal"
      onClose={onCancel}
      mobilePosition="bottom"
      theme="dark"
      hideClose
    >
      <Container>
        <LeftContainer data-active={displayedPage === 'select-contact'}>
          <LeftHeader title={title} onClose={onCancel} action="Done" onAction={onCancel} />

          <LeftContent>
            <ContactSearch
              contactsDetails={contacts.map((contact) => ({
                contact,
                isAssigned: existingContactIds.has(contact._id),
              }))}
              onAdd={onAdd}
            />

            <AddedContactsSection>
              <Title>{subtitle}</Title>

              <AddedCandidatesContainer>
                {addedContacts &&
                  (addedContacts?.length ? (
                    addedContacts.map((addedContact) => (
                      <Member key={`candidates-${addedContact._id}`}>
                        <TextWrapper>
                          <Avatar
                            src={addedContact.avatar || ''}
                            initials={getNameInitials(addedContact)}
                            size="medium"
                            theme="dark"
                          />

                          <Name>{getFullName(addedContact)}</Name>
                        </TextWrapper>

                        <Invite onClick={() => onDelete && onDelete(addedContact._id)}>
                          <Close />
                        </Invite>
                      </Member>
                    ))
                  ) : (
                    <Title>No candidates added yet</Title>
                  ))}
              </AddedCandidatesContainer>
            </AddedContactsSection>
            <CloseButtonWrapper>
              <ElevatedButton onClick={onCancel} text={'Close'} />
            </CloseButtonWrapper>
          </LeftContent>

          <CreateContactLinkWrapper>
            <span>Create contact</span>

            <IconButton
              icon={<PlusIcon />}
              onClick={() => {
                setDisplayedPage('create-contact');
              }}
            />
          </CreateContactLinkWrapper>
        </LeftContainer>

        <RightContainer data-active={displayedPage === 'create-contact'}>
          <ModalHeader
            title="Create contact"
            action="Create"
            onAction={() => {
              const submitButton = document.getElementById('assign-added-contact-form-button');
              if (submitButton) submitButton.click();
              else onCancel();
            }}
            onClose={
              isMobile
                ? () => {
                    setDisplayedPage('select-contact');
                  }
                : onCancel
            }
          />

          <AssignAddedContactForm
            spaceId={spaceId}
            onAssignCandidate={onAdd}
            onSuccess={onCancel}
          />
        </RightContainer>
      </Container>
    </BaseDialog>
  );
};

const Container = styled.div`
  display: flex;
  width: 100rem;
  min-height: 0;
  max-width: 100%;
  height: 88rem;
  max-height: 90vh;
  border-radius: 4px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 11;
  background: var(--color-grayscale-charleston, #25272d);
  max-height: 100%;
  max-width: 55%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  @media screen and (max-width: ${breakPoint.medium - 1}px) {
    max-width: 100%;

    &[data-active='false'] {
      display: none;
    }
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  padding: 0 2.4rem 2.4rem;
  gap: 1.6rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    header > div {
      display: none;
    }
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 9;
  background: var(--color-grayscale-eerie-black, #181a1e);
  max-width: 45%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  @media screen and (max-width: ${breakPoint.medium - 1}px) {
    max-width: 100%;

    &[data-active='false'] {
      display: none;
    }
  }
`;

const AddedCandidatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  gap: 1.6rem;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #7e8087;
`;

export const Member = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.2rem;
  gap: 1.6rem;
  width: 100%;
  cursor: default;

  &[data-is-assigned='true'] {
    cursor: default;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  max-width: 100%;
  min-width: 0;
`;

export const Invite = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--color-secondary);
  border-radius: 0.4rem;
  transition: background-color 0.1s ease-in-out;
  padding: 0.2rem 0.6rem;

  & svg {
    width: 1rem;
    height: 1rem;
    fill: var(--color-grey-5);
    stroke: var(--color-grey-5);
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-secondary-10);

    &[data-is-assigned='true'] {
      cursor: default;
      background-color: transparent;
    }
  }
`;

export const Name = styled.h6`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #ffffff;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CloseButtonWrapper = styled.div`
  align-self: flex-end;

  @media screen and (max-width: ${breakPoint.medium - 1}px) {
    display: none;
  }
`;

const CreateContactLinkWrapper = styled.div`
  background: var(--color-grayscale-eerie-black, #181a1e);
  display: flex;
  padding: 2.4rem 2.4rem 3.2rem;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }

  span:first-child {
    color: var(--color-grayscale-white, #fafafa);
    font-size: 1.8rem;
    font-weight: 600;
  }
`;

const LeftHeader = styled(ModalHeader)`
  @media screen and (min-width: ${breakPoint.medium}px) {
    .close-item {
      display: none;
    }
  }
`;

const AddedContactsSection = styled.div`
  display: none;
  gap: 1.6rem;
  flex: 1;

  @media screen and (min-width: ${breakPoint.small}px) {
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
`;
