import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Tooltip } from '../../../components/tooltip/Tooltip';
import { Resend } from '../../../components/icons/Resend';
import { TimeIcon } from '../../../components/icons/Time';
import { BlueLine } from '../../../components/texts/Texts';
import { timeParser } from '../../../lib/utils/time-parser';
import { Revoke } from '../../../components/icons/Revoke';
import { IconButton } from '../../../components/buttons';

import { Invitation } from '../../../app/entities/invitation';
import { toastPool } from '../../toasts/models/toast-pool';
import { Toast } from '../../toasts/models/toast';
import { catchError } from '../../../core/catch-error';

type GuestInvitationActionProps = {
  invitation: Invitation;
};

export const GuestInvitationAction: React.FC<GuestInvitationActionProps> = ({ invitation }) => {
  const [resendCoolDown, setResendCoolDown] = useState<number>(59);
  const [invitedTime, setInvitedTime] = useState<null | number>(null);
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (!invitedTime || resendCoolDown < 1) {
      return;
    }

    const now = new Date();
    let lastInviteTimeDiff = Math.trunc(Math.abs(now.getTime() - invitedTime) / 1000);

    const resendCoolDownInterval = setInterval(() => {
      if (lastInviteTimeDiff >= 59) {
        clearInterval(resendCoolDownInterval);
        setIsResendDisabled(false);
      }

      const timeLeft = 59 - lastInviteTimeDiff;
      setResendCoolDown(timeLeft);
      lastInviteTimeDiff += 1;
    }, 1000);

    return () => clearInterval(resendCoolDownInterval);
  }, [invitedTime, isResendDisabled, resendCoolDown]);

  const handleResendInvitation = async () => {
    try {
      if (isResendDisabled) {
        return;
      }

      setInvitedTime(new Date().getTime());
      setIsResendDisabled(true);

      await invitation.resend();

      toastPool.insert(new Toast('Invitation sent.'));
    } catch (e) {
      catchError(e);
      setIsResendDisabled(false);
    }
  };

  const handleRevokeInvitation = async () => {
    try {
      await invitation.delete();
    } catch (e) {
      catchError(e);
    }
  };

  return (
    <Container>
      {isResendDisabled && (
        <CoolDownWrapper>
          <TimeIcon /> {timeParser(resendCoolDown)}
        </CoolDownWrapper>
      )}

      <Tooltip text="Resend" position="top" disabled={isResendDisabled}>
        <IconButton
          icon={<Resend />}
          onClick={handleResendInvitation}
          disabled={isResendDisabled}
        />
      </Tooltip>

      <Tooltip text="Revoke" position="top">
        <IconButton icon={<Revoke />} onClick={handleRevokeInvitation} />
      </Tooltip>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-inline-end: 1rem;
  gap: 1.6rem;
  min-width: 16rem;

  & button {
    & svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;

const CoolDownWrapper = styled(BlueLine)`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-weight: 400;
  margin-top: 0;
`;
