import { action, makeObservable, observable } from 'mobx';

import { Model } from '../../core/engine/model';
import { Project } from './project';
import { ManyToOne, OneToOne } from '../../core/engine/decorators';
import { StoryboardSettings } from '../../features/storyboards/models/storyboard-settings';
import { DEFAULT_STORYBOARD_SETTINGS } from '../../features/storyboards/default-settings';
import { Shot } from './shot';
import { api } from '../../api';
import { catchError } from '../../core/catch-error';
import { entityPool } from '../../core/engine/engine';
import { Step } from './step';

export class Storyboard extends Model {
  projectId: string;
  duration: number;
  name: string = 'New storyboard';
  shots: StoryboardShot[] = [];
  description?: string;
  aspectRatio?: string;
  status?: string;
  settings: StoryboardSettings = DEFAULT_STORYBOARD_SETTINGS;
  stepId?: string;

  @OneToOne('storyboard')
  step?: Step;

  @ManyToOne('storyboards')
  project: Project | null = null;

  constructor() {
    super('storyboards');

    makeObservable(this, {
      // Observable
      aspectRatio: observable,
      description: observable,
      duration: observable,
      name: observable,
      project: observable,
      projectId: observable,
      settings: observable,
      shots: observable,
      stepId: observable,
      step: observable,

      // Actions
      addShot: action,
      insertShot: action,
    });
  }

  static getOne(id: string): Storyboard | undefined {
    return Model.getOne(id) as Storyboard;
  }

  async addShot(shotId: string, position?: number) {
    const currentShots = JSON.parse(JSON.stringify(this.shots));

    if (position === undefined) {
      position = this.shots.length;
    }

    this.shots.splice(position, 0, {
      _id: shotId,
      position,
    });

    try {
      const { data } = await api.post(`/projects/${this.projectId}/storyboards/${this._id}/shots`, {
        shotId,
        position,
      });

      const newShot = this.shots.find((shot) => shot._id === shotId);

      if (newShot) {
        Object.assign(newShot, data);
      }
    } catch (e) {
      catchError(e);
      this.shots = currentShots;
    }
  }

  toPOJO(): Record<string, any> {
    return {
      _id: this._id,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      duration: this.duration,
      shots: this.shots,
      description: this.description,
      aspectRatio: this.aspectRatio,
      projectId: this.projectId,
      project: this.project,
      name: this.name,
      settings: this.settings,
      stepId: this.stepId,
    };
  }

  async edit(shotIds: string[], position: number) {
    const currentShots = JSON.parse(JSON.stringify(this.shots));

    const selectedShotIndex = this.shots.findIndex((shot) => shotIds.includes(shot._id));
    const insertIndex = position < selectedShotIndex ? position - 1 : position;
    let dividerIndex;

    if (insertIndex >= -1 && insertIndex < this.shots.length) {
      dividerIndex = insertIndex;
    } else {
      dividerIndex = this.shots.length;
    }

    const selectedShot = this.shots.filter((shot) => shotIds.includes(shot._id));

    const upperHalfRemainingCards = this.shots
      .slice(0, dividerIndex + 1)
      .filter((currShot) => !shotIds.includes(currShot._id));

    const lowerHalfRemainingCards = this.shots
      .slice(dividerIndex + 1)
      .filter((currShot) => !shotIds.includes(currShot._id));

    const finalShots = [...upperHalfRemainingCards, ...selectedShot, ...lowerHalfRemainingCards];
    finalShots.forEach((shot, index) => (shot.position = index));

    this.shots = finalShots;

    try {
      await api.patch(`/projects/${this.projectId}/storyboards/${this._id}`, {
        shotIds,
        position,
      });
    } catch (e) {
      catchError(e);
      this.shots = currentShots;
    }
  }

  async update(data: Partial<Storyboard>) {
    Object.assign(this, data);
    try {
      await api.patch(`/projects/${this.projectId}/storyboards/${this._id}`, data);
    } catch (e) {
      catchError(e);
    }
  }

  insertShot(shot: Shot, position?: number, duration?: number) {
    position = position || this.shots.length;

    const storyboardShot = {
      _id: shot._id,
      duration,
      position,
      locked: false,
    };

    this.shots.splice(position, 0, storyboardShot);
    this.shots.forEach((el, index) => (el.position = index));

    return storyboardShot;
  }

  removeShots(shotIds: string[]) {
    this.shots = this.shots.filter((shot) => !shotIds.includes(shot._id));
    api.post(`/projects/${this.projectId}/storyboards/${this._id}/delete-shots`, { shotIds });
  }

  async assignShots(shotsIds: string[], position?: number) {
    let latestPosition = position;
    for (const id of shotsIds) {
      const shot = Shot.getOne(id);
      if (shot) this.insertShot(shot, position);
      if (latestPosition !== undefined) latestPosition++;
    }

    const requestBody: { shotsIds: string[]; position?: number } = {
      shotsIds,
    };

    if (position !== undefined && position !== null) {
      requestBody.position = position;
    }

    api.patch(`/projects/${this.projectId}/storyboards/${this._id}/assign-shots`, requestBody);
  }

  async save() {
    const steps = Step.getAll().filter(
      (step) => step.projectId === this.projectId && step.type === 'storyboard',
    );
    const storyboards = Storyboard.getAll().filter(
      (storyboard) => storyboard.projectId === this.projectId,
    );
    const requestBody: Partial<Storyboard> = { name: 'New storyboard' };

    if (steps.length > storyboards.length) {
      const stepWithoutStoryboard = steps.find(
        (step) => !step.storyboardId || !storyboards.some((sb) => sb._id === step.storyboardId),
      );
      if (stepWithoutStoryboard) {
        requestBody.stepId = stepWithoutStoryboard._id;
      }
    }

    const { data } = await api.post(`/projects/${this.projectId}/storyboards`, requestBody);

    Object.assign(this, data.storyboard);
    entityPool.insert(this);

    if (!requestBody.stepId) {
      const step = Object.assign(new Step(), data.step);
      entityPool.insert(step);
    } else {
      steps[0].storyboardId = this._id;
      steps[0].storyboard = this;
    }
  }

  async delete() {
    try {
      await api.delete(`/projects/${this.projectId}/storyboards/${this._id}`);
      entityPool.delete(this);
      if (this.step) entityPool.delete(this.step);
    } catch (e) {
      catchError(e);
    }
  }

  static getAll(): Storyboard[] {
    return Model.getAll().filter((model) => model instanceof Storyboard) as Storyboard[];
  }
}

// Review StoryboardShot model vs Shot model
export type StoryboardShot = Partial<Shot> & {
  _id: string;
  duration?: number;
  locked?: boolean;
  position: number;
};
