import React, { useEffect, useState } from 'react';
import { ClipboardIcon } from '@radix-ui/react-icons';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { uiStore } from '../../core/stores/ui-store';
import { TaskList } from '../../features/tasks/components/tasks-list';
import { ElevatedButton } from '../../components/buttons';
import { SaveTaskModal } from '../../features/tasks/components/save-task.modal';
import { PagePlaceholder } from '../../components/page-placeholder/page-placeholder';
import { Header } from '../project/project.page';
import { useCurrentSpace } from 'hooks/use-current-space';
import { Task } from '../../app/entities/task';
import { PageLoader } from '../../app/components/page-loader';
import { fetchCalendarData } from 'features/spaces/stores/space.slice';
import { taskGroupingStore } from '../../core/stores/task-store';
import { openModal } from 'core/modal/open-modal';

export const SpaceTasksRoute: React.FC = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const space = useCurrentSpace();
  const spaceTasks = space.tasks;
  const spaceTasksFilter = space.tasksFilter;

  const [filteredTasks, setFilteredTasks] = useState<Task[]>(spaceTasks);

  const filterCount = spaceTasksFilter.filtersCount;

  useEffect(() => {
    if (!spaceTasks) return;

    if (!spaceTasksFilter.filtersCount) {
      return setFilteredTasks(spaceTasks);
    }

    if (!spaceTasksFilter.filtersCount) {
      setFilteredTasks(spaceTasks);
    }

    const { projectIds, status, priority, assigneeIds, tagIds } = spaceTasksFilter;

    const filtered = spaceTasks.filter((task) => {
      if (projectIds.length && !projectIds.includes(task.projectId)) {
        return false;
      }

      if (status.length && !status.includes(task.status)) {
        return false;
      }

      if (priority.length && !priority.includes(task.priority)) {
        return false;
      }

      if (assigneeIds.length && !assigneeIds.includes(task.assigneeId)) {
        return false;
      }

      if (tagIds.length) {
        if (tagIds.includes(null) && task.tags.length === 0) {
          return true;
        }

        if (!task.tags.some((tag) => tagIds.includes(tag._id))) {
          return false;
        }
      }

      return true;
    });

    setFilteredTasks(filtered);
  }, [spaceTasks, filterCount, spaceTasksFilter]);

  useEffect(() => {
    uiStore.currentLocation = 'space.tasks';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  const spaceProjects = space?.projects;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchCalendarData(space._id), Task.fetchBySpace(space._id)]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [space?._id, spaceProjects]);

  const content = () => {
    if (isLoading) {
      return <PageLoader />;
    } else if (space.tasks.length === 0) {
      return (
        <PagePlaceholder
          title="You're off to a clean start!"
          description="Start by creating a task to keep things organized and on track."
          fullWidth
          backgroundImgUrl="/images/order-from-chaos.svg"
        />
      );
    } else {
      return (
        <TaskList
          key={taskGroupingStore.groupingKey}
          tasks={filteredTasks}
          onClearFilters={() => spaceTasksFilter.clearFilters()}
          taskLevel="space"
        />
      );
    }
  };

  return (
    <Container>
      <Header>
        <span>Tasks</span>

        <ElevatedButton
          icon={<ClipboardIcon style={{ stroke: 'unset' }} />}
          text="Create task"
          onClick={() => openModal(SaveTaskModal, { title: 'Create task' })}
        />
      </Header>

      {content()}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;
