import React, { useState, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';

import { TextInput } from '../../../../components/text-input/TextInput';
import { Project } from '../../../../app/entities/project';
import { HasServerResponse } from '../../../../components/PanelContainer';
import { Alert } from '../../../../components/alert/Alert';
import { AddCover } from '../../../../components/add-file/add-cover';
import { BaseDialog, DialogHeader, DialogFooter } from '../../../../components/dialog';
import { ElevatedButton, OutlinedButton } from '../../../../components/buttons';
import { PageLoader } from '../../../../components/page-loader/PageLoader';
import { formatPicture } from '../../../../core/services/image.service';
import { breakPoint } from '../../../../app/theme';
import { SelectionProvider } from '../../../../core/contexts/selection.context';
import {
  ProjectTemplateCategory,
  ProjectTemplateItem,
  ProjectTemplateType,
} from './project-template-item';

interface ModalProps {
  spaceId: string;
  onCancel: () => void;
  initialValues?: Project;
  onSubmit: (values: any) => void;
}

export const projectTemplates = [
  {
    category: ProjectTemplateCategory.Commercial,
    value: ProjectTemplateType.Commercial,
    backgroundImage: '/templates/commercial.jpeg',
    badge: 'Template',
  },
  {
    category: ProjectTemplateCategory.SocialMedia,
    value: ProjectTemplateType.SocialMedia,
    backgroundImage: '/templates/social-media.png',
    badge: 'Template',
  },
  {
    category: ProjectTemplateCategory.Documentary,
    value: ProjectTemplateType.Documentary,
    backgroundImage: '/templates/documentary.jpeg',
    badge: 'Template',
  },
  {
    category: ProjectTemplateCategory.ShortFiction,
    value: ProjectTemplateType.ShortFiction,
    backgroundImage: '/templates/fiction.png',
    badge: 'Template',
  },
  {
    category: ProjectTemplateCategory.Interview,
    value: ProjectTemplateType.Interview,
    backgroundImage: '/templates/interview.jpeg',
    badge: 'Template',
  },
  {
    category: ProjectTemplateCategory.Event,
    value: ProjectTemplateType.Event,
    backgroundImage: '/templates/event.jpeg',
    badge: 'Template',
  },
];

export const DesktopCreateProjectModal: React.FC<ModalProps> = ({
  spaceId,
  onCancel,
  onSubmit,
  initialValues,
}) => {
  const { t } = useTranslation('createProject');
  const [file, setFile] = useState<File | null>();
  const [projectTemplate, setProjectTemplate] = useState(ProjectTemplateType.Commercial);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<any>();
  const [fileError, setFileError] = useState(null);

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .transform((value) => value.trim())
          .matches(/^(.*)?\S+(.*)?$/, t('common:fieldRequired'))
          .required(t('common:fieldRequired'))
          .max(32, t('common:fieldMax', { max: 32 })),
        client: yup.string().max(32, t('common:fieldMax', { max: 32 })),
      }),
    [t],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      client: '',
      template: projectTemplate,
    },
  });

  const watchedName = watch('name');

  useEffect(() => {
    setValue('template', projectTemplate);

    const defaultDemoName = 'Breitling - History (Demo)';
    if (projectTemplate === ProjectTemplateType.Corporate) {
      if (!watchedName) {
        setValue('name', defaultDemoName);
      }
    } else {
      if (watchedName === defaultDemoName) {
        setValue('name', '');
      }
    }
  }, [projectTemplate]);

  const onHandleSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }

    setFileError(null);
    setIsLoading(true);

    let frame;
    if (file) {
      const options = { width: 640, height: 380 };
      frame = await formatPicture(file, options);
    }

    try {
      await onSubmit({
        spaceId,
        ...values,
        ...(frame && { file: frame }),
      });

      onCancel();
    } catch (e) {
      setServerError(e);
    }
  };

  return (
    <BaseDialog onClose={onCancel} isMobileFill>
      <DialogHeader title={t('new_project')} onClose={onCancel} />

      <Form>
        {serverError && serverError.response && (
          <HasServerResponse>
            <Alert text={serverError.response.data.message} />
          </HasServerResponse>
        )}

        <AddCoverWrapper>
          <AddCover
            setFieldValue={(_, file) => setFile(file)}
            errorMessage={fileError}
            file={file}
            accept={'image/*'}
            sourceFile={initialValues?.avatar}
            acceptedFiles={['.png', '.jpeg']}
          />
        </AddCoverWrapper>

        <InputsWrapper>
          <TextInput
            {...register('name')}
            errorMessage={errors.name?.message}
            label={t('name')}
            placeholder={t('name_placeholder')}
            required
          />

          <TextInput
            {...register('client')}
            name={'client'}
            label={t('client')}
            placeholder={t('client_placeholder')}
            autocomplete="false"
            errorMessage={errors.client?.message}
          />
        </InputsWrapper>
      </Form>

      <ProjectTemplatesContainer>
        <p>Use a demo or a template to get started</p>

        <ProjectTemplates>
          <SelectionProvider items={projectTemplates.map((el) => el.value)}>
            {projectTemplates.map((template) => (
              <ProjectTemplateItem
                key={template.value}
                category={template.category}
                value={template.value as ProjectTemplateType}
                defaultValue={ProjectTemplateType.Commercial}
                badge={template.badge as 'Template' | 'Demo'}
                backgroundImage={template.backgroundImage}
                onSelect={() => setProjectTemplate(template.value)}
              />
            ))}
          </SelectionProvider>
        </ProjectTemplates>
      </ProjectTemplatesContainer>

      <DialogFooter
        actions={[
          <OutlinedButton key="cancel" text="Cancel" onClick={onCancel} />,
          <ElevatedButton
            key="submit"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleSubmit(onHandleSubmit)}
            text="Create"
          />,
        ]}
      />

      {isLoading && (
        <LoadingOverlay>
          <PageLoader />
        </LoadingOverlay>
      )}
    </BaseDialog>
  );
};

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  flex: 1;
`;

const AddCoverWrapper = styled.div`
  width: 100%;

  & > div {
    & > div {
      height: 12.2rem;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    width: 50%;
  }
`;

const ProjectTemplatesContainer = styled.div`
  height: 100%;
  padding: 1.6rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & > p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    color: var(--color-grayscale-white);
    margin-bottom: 1.6rem;
  }
`;

const ProjectTemplates = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.6rem;
  overflow-y: scroll;
`;

const Form = styled.form`
  padding: 1.6rem;
  display: flex;
  flex-direction: column-reverse;
  gap: 1.6rem;
  width: 100%;
  height: 100%;
  border-bottom: 0.1rem solid var(--color-grayscale-trout);

  @media screen and (min-width: ${breakPoint.medium}px) {
    flex-direction: row;
    width: 48rem;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
`;
