import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Share2Icon } from '@radix-ui/react-icons';

import { AssetViewerScreen } from '../../assets/screens/asset-viewer.screen';
import { LocationBreadcrumb } from '../components/location-breadcrumb';
import { ScreenHeader } from '../../projects/components/screen-header';
import { ElevatedButton } from '../../../components/buttons';
import { ShareModal } from '../../share/components/share-modal';
import { FolderView } from '../../assets/components/folder-view';
import { openModal } from '../../../core/modal/open-modal';
import { Asset } from '../../../app/entities/asset';

import type { ScreenProps } from '../../projects/models/types';

export const LocationAssetViewerScreen: React.FC<ScreenProps> = observer(({ step, spaceId }) => {
  const { locationId, assetId } = useParams();

  const asset = Asset.getOne(assetId!)!;

  if (!asset) {
    return <div />; // TODO: handle display file not found placeholder
  }

  const handleOpenShareModal = async () => {
    openModal(ShareModal, {
      title: `Share ${asset.name}`,
      elementIds: [asset._id],
      spaceId: spaceId!,
      projectId: step.projectId,
    });
  };

  return (
    <React.Fragment>
      <ScreenHeader
        breadcrumb={<LocationBreadcrumb locationId={locationId!} assetId={assetId} />}
        stepId={step._id}
        title={step.name}
        dueDate={step.dueDate}
        startDate={step.startDate}
        handleUpdateTimeFrame={(values) => step.update(values)}
        actions={[
          <React.Fragment key="share-button">
            {asset && (
              <ElevatedButton onClick={handleOpenShareModal} text="Share" icon={<Share2Icon />} />
            )}
          </React.Fragment>,
        ]}
        canValidateStep={false}
      />

      <Content>
        {asset.type === 'folder' ? (
          <FolderView stepId={step._id} folderId={asset._id} />
        ) : (
          <AssetViewerScreen asset={asset} />
        )}
      </Content>
    </React.Fragment>
  );
});

const Content = styled.div`
  height: 100%;
  max-height: calc(100% - 4rem);
`;
