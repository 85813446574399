import React, { PropsWithChildren } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';

import { breakPoint } from '../../app/theme';

interface DesktopDrawerProps {
  id?: string;
  className?: string;
  isOpen: boolean;
  onCancel: () => void;
  autoFocus?: boolean;
  mobileFullHeight?: boolean;
  onPointerDownOutside?: React.ComponentProps<typeof Dialog.Content>['onPointerDownOutside'];
}

export const Drawer: React.FC<PropsWithChildren<DesktopDrawerProps>> = ({
  id = 'drawer',
  className,
  isOpen,
  onCancel,
  autoFocus = true,
  children,
  mobileFullHeight,
  onPointerDownOutside,
}) => {
  const handleOpenChange = (value: boolean) => {
    if (!value) {
      onCancel();
    }
  };

  return (
    <Dialog.Root key={id} open={isOpen} onOpenChange={handleOpenChange}>
      <Dialog.Portal>
        <Overlay />

        <Content
          data-full-height={mobileFullHeight}
          className={className}
          onPointerDownOutside={onPointerDownOutside}
          onOpenAutoFocus={(e) => {
            if (!autoFocus) {
              e.preventDefault();
            }
          }}
        >
          {children}
        </Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(12, 13, 15, 0.7);
  position: fixed;
  inset: 0;
  top: var(--tab-bar-height);
  z-index: var(--layer-overlay);

  &[data-state='open'] {
    animation: overlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state='closed'] {
    animation: overlay-hide 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  @keyframes overlay-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes overlay-hide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const Content = styled(Dialog.Content)`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: var(--tab-bar-height);
  right: 0;
  bottom: 0;
  z-index: var(--layer-modal);
  width: 100%;
  max-width: 100%;
  background: var(--color-grayscale-eerie-black);
  box-shadow: hsl(206 22% 7% / 35%) 0 10px 38px -10px, hsl(206 22% 7% / 20%) 0 10px 20px -15px;

  &[data-state='open'] {
    animation: content-show 300ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state='closed'] {
    overflow: hidden;
    animation: content-hide 300ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  @keyframes content-show {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @keyframes content-hide {
    from {
      opacity: 1;
      transform: translateY(0%);
    }
    to {
      opacity: 0;
      transform: translateY(100%);
    }
  }

  @media (max-width: ${breakPoint.medium - 1}px) {
    &[data-full-height='true'] {
      top: 0;
    }
  }

  @media (min-width: ${breakPoint.medium}px) {
    width: 37.6rem;

    @keyframes content-show {
      from {
        opacity: 0;
        transform: translateX(100%);
      }
      to {
        opacity: 1;
        transform: translateX(0%);
      }
    }

    @keyframes content-hide {
      from {
        opacity: 1;
        transform: translateX(0%);
      }
      to {
        opacity: 0;
        transform: translateX(100%);
      }
    }
  }
`;
