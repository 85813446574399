import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { TagList } from '../../features/tags/components/tag-list';
import { SettingScreenLayout } from '../../features/settings/components/setting-screen-layout';
import { Space } from '../../app/entities/space';

export const TagsRoute: React.FC = observer(() => {
  const { spaceId } = useParams<{ spaceId: string }>();
  const space = Space.getOne(spaceId!);

  return (
    <SettingScreenLayout title="Tags">
      <TagList tags={space?.tags || []} />
    </SettingScreenLayout>
  );
});
