import React from 'react';

export const ProjectIcon2 = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.11846 3.88333H7.68849L6.39998 6.40334L7.87179 6.40334L9.19027 3.88333H10.7603L9.47179 6.40334L10.9436 6.40334L12.2621 3.88333H13.4333C13.7095 3.88333 13.9333 4.10719 13.9333 4.38333V13.1167C13.9333 13.3928 13.7095 13.6167 13.4333 13.6167H2.56665C2.29051 13.6167 2.06665 13.3928 2.06665 13.1167V4.38333C2.06665 4.10719 2.29051 3.88333 2.56665 3.88333H4.61668L3.32817 6.40334L4.79998 6.40334L6.11846 3.88333ZM1.06665 4.38333C1.06665 3.5549 1.73822 2.88333 2.56665 2.88333H13.4333C14.2617 2.88333 14.9333 3.5549 14.9333 4.38333V13.1167C14.9333 13.9451 14.2617 14.6167 13.4333 14.6167H2.56665C1.73822 14.6167 1.06665 13.9451 1.06665 13.1167V4.38333Z"
      fill="currentColor"
    />
  </svg>
);
